import React from "react";
import OpinModal from "components/OpinModal";
import { useModalsContext } from "hooks/useModalsContext";
import { CONSENTIMENTO_MODALS } from "context/modalsContext";
import { useNavigate } from "react-router-dom";

const BlockConsentFlowModal = () => {
  const navigate = useNavigate();
  const { closeModal, openedModalType } = useModalsContext();

  const handleCloseModal = () => {
    closeModal();
    navigate("/opin");
  };

  return (
    <OpinModal
      open={openedModalType === CONSENTIMENTO_MODALS.blockConsentFlow}
      onClose={handleCloseModal}
      title="Ops, funcionalidade em manutenção."
      description="No momento não é possível criar novos compartilhamentos. A funcionalidade será atualizada e disponibilizada novamente em agosto. Pedimos desculpas pelo ocorrido."
      closeActionProps={{ text: "Voltar para Open Insurance" }}
    />
  );
};

export default BlockConsentFlowModal;
