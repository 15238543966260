import { Skeleton, useDeviceType } from "@ds/react";
import React from "react";

import * as S from "./Card.style";

const Card = ({ lead, title, hasDivider, children, loading, logo }) => {
  const { sm } = useDeviceType();
  return (
    <S.CardContainer>
      {loading ? (
        <S.SkeletonContainer>
          <Skeleton lines={4} />
        </S.SkeletonContainer>
      ) : (
        <>
          <S.CardLeft>
            {logo}
            {lead && (
              <S.Lead variant="lead-text" color="text-light">
                {lead}
              </S.Lead>
            )}
            <S.Title variant={sm ? "headline-05" : "headline-07"}>
              {title}
            </S.Title>
          </S.CardLeft>
          <S.CardRight hasDivider={hasDivider}>{children}</S.CardRight>
        </>
      )}
    </S.CardContainer>
  );
};

export default Card;
