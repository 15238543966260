import useAprovadoresByApolice from "hooks/useAprovadoresByApolice";
import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { hasSessionStorageItemByKey } from "utils/helpers";

const INTERACTION_KEY = "interaction";

const AutorizacaoContext = createContext({});

export const AutorizacaoProvider = ({ children }) => {
  const hasValidInteractionData = hasSessionStorageItemByKey(INTERACTION_KEY);
  const { clearAprovadoresState, ...aprovadoresMethods } =
    useAprovadoresByApolice();

  const initialInteractionData = hasValidInteractionData
    ? JSON.parse(sessionStorage.getItem(INTERACTION_KEY))
    : null;

  const [interaction, setInteraction] = useState(initialInteractionData);
  const [apolices, setApolices] = useState([]);
  const [detailedPermissions, setDetailedPermissions] = useState([]);
  const [apolicesSelecionadas, setApolicesSelecionadas] = useState([]);
  const [status, setStatus] = useState("default-content");

  const hasApolicesPermissions = detailedPermissions?.some(
    (detailedPermission) => !detailedPermission?.dadosPessoais
  );
  const hasOnlyApolicesPermissions = detailedPermissions?.every(
    (detailedPermission) => !detailedPermission?.dadosPessoais
  );
  const personalPermissions = detailedPermissions.filter(
    ({ dadosPessoais }) => dadosPessoais
  );

  const clearSelectedApolices = useCallback(() => {
    setApolicesSelecionadas([]);
    clearAprovadoresState();
  }, [clearAprovadoresState]);

  useEffect(() => {
    sessionStorage.setItem(INTERACTION_KEY, JSON.stringify(interaction));
  }, [interaction]);

  const value = useMemo(
    () => ({
      interaction,
      setInteraction,
      apolices,
      setApolices,
      detailedPermissions,
      setDetailedPermissions,
      apolicesSelecionadas,
      setApolicesSelecionadas,
      status,
      setStatus,
      personalPermissions,
      hasApolicesPermissions,
      hasOnlyApolicesPermissions,
      clearSelectedApolices,
      clearInteractionData: () => sessionStorage.clear(),
      ...aprovadoresMethods,
    }),
    [
      interaction,
      apolices,
      apolicesSelecionadas,
      status,
      aprovadoresMethods,
      detailedPermissions,
      personalPermissions,
      hasApolicesPermissions,
      hasOnlyApolicesPermissions,
      clearSelectedApolices,
    ]
  );

  return (
    <AutorizacaoContext.Provider value={value}>
      {children}
    </AutorizacaoContext.Provider>
  );
};

export default AutorizacaoContext;
