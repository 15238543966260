import { Text } from "@ds/react";
import NavigationHeader from "components/NavigationHeader";
import { useConsentimentoContext } from "hooks/useConsentimentoContext";
import { useModalsContext } from "hooks/useModalsContext";
import useHandleRedirect from "pages/Consentimento/hooks/useHandleRedirect";
import { PREVISUL_NAME_MOCK } from "utils/constants";
import OpinLogo from "../../../../assets/icons/OpenInsuranceLogo.svg";
import ArrowLineDown from "../../../../assets/icons/arrow-line-down.svg";
import PrevisulLogo from "../../../../assets/icons/logo-previsul-footer.svg";
import Card from "../../../../components/Card";
import * as S from "./Redirecionamento.style";

const Redirecionamento = ({ backToTermsAndConditions }) => {
  const { renderModal } = useModalsContext();
  const { onganizationName } = useHandleRedirect();
  const { selectedInstituicao } = useConsentimentoContext();

  return (
    <S.Container>
      <NavigationHeader
        label="Voltar para Open Insurance"
        onBack={backToTermsAndConditions}
      />

      <Card title="Aguarde enquanto te redirecionamos..." hasDivider>
        <S.EnterprisesInfo>
          <S.Info>
            <img
              src={PrevisulLogo}
              alt={`Logo da empresa ${PREVISUL_NAME_MOCK}`}
            />
            <Text variant="body-01-sm">{PREVISUL_NAME_MOCK}</Text>
          </S.Info>
          <S.Info center>
            <img src={ArrowLineDown} alt="Arrow Line Down" />
            <Text variant="caption-02">
              Você está sendo redirecionado para o ambiente da instituição
              selecionada.
            </Text>
          </S.Info>
          <S.Info>
            <img
              src={selectedInstituicao?.CustomerFriendlyLogoUri}
              alt={`Logo da ${selectedInstituicao?.CustomerFriendlyName}`}
            />
            <Text variant="body-01-sm">{onganizationName}</Text>
          </S.Info>
        </S.EnterprisesInfo>
        <div>
          <img src={OpinLogo} alt="OpenInsurance logo" />
          <Text variant="body-02-md">
            Este compartilhamento de dados será realizado através do Open
            Insurance.
          </Text>
        </div>
      </Card>

      {renderModal()}
    </S.Container>
  );
};

export default Redirecionamento;
