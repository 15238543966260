import { useCallback, useState } from "react";

const INITIAL_STEP_POSITION = 0;

const useEtapas = (initialSteps) => {
  const [steps, setSteps] = useState(initialSteps);
  const [stepPosition, setStepPosition] = useState(INITIAL_STEP_POSITION);

  const isLastStep = stepPosition === steps.length - 1;
  const isFirstStep = stepPosition === 0;

  const stepForward = () => setStepPosition((previewValue) => previewValue + 1);
  const stepBack = () => setStepPosition((previewValue) => previewValue - 1);

  const changeSteps = useCallback((newSteps) => setSteps(newSteps), [setSteps]);

  return {
    isFirstStep,
    isLastStep,
    currentStep: steps[stepPosition],
    stepForward,
    stepBack,
    changeSteps,
  };
};

export default useEtapas;
