import * as S from "./Confirmacao.style";
import ActionsCard from "./components/ActionsCard/ActionsCard";
import ResumoSolicitacaoCard from "./components/ResumoSolicitacaoCard/ResumoSolicitacaoCard";
import SharedDataCard from "./components/SharedDataCard/SharedDataCard";

const Confirmacao = () => (
  <S.Container>
    <ResumoSolicitacaoCard />
    <SharedDataCard />
    <ActionsCard />
  </S.Container>
);

export default Confirmacao;
