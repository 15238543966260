import React from "react";
import OpinModal from "components/OpinModal";
import { useModalsContext } from "hooks/useModalsContext";
import * as S from "./TermosModal.style";
import { AUTORIZACAO_MODALS } from "context/modalsContext";

const TermosModal = () => {
  const { closeModal, openedModalType } = useModalsContext();

  return (
    <OpinModal
      open={openedModalType === AUTORIZACAO_MODALS.termos}
      onClose={closeModal}
      title="Termos e Condições"
    >
      <S.TermosText>
        <strong>1. O que é o Open Insurance?</strong>
        <p>
          O Open Insurance é um ecossistema regulamentado pelo Conselho Nacional
          de Seguros Privados (CNSP) e pela SUSEP (Superintendência de Seguros
          Privados) que permite que pessoas que possuem produtos de seguros,
          previdência e capitalização compartilhem os seus dados pessoais e
          contratuais com outras seguradoras, a partir do consentimento prévio
          para esse compartilhamento. A implementação do Open Insurance obedece
          ao cronograma estabelecido pelo CNSP e pela SUSEP e, com sua evolução
          e futura integração com o Open Finance será possível compartilhar
          informações sobre seu relacionamento com bancos, corretoras de
          investimento e outros participantes do mercado financeiro nacional. O
          Sistema tem o objetivo de manter o cliente no centro, ou seja, toda a
          jornada no Open Insurance está voltada para facilitar o seu
          relacionamento com o mercado segurador, seja para aquisição de
          produtos e serviços, seja para a manutenção dos produtos que você já
          possui. Essa facilidade permite que você dê início a relacionamento
          com outras seguradoras mediante o compartilhamento seguro das suas
          informações já cadastradas na sua atual seguradora, facilitando a
          oferta e a aquisição de produtos e serviços que melhor atendam ao seu
          perfil e suas necessidades.
        </p>
        <strong>2. Quem participa do Open Insurance?</strong>
        <p>
          Participam do Open Insurance apenas seguradoras previamente
          autorizadas a funcionar pela Superintendência de Seguros Privados –
          SUSEP, órgão que regula o mercado segurador no Brasil. Para ver a
          relação completa das instituições participantes, acesse:{" "}
          <a
            href="https://www.opinbrasil.com.br/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.opinbrasil.com.br/
          </a>
        </p>
        <strong> 3. Qual a finalidade destes Termos e Condições?</strong>
        <p>
          Conforme esse documento de Termos e Condições, o Grupo CNP Seguros
          Brasil e as empresas coligadas serão os receptores dos dados que você
          escolher compartilhar conosco pelo Open Insurance, e poderão usar
          esses dados conforme as finalidades a serem atendidas com esse
          compartilhamento ou descritas nesse documento. É importante destacar
          que nós temos uma Política de Segurança de Informação rígida que prevê
          todos os cuidados para que suas informações estejam seguras, pelo
          período que ela permanecer com a gente Trabalhamos para proteger os
          seus dados contra destruição ou alteração acidental ou ilegal, perda
          acidental, divulgação ou acesso não autorizado e contra outras formas
          ilegais de tratamento de dados pessoais.
        </p>
        <strong>4. Que informações você pode compartilhar?</strong>
        <p>
          Abaixo, listamos os tipos de dados que você poderá compartilhar
          conosco:
        </p>
        - Dados Cadastrais (Pessoas Físicas):
        <ul>
          <li>nome completo;</li>
          <li>documento de identificação (CPF e outros);</li>
          <li>endereço residencial completo;</li>
          <li>número de telefone;</li>
          <li>e-mail(s);</li>
          <li>nome social;</li>
          <li>tipo de filiação;</li>
          <li>filiação (nome completo das pessoas relativas à filiação);</li>
          <li>data de nascimento;</li>
          <li>estado civil;</li>
          <li>sexo;</li>
          <li>nacionalidade;</li>
          <li>nome e CPF do cônjuge, renda;</li>
          <li>patrimônio;</li>
          <li>ocupação;</li>
          <li>data de início de relacionamento;</li>
          <li>tipos de produtos e serviços com contratos vigentes;</li>
          <li>nome e CPF do representante legal ou procurador (se houver).</li>
        </ul>
        - Dados Cadastrais (Pessoas Jurídicas):
        <ul>
          <li>razão social;</li>
          <li>nome fantasia (se houver);</li>
          <li>CNPJ ou número de registro no exterior (se houver);</li>
          <li>data da constituição;</li>
          <li>endereço completo;</li>
          <li>latitude e longitude;</li>
          <li>telefone;</li>
          <li>e-mail(s);</li>
          <li>
            nome, nome social e documento de identificação dos sócios,
            representantes ou administradores;
          </li>
          <li>participação societária de sócios;</li>
          <li>data de início de vínculo;</li>
          <li>participação societária;</li>
          <li>ramo de atuação principal e secundário (se houver);</li>
          <li>valor e frequência de faturamento;</li>
          <li>patrimônio;</li>
          <li>data de início de relacionamento da pessoa jurídica;</li>
          <li>tipos de produtos e serviços com contratos vigentes;</li>
          <li>
            nome, CPF ou CNPJ do representante legal ou procurador (se houver).
          </li>
        </ul>
        Dados transacionais: - Informações sobre sua apólice de seguro:
        <ul>
          <li>incluindo tipo de documento emitido;</li>
          <li>tipo de emissão;</li>
          <li>data de emissão;</li>
          <li>data de início e fim de vigência;</li>
          <li>limite máximo de garantia;</li>
          <li>nome, tipo de documento e numero de documento do segurado;</li>
          <li>
            nome, tipo de documento e número de documento do beneficiário (se
            houver);
          </li>
          <li>
            nome, tipo de documento e número de documento do tomador/ garantidor
            (se aplicável);
          </li>
          <li>
            identificação, tipo, tipo de documento, número de documento e
            endereço do intermediador;
          </li>
          <li>identificação, tipo, descrição e valor do objeto segurado;</li>
          <li>grupo e ramo de coberturas;</li>
          <li>limite máximo de indenização;</li>
          <li>tipo de coberturas;</li>
          <li>carência;</li>
          <li>franquias;</li>
          <li>participação obrigatória do segurado;</li>
          <li>cosseguro;</li>
          <li>cessionárias;</li>
          <li>histórico de movimentação de prêmios;</li>
          <li>valores pagos;</li>
          <li>assistências contratadas;</li>
          <li>benefícios ou serviços adicionais;</li>
          <li>sinistros avisados;</li>
          <li>pontuação;</li>
          <li>
            classe de bônus. - Informações sobre seu título de capitalização:
            dados de pagamento;
          </li>
          <li>valores dos títulos;</li>
          <li>prazo de vigência;</li>
          <li>datas de compra e resgate;</li>
          <li>dados de sorteio;</li>
          <li>índice de atualização.</li>
        </ul>
        <p>
          Importante saber: As informações que você compartilhar conosco ou com
          outras instituições no Open Insurance podem também conter informações
          relacionadas a terceiros. Por exemplo: a filiação de pessoa física,
          dados de identificação de sócios e representantes de pessoas jurídicas
          e dados sobre pagamentos recebidos e realizados por terceiros,
          beneficiários e dependentes. Estes dados também poderão ser usados
          para que possamos cumprir os objetivos do compartilhamento das
          informações conosco.
        </p>
        <strong>5. Como seus dados podem ser utilizados?</strong>
        <p>
          Com os dados que você vai compartilhar conosco vamos poder conhecer
          melhor seu perfil para te oferecer o melhor produto, serviço,
          vantagens, benefícios ou as melhores condições de contratação que
          façam sentido para seu momento de vida e para sua necessidade. Para
          isso os dados que você compartilhar conosco podem ser utilizados por
          qualquer empresa de nosso grupo, incluindo nossas empresas de seguros,
          capitalização ou consórcio. Esses dados compartilhados serão
          utilizados de acordo com a autorização que você nos forneceu ou usando
          outras hipóteses legais previstas na regulamentação de proteção de
          dados pessoais e nas regulamentações do mercado segurador. Nesse
          contexto podemos usar seus dados em nossos processos de prospecção,
          venda, cadastro, aceitação, avaliações de risco de subscrição e
          crédito, pós-venda, sinistro, endosso, resgate, financeiro e contábil,
          prevenção a fraudes, cumprimento de obrigações legais ou regulatórias
          e para nos defendermos eventualmente em processos judiciais, arbitrais
          ou administrativos. Os dados também poderão ser utilizados para
          criação e melhoria de nossos serviços, processos e produtos. Para
          saber mais sobre a política de privacidade do grupo CNP Seguros
          Brasil, acesse{" "}
          <a
            href="https://portalprivacidade.cnpbrasil.com.br"
            rel="noopener noreferrer"
            target="_blank"
          >
            portalprivacidade.cnpbrasil.com.br
          </a>
        </p>
        <strong>6. Quais são os passos para compartilhar seus dados</strong>
        <div>
          O compartilhamento dos seus dados pelo Open Insurance só ocorre após a
          sua autorização expressa, em um processo gratuito realizado pelos
          canais digitais das seguradoras e empresas participantes, com
          segurança, privacidade, agilidade, conveniência e transparência para
          que você tenha controle sobre os seus dados. Caso você não aceite,
          estes Termos e Condições, seus dados não serão compartilhados com as
          empresas do grupo CNP Seguros Brasil nenhuma ação descrita neste
          documento será realizada com base nos seus dados pessoais ou
          transacionais. Saiba abaixo como funciona as etapas da solicitação de
          compartilhamento de dados:
          <ol>
            <li>
              Você entre em nossos canais digitais, seja website, aplicativo de
              celular ou outros, e se identifica. Caso seja nosso cliente, você
              poderá informar seu usuário e senha para acesso. Caso não seja
              nosso cliente, será necessário realizar um rápido cadastro.
            </li>
            <li>
              Após o acesso, apresentaremos opções nas quais seus dados poderão
              ser utilizados: pode ser uma oferta de seguro, um benefício
              adicional a uma apólice que você possui conosco ou outro objetivo.
              Basta escolher uma delas e concordar com o compartilhamento dos
              seus dados com a gente.
            </li>
            <li>
              No passo seguinte, você deverá nos informar em qual instituição
              seus dados estão localizados para que eles possam ser
              compartilhados com a gente. Você será direcionado para a interface
              da seguradora e deverá garantir, pelos mecanismos de segurança,
              que é realmente você quem está solicitando a transferência dos
              seus dados.
            </li>
            <li>
              Após autorizar o compartilhamento, receberemos os dados que
              precisamos para cumprir com a finalidade da opção que você
              escolheu no início do processo. Importante informar que podemos
              usar esses dados por até 12 meses.
            </li>
            <li>
              Após finalizar as etapas de autorização do compartilhamento de
              seus dados poderemos solicitar esses dados atualizados para a
              instituição autorizada durante todo o período em que você
              consentiu.
            </li>
          </ol>
        </div>
        <strong>
          {" "}
          7. Como você gerencia seus compartilhamentos e quando eles serão
          encerrados
        </strong>
        <p>
          No Open Insurance, seus dados estão sob o seu total controle. Você
          poderá solicitar a revogação da autorização para tratamento dos seus
          dados a qualquer momento. O pedido poderá ser feito a nós ou à
          instituição de onde seus dados foram transferidos. A sua autorização
          de compartilhamento de dados pelo Open Insurance tem duração de no
          máximo, 12 (doze) meses. Ao fim desse prazo a sua autorização será
          revogada caso você não a renove. Para que você não perca nenhuma
          oportunidade ou facilidade fornecida a você a partir dos seus dados,
          vamos notificá-lo previamente sobre a proximidade do fim sua
          autorização de compartilhamento.
        </p>
        <strong>8. Alteração destes Termos e Condições</strong>
        <p>
          A qualquer momento poderemos alterar estes Termos e Condições em razão
          de novas exigências legais ou regulatórias, mudanças no ambiente do
          Open Insurance, incluindo evoluções e correções de erros ou caso a CNP
          Seguros Brasil entenda ser necessária alguma mudança, para a melhoria
          dos serviços. Nesse caso, se você continuar compartilhando seus dados,
          entenderemos que você estará de acordo com todas as atualizações dos
          Termos e Condições.
        </p>
      </S.TermosText>
    </OpinModal>
  );
};

export default TermosModal;
