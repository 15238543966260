import styled from "styled-components/macro";

import { Text } from "@ds/react";

import { ReactComponent as Logo } from "assets/icons/logo-previsul-footer.svg";

export const FooterMain = styled.footer(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "40px",
  gap: "40px",
  width: "100%",
  height: "fit-content",
  background: theme.color.neutral["08"],
  placeItems: "center",
  [theme.breakpoint.lg()]: {
    flexDirection: "row",
    padding: "60px 70px",
    alignItems: "flex-start",
    gap: "80px",
  },
}));

export const Content = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "22px",
}));

export const ContactsSection = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  fontSize: "14px",
  alignItems: "center",

  [theme.breakpoint.lg()]: {
    alignItems: "flex-start",
  },
}));

export const LinksSection = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  fontSize: "14px",

  [theme.breakpoint.lg()]: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

export const LinksSectionItem = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  placeItems: "center",
  fontSize: "14px",
  [theme.breakpoint.lg()]: {
    placeItems: "flex-start",
  },
}));

export const ContentTitle = styled(Text)(({ theme }) => ({
  color: theme.color.brandPrimary.light,
  textAlign: "center",
  [theme.breakpoint.lg()]: {
    textAlign: "left",
  },
}));

export const ContentDescription = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["03"],
  fontWeight: 400,
  textAlign: "center",
  [theme.breakpoint.lg()]: {
    textAlign: "left",
  },
}));

export const ContactItem = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  alignItems: "center",

  [theme.breakpoint.lg()]: {
    textAlign: "left",
    alignItems: "flex-start",
    gap: "12px",
  },
}));

export const ContactsBody = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  placeItems: "center",
  [theme.breakpoint.lg()]: {
    placeItems: "flex-start",
  },
}));

export const ItemTitle = styled(Text)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 400,
  color: theme.color.neutral["03"],
  [theme.breakpoint.lg()]: {
    fontSize: "16px",
  },
}));

export const ItemText = styled.a(({ theme }) => ({
  fontSize: "16px",
  fontWeight: 500,
  color: theme.color.brandPrimary.dark,
  textDecoration: "underline",
  cursor: "pointer",
  [theme.breakpoint.lg()]: {
    fontSize: "16px",
  },
}));

export const LinksBody = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  placeItems: "center",
  [theme.breakpoint.lg()]: {
    placeItems: "flex-start",
  },
}));

export const FooterLink = styled.a(({ theme }) => ({
  color: theme.color.neutral["03"],
  textAlign: "center",
  fontWeight: 400,
  textDecoration: "underline",
  cursor: "pointer",
  [theme.breakpoint.lg()]: {
    textAlign: "left",
  },
}));

export const LogoContainer = styled.div(({ theme }) => ({
  display: "none",
  [theme.breakpoint.lg()]: {
    display: "flex",
    flexDirection: "column",
    placeItems: "center",
    gap: "16px",
  },
}));

export const LogoPrevisul = styled(Logo)(({ theme }) => ({
  width: "155px",
}));

export const Copyright = styled(Text)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 400,
  color: theme.color.neutral["02"],
}));
