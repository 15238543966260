import { useCallback, useEffect, useState } from "react";
import useIsFetchingAprovadores from "./useIsFetchingAprovadores";
import useMutableRef from "./useMutableRef";
import useApi from "./useApi";
import { REQUEST_METHODS } from "utils/constants";

let apolicesCache = {};

const useAprovadoresByApolice = () => {
  const {
    hasSomeAprovadoresBeingFetched,
    addNewCallToGetAprovadores,
    removeCallToGetAprovadores,
  } = useIsFetchingAprovadores();

  const [selectedApolice, setSelecetedApolice] = useState(null);
  const [previousRequestId, setPreviousRequestId] = useState(null);
  const [aprovadores, setAprovadores] = useState([]);
  const [occurredErrorInAprsRequest, setOccurredErrorInAprsRequest] =
    useState(null);

  const { response, error, fetchData, clearRequestData } = useApi({
    method: REQUEST_METHODS.GET,
    url: `fake/${selectedApolice?.id}`,
    autoRun: false,
  });

  const getAprovadores = (apolice) => setSelecetedApolice(apolice);

  const clearAprovadoresState = useCallback(() => {
    apolicesCache = {};
    setOccurredErrorInAprsRequest(null);
    setSelecetedApolice(null);
    setAprovadores([]);
    clearRequestData();
  }, [clearRequestData]);

  const addNewAprovadores = useCallback(
    (apolice, aprovadoresToAdd = []) => {
      const newAprovadores = { ...aprovadores };

      aprovadoresToAdd.forEach((newAprovador) => {
        const aprovadorCPF = newAprovador.identification;
        if (!newAprovadores[aprovadorCPF])
          newAprovadores[aprovadorCPF] = { ...newAprovador, apolices: [] };
        newAprovadores[aprovadorCPF].apolices.push(apolice);
      });

      setAprovadores(newAprovadores);
    },
    [aprovadores]
  );

  const addNewAprsRef = useMutableRef(addNewAprovadores);
  const addNewCallToGetAprsRef = useMutableRef(addNewCallToGetAprovadores);
  const removeCallToGetAprsRef = useMutableRef(removeCallToGetAprovadores);
  const fetchAprovadoresRef = useMutableRef(fetchData);

  useEffect(() => {
    if (
      !!selectedApolice &&
      !apolicesCache[selectedApolice.id] &&
      !!fetchAprovadoresRef.current &&
      !occurredErrorInAprsRequest
    ) {
      fetchAprovadoresRef.current();
      addNewCallToGetAprsRef.current();
      apolicesCache[selectedApolice?.id] = selectedApolice;
    }
  }, [
    selectedApolice,
    fetchAprovadoresRef,
    addNewCallToGetAprsRef,
    occurredErrorInAprsRequest,
  ]);

  useEffect(() => {
    const isAddAprovadoresEnabled =
      response && response?.config?.requestId !== previousRequestId; // NOSONAR

    if (isAddAprovadoresEnabled) {
      const newAprovadoresToAdd = response.data;
      const requestApoliceId = response.config.url.split("=")[1];

      addNewAprsRef.current(
        apolicesCache[requestApoliceId],
        newAprovadoresToAdd
      );

      removeCallToGetAprsRef.current();

      setPreviousRequestId(response?.config?.requestId);
    }
  }, [
    response,
    selectedApolice,
    addNewAprsRef,
    removeCallToGetAprsRef,
    previousRequestId,
  ]);

  useEffect(() => {
    if (error && selectedApolice) {
      setOccurredErrorInAprsRequest(error);
      removeCallToGetAprsRef.current();
    }
  }, [error, removeCallToGetAprsRef, selectedApolice]);

  return {
    aprovadores,
    hasSomeAprovadoresBeingFetched,
    getAprovadores,
    clearAprovadoresState,
    occurredErrorInAprsRequest,
  };
};

export default useAprovadoresByApolice;
