import { Button, Text, useDeviceType } from "@ds/react";
import Welcome from "components/Welcome";
import withTemplate from "hoc/withTemplate";
import useEfetivacaoData from "hooks/useEfetivacaoData";
import { useModalsContext } from "hooks/useModalsContext";
import { useAuth } from "oidc-react";
import DisabledDataToShareCard from "pages/Consentimento/components/DisabledDataToShareCard/DisabledDataToShareCard";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getMonthDifference, partialCPF } from "utils/helpers";
import OpinLogo from "../../assets/icons/OpenInsuranceLogo.svg";
import SuccessCheckDesktop from "../../assets/icons/success-check-desktop.svg";
import SuccessCheck from "../../assets/icons/success-check.svg";
import Card from "../../components/Card";
import NavigationHeader from "../../components/NavigationHeader";
import * as S from "./Efetivacao.style";

const Efetivacao = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { renderModal } = useModalsContext();
  const { data: consentimento, loading } = useEfetivacaoData();
  const { lg: isLargeDevice } = useDeviceType();

  const { nome, cpf } = auth?.userData?.profile || {};
  const {
    creationDateTime,
    organizationName,
    expirationDateTime,
    statusUpdateDateTime,
    permissions,
    segmento,
  } = consentimento || {};

  const expirationTimeMessage = useMemo(() => {
    const expirationDate = new Date(expirationDateTime);
    const currentDate = new Date(creationDateTime);
    const monthsDiff = getMonthDifference(currentDate, expirationDate);
    return `${monthsDiff} meses, até ${expirationDate.toLocaleDateString()}`;
  }, [creationDateTime, expirationDateTime]);

  if (loading) return <Welcome />;

  if (state?.error) {
    return (
      <>
        <NavigationHeader
          text="Voltar para Open Insurance"
          onBack={() => navigate("/")}
        />
        <S.Container>
          <S.EnterprisesInfo>
            <S.Info>
              <img
                src={!isLargeDevice ? SuccessCheck : SuccessCheckDesktop}
                alt="Previsul Logo"
              />
              <S.Title variant="headline-05">
                Compartilhamento de dados cancelado com sucesso!
              </S.Title>
            </S.Info>
          </S.EnterprisesInfo>

          <Card title={<img src={OpinLogo} alt="Open Insurance Logo" />}>
            <Text variant="body-02-md">
              Agora que sua solicitação foi cancelada, você pode iniciar uma
              nova solicitação ou gerenciar os seus compartilhamentos.
            </Text>
            <S.ButtonContainer>
              <Button
                variant="secondary-md"
                type="button"
                onClick={() => navigate("/opin/consentimento")}
                fullWidth={!isLargeDevice}
              >
                Nova solicitação
              </Button>
              <S.Link onClick={() => navigate("/opin/gestao")}>
                Meus compartilhamentos
              </S.Link>
            </S.ButtonContainer>
          </Card>
          {renderModal()}
        </S.Container>
      </>
    );
  }

  return (
    <>
      <NavigationHeader
        text="Voltar para Open Insurance"
        onBack={() => navigate("/")}
      />

      <S.Container>
        <S.EnterprisesInfo>
          <S.Info>
            <img
              src={!isLargeDevice ? SuccessCheck : SuccessCheckDesktop}
              alt="Previsul Logo"
            />
            <S.Title variant="headline-05">
              Compartilhamento de dados realizado com sucesso!
            </S.Title>
          </S.Info>
          <Text variant="body-02-md" display="inline-block">
            <strong>É bom saber:</strong> já estamos recebendo os dados da
            instituição {organizationName}. Porém, se o seu produto tiver
            movimentações mais recentes, elas serão atualizadas em até 2 dias
            úteis.
          </Text>
        </S.EnterprisesInfo>

        <Card title="Resumo do compartilhamento" hasDivider>
          <S.DataSection>
            <S.Caption variant="caption-02">Solicitação criada em</S.Caption>
            <Text variant="body-02-md">
              {new Date(creationDateTime).toLocaleString()}
            </Text>
          </S.DataSection>
          <S.DataSection>
            <S.Caption variant="caption-02">Realizada por</S.Caption>
            <Text variant="body-02-md">{nome}</Text>
          </S.DataSection>
          <S.DataSection>
            <S.Caption variant="caption-02">CPF</S.Caption>
            <Text variant="body-02-md">{partialCPF(cpf)}</Text>
          </S.DataSection>
          <S.DataSection>
            <S.Caption variant="caption-02">Finalidade dos dados</S.Caption>
            <Text variant="body-02-md">
              Ofertar produtos e serviços personalizados de acordo com a sua
              necessidade.
            </Text>
          </S.DataSection>
          <S.DataSection>
            <S.Caption variant="caption-02">Segmento</S.Caption>
            <Text variant="body-02-md">{segmento}</Text>
          </S.DataSection>
          <S.DataSection>
            <S.Caption variant="caption-02">Data da confirmação</S.Caption>
            <Text variant="body-02-md">
              {new Date(statusUpdateDateTime).toLocaleString()}
            </Text>
          </S.DataSection>
          <S.DataSection>
            <S.Caption variant="caption-02">
              Instituição que vai receber os dados
            </S.Caption>
            <Text variant="body-02-md">Previsul Seguradora</Text>
          </S.DataSection>
          <S.DataSection>
            <S.Caption variant="caption-02">
              Período de compartilhamento
            </S.Caption>
            <Text variant="body-02-md">{expirationTimeMessage}</Text>
          </S.DataSection>
        </Card>

        <DisabledDataToShareCard
          cardTitle="Dados compartilhados"
          permissions={permissions}
          segmento="Patrimonial" // TODO: mockado enquando o dado não vem da API
        />

        <Card title={<img src={OpinLogo} alt="Open Insurance Logo" />}>
          <Text variant="body-02-md">
            Agora que sua solicitação foi finalizada, você pode iniciar uma nova
            solicitação ou gerenciar os seus compartilhamentos.
          </Text>
          <S.ButtonContainer>
            <Button
              variant="secondary-md"
              type="button"
              onClick={() => navigate("/opin/consentimento")}
              fullWidth={!isLargeDevice}
            >
              Nova solicitação
            </Button>
            <S.Link onClick={() => navigate("/opin/gestao")}>
              Meus compartilhamentos
            </S.Link>
          </S.ButtonContainer>
        </Card>
      </S.Container>

      {renderModal()}
    </>
  );
};

export default withTemplate(Efetivacao);
