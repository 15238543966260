import { Divider, Text } from "@ds/react";
import React, { useCallback, useEffect, useState } from "react";
import { ReactComponent as ArrowUp } from "../../../../../../assets/icons/arrow-single-up.svg";
import { ReactComponent as ArrowDown } from "../../../../../../assets/icons/arrow-single-down.svg";
import * as S from "./InstituicaoCard.style";
import useOrganisation from "hooks/useOrganisation";
import { formatCNPJ } from "utils/helpers";
import { pegarParticipanteByOrgIdUrl } from "services/endpoints";

const InstituicaoCard = ({ instituicao, onSelect }) => {
  const { getOrganisation, loading, data: organisation } = useOrganisation();
  const { AuthorisationServers, RegistrationNumber } = organisation || {};

  const [isDetailsOpened, setIsDetailsOpened] = useState(false);
  const { CustomerFriendlyName, CustomerFriendlyDescription, organisationId } =
    instituicao;

  const toggleCardDetails = useCallback(() => {
    setIsDetailsOpened((previous) => !previous);
  }, [setIsDetailsOpened]);

  useEffect(() => {
    if (isDetailsOpened)
      getOrganisation({ url: pegarParticipanteByOrgIdUrl(organisationId) });
  }, [getOrganisation, isDetailsOpened, organisationId]);

  const renderInstituicoesParticipantes = useCallback((instituicoes) => {
    const instituicoesAmount = instituicoes?.length;
    if (!instituicoesAmount) return null;

    const amountInstituicoesInfo =
      instituicoesAmount === 1
        ? `${instituicoesAmount} instituição participa dessa marca`
        : `${instituicoesAmount} instituições participam dessa marca`;

    return (
      <>
        <S.ParticipantesQuantityContainer>
          <Text variant="caption-02">{amountInstituicoesInfo}</Text>
        </S.ParticipantesQuantityContainer>

        <S.InstituicoesList>
          {instituicoes.map((instituicao) => (
            <S.InstituicaoItem key={instituicao.AuthorisationServerId}>
              {instituicao.CustomerFriendlyName}
            </S.InstituicaoItem>
          ))}
        </S.InstituicoesList>
      </>
    );
  }, []);

  const renderArrowIcon = useCallback(
    () => (isDetailsOpened ? <ArrowUp /> : <ArrowDown />),
    [isDetailsOpened]
  );

  const renderCardContent = useCallback(() => {
    if (loading) return <Text variant="body-01-sm">Carregando...</Text>;

    return (
      <>
        <Divider />

        <Text variant="body-01-sm">CNPJ: {formatCNPJ(RegistrationNumber)}</Text>
        <Text variant="body-02-sm">{CustomerFriendlyDescription}</Text>
        {renderInstituicoesParticipantes(AuthorisationServers)}

        <Text variant="caption-02">
          Para mais detalhes, acesse o&nbsp;
          <S.BodyLink
            href="https://opinbrasil.com.br/"
            target="_blank"
            rel="noreferrer"
          >
            Portal do Open Insurance.
          </S.BodyLink>
        </Text>
      </>
    );
  }, [
    AuthorisationServers,
    CustomerFriendlyDescription,
    RegistrationNumber,
    loading,
    renderInstituicoesParticipantes,
  ]);

  return (
    <S.InstituicaoContainer>
      <S.InstituicaoInfo onClick={toggleCardDetails}>
        <div>
          <S.InstituicaoCardHeaderText className="title" variant="headline-07">
            {CustomerFriendlyName}
          </S.InstituicaoCardHeaderText>
          <S.InstituicaoCardHeaderText
            className="subtitle"
            variant="caption-01"
          >
            Seguradora
          </S.InstituicaoCardHeaderText>
        </div>

        {renderArrowIcon()}
      </S.InstituicaoInfo>

      <S.InstituicaoContent>
        {isDetailsOpened && renderCardContent()}
      </S.InstituicaoContent>

      <Divider />

      <S.ChooseButton variant="primary-01-md" onClick={onSelect}>
        <Text variant="button-01-md">Escolher</Text>
      </S.ChooseButton>
    </S.InstituicaoContainer>
  );
};

export default InstituicaoCard;
