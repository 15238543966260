import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCallbackDataUrl,
  getConsentimentoRecebidoUrl,
  getPermissionsByNamesUrl,
} from "services/endpoints";
import { useOpinAuth } from "./useOpinAuth";
import { EFETIVACAO_MODALS } from "context/modalsContext";
import { useModalsContext } from "./useModalsContext";

const useEfetivacaoData = () => {
  const api = useOpinAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { openModal } = useModalsContext();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    code,
    state,
    idToken,
    errorDescription,
    error: canceled,
  } = location.state || {};

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const { data: callbackData } = await api.post(
        getCallbackDataUrl(),
        idToken
          ? { code, state, id_token: idToken }
          : { error: canceled, error_description: errorDescription, state }
      );

      const { data: consentimento } = await api.get(
        getConsentimentoRecebidoUrl(callbackData.consent_id)
      );
      const { data: permissions } = await api.get(
        getPermissionsByNamesUrl(consentimento.permissions)
      );

      setData({
        ...consentimento,
        segmento: "Patrimonial", // TODO: mockado enquando o dado não vem da API
        permissions: {
          required: permissions?.filter?.((p) => p.obrigatorio),
          optional: permissions?.filter?.((p) => !p.obrigatorio),
        },
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [api, idToken, code, state, canceled, errorDescription]);

  useEffect(() => {
    if (error && !error?.response?.data?.consent_id)
      openModal(EFETIVACAO_MODALS.error);
  }, [error, openModal]);

  useEffect(() => {
    fetchData();
  }, [fetchData, idToken, navigate]);

  return {
    loading,
    data,
    success: !error && data,
  };
};

export default useEfetivacaoData;
