export const ETAPAS = ["Compartilhamento", "Confirmação"];

export const GET_APROVADORES_ERROR_MESSAGE =
  "Houve erro ao recuperar os dados das apólices selecionadas.\nTente novamente mais tarde.";

export const DIFFERENT_CPF_ERROR_TYPE = "CPF_DIVERGENTE_CONSENTIMENTO";

export const RESOURCES_READ = ["RESOURCES_READ"];

export const FAKE_INTERACTION_DATA = {
  data: {
    url: "https://arealogadahm.caixaseguradora.com.br/opin/autorizacao?interactionId=UjeRrfrerO7Wj3MV8WAmJ&kind=DATA",
    interactionId: "UjeRrfrerO7Wj3MV8WAmJ",
    consent: {
      permissions: [
        "CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ",
        "CUSTOMERS_PERSONAL_ADDITIONALINFO_READ",
        "CUSTOMERS_PERSONAL_QUALIFICATION_READ",
        "DAMAGES_AND_PEOPLE_PATRIMONIAL_READ",
        "DAMAGES_AND_PEOPLE_PATRIMONIAL_POLICYINFO_READ",
        "DAMAGES_AND_PEOPLE_PATRIMONIAL_PREMIUM_READ",
        "DAMAGES_AND_PEOPLE_PATRIMONIAL_CLAIM_READ",
        "RESOURCES_READ",
      ],
      creationDateTime: "2023-06-22T19:24:57.649Z",
      status: "AWAITING_AUTHORISATION",
      statusUpdateDateTime: "2023-06-22T19:24:57.649Z",
      expirationDateTime: "2023-08-31T00:00:00.000Z",
      loggedUser: {
        document: {
          identification: "04753055663",
          rel: "CPF",
        },
      },
      kind: "DATA",
      fapiInteractionId: "9195615f-9b5c-441b-8c06-9db67f93f67d",
      applicantId: "4ed2845e-d198-4a3c-a60f-c773619428f9",
      applicantSoftwareId: "719be799-9ecd-4b84-a736-a9fd4408784d",
      events: [
        {
          date: "2023-06-22T19:24:57.649Z",
          event: "AWAITING_AUTHORISATION",
        },
      ],
      consentId: "urn:caixaseguradora:78491556-345e-486e-b1c3-e93b0c425630",
      consentTerm: 2,
      consentTermUnit: "M",
      organizationName: "Brick Insurance Dev",
      organizationLogo:
        "https://finansystech-pub.s3.sa-east-1.amazonaws.com/f_logo.svg",
      meta: {
        totalRecords: 1,
        totalPages: 1,
      },
    },
    segment: "PATRIMONIAL",
    expirationDateTime: "2023-06-22T20:24:57.649Z",
  },
};
