import { REQUEST_METHODS } from "utils/constants";
import useApi from "./useApi";

const useOrganisation = () => {
  const { data, error, loading, success, fetchData } = useApi({
    method: REQUEST_METHODS.GET,
    autoRun: false,
  });

  return {
    loading,
    data,
    success,
    error,
    getOrganisation: fetchData,
  };
};

export default useOrganisation;
