import { Skeleton } from "@ds/react";
import * as S from "./CardLoading.style";

const CardLoading = () => {
  return (
    <S.LoadingContainer>
      <Skeleton lines={4} />
    </S.LoadingContainer>
  );
};

export default CardLoading;
