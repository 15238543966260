import { useAuth } from "oidc-react";
import { useState, useEffect, useCallback } from "react";
import { useOpinAuth } from "./useOpinAuth";

const useApi = ({
  url,
  method,
  body = null,
  configHeaders = null,
  autoRun = true,
}) => {
  const api = useOpinAuth();

  const [data, setData] = useState(null);
  const [response, setResponse] = useState(null);
  const [status, setStatus] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const fetchData = useCallback(
    async (config) => {
      try {
        const headers = {
          ...configHeaders,
          Authorization: `Bearer ${auth?.userData?.access_token}`,
        };
        setLoading(true);
        const res = await api({
          method,
          url: url,
          headers,
          data: body,
          ...config,
        });
        setResponse(res);
        setData(res.data);
        setStatus(res.status);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    },
    [configHeaders, auth?.userData?.access_token, api, method, url, body]
  );

  const clearData = () => {
    setData(null);
    setError("");
    setLoading(false);
    setResponse(null);
    setStatus(null);
  };

  useEffect(() => {
    if (autoRun && !data && !error) fetchData();
  }, [fetchData, autoRun, data, error]);

  return { data, error, loading, fetchData, status, response, clearData };
};

export default useApi;
