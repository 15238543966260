import { Text } from "@ds/react";
import BodyText from "components/BodyText/BodyText";
import { useGestaoContext } from "hooks/useGestaoContext";
import useDadosCompartilhados from "pages/Gestao/hooks/useDadosCompartilhados";
import * as S from "../CompartilhamentoDesktop.style";

const DadosCompartilhadosItemSection = () => {
  const { selectedCompartilhamento } = useGestaoContext();
  const { renderPermissions, renderSelectedApolicesInfo } =
    useDadosCompartilhados();

  const { segmento } = selectedCompartilhamento || {};

  return (
    <S.Section>
      <Text variant="caption-01">Dados compartilhados</Text>

      <S.SharedDataSection>
        <S.DataSection className="distributed">
          {renderPermissions()}
        </S.DataSection>

        {segmento && (
          <S.DataSection>
            <Text variant="caption-01">Segmento/produto</Text>
            <BodyText>Apólice de {segmento || "--"}</BodyText>
            {renderSelectedApolicesInfo()}
          </S.DataSection>
        )}
      </S.SharedDataSection>
    </S.Section>
  );
};

export default DadosCompartilhadosItemSection;
