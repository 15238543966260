export const ETAPAS_CRIACAO = [
  "Solicitação",
  "Compartilhamento",
  "Termos e condições",
];

export const ETAPAS_ALTERACAO = [
  "Solicitação",
  "Compartilhamento",
  "Termos e condições",
];

export const ETAPAS_RENOVACAO = ["Renovação", "Termos e condições"];

export const RESOURCES_READ = ["RESOURCES_READ"];

export const PRAZOS_MOCK = [
  { id: 3, value: "3 meses" },
  { id: 6, value: "6 meses" },
  { id: 12, value: "12 meses" },
];
