import React, { useMemo } from "react";
import * as S from "./AutorizacaoPendente.style";
import OpinLogo from "assets/icons/OpenInsuranceLogo.svg";
import { useModalsContext } from "hooks/useModalsContext";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import NavigationHeader from "components/NavigationHeader/NavigationHeader";
import Card from "components/Card/Card";
import { Text } from "@ds/react";
import { formatarDataHora } from "utils/helpers";
import BodyText from "components/BodyText/BodyText";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import waitingIcon from "assets/icons/waiting.svg";
import { useNavigate } from "react-router-dom";

const AutorizacaoPendente = () => {
  const navigate = useNavigate();
  const { openModal, renderModal } = useModalsContext();
  const { interaction, apolices, aprovadores } = useAutorizacaoContext();
  const interactionData = interaction?.data;

  const solicitante = useMemo(
    () => aprovadores.find((aprovador) => aprovador.solicitante),
    [aprovadores]
  );

  const apolicesSelecionadas = useMemo(
    () => apolices?.filter((apolice) => apolice.selected),
    [apolices]
  );

  const timeCalculated = useMemo(() => {
    const data1 = new Date(interactionData?.consent?.creationDateTime);
    const data2 = new Date(interactionData?.consent?.expirationDateTime);
    return (
      (data2.getFullYear() - data1.getFullYear()) * 12 +
        (data2.getMonth() - data1.getMonth()) || 0
    );
  }, [interactionData]);

  const segmento = useMemo(() => {
    const segmentos = interactionData?.consent?.permissions?.map(
      (permission) => {
        if (permission.includes("AUTO")) {
          return "Automóvel";
        }
        return "";
      }
    );

    return segmentos?.filter(Boolean)[0] || "";
  }, [interactionData?.consent?.permissions]);

  const formattedCPF = useMemo(() => {
    const middleThree =
      interactionData?.consent?.loggedUser?.document?.identification?.slice(
        4,
        7
      );
    const lastThree =
      interactionData?.consent?.loggedUser?.document?.identification?.slice(
        7,
        10
      );
    return `***.${middleThree}.${lastThree}-**`;
  }, [interactionData?.consent?.loggedUser?.document?.identification]);

  return (
    <S.Container>
      <div>
        <NavigationHeader
          text="Voltar para Open Insurance"
          onBack={() => navigate("/opin")}
        />
        <Card lead="Efetivação da solicitação" hasDivider>
          <S.Info>
            <img src={waitingIcon} alt="Icone de Espera" />
            <Text variant="headline-05">
              Acesso aos dados pendente de autorização!
            </Text>
          </S.Info>
        </Card>
      </div>
      <Card title="Autorização" hasDivider>
        <div>
          <Text variant="body-02-md">
            Identificamos que será necessária a autorização de mais pessoas para
            o acesso aos dados.
          </Text>
        </div>
        <div>
          <Text variant="body-02-md">
            <strong>
              A autorização deve ser concluída por todos os representantes até
              {formatarDataHora(
                interactionData?.consent?.expirationDateTime
              )},{" "}
            </strong>
            se esse prazo expirar, será necessária uma nova solicitação de
            compartilhamento.
          </Text>
        </div>
        <div>
          <Text variant="caption-02">
            <strong>A solicitação foi realizada por:</strong>
          </Text>
        </div>
        <div>
          <Text variant="caption-02" color="text-light">
            CPF
          </Text>
          <BodyText>{formattedCPF}</BodyText>
        </div>
        <div>
          <Text variant="caption-02" color="text-light">
            Nome
          </Text>
          <BodyText>{solicitante?.nome}</BodyText>
        </div>
      </Card>
      <Card title="Resumo da solicitação" hasDivider>
        <div>
          <Text variant="caption-02" color="text-light">
            Solicitação criada em
          </Text>
          <BodyText>
            {formatarDataHora(interactionData?.consent?.creationDateTime)}
          </BodyText>
        </div>
        <div>
          <Text variant="caption-02" color="text-light">
            CNPJ
          </Text>
          <BodyText>12.345.678/0001-14</BodyText>
        </div>
        <div>
          <Text variant="caption-02" color="text-light">
            Razão Social
          </Text>
          <BodyText>Lorem Ipsum Ltda</BodyText>
        </div>
        <div>
          <Text variant="caption-02" color="text-light">
            Segmento
          </Text>
          <BodyText>{segmento}</BodyText>
        </div>
        <div>
          <Text variant="caption-02" color="text-light">
            Data da confirmação
          </Text>
          <BodyText>01/07/2022 às 18:57</BodyText>
        </div>
        <div>
          <Text variant="caption-02" color="text-light">
            Instituição que vai receber os dados
          </Text>
          <BodyText>{interactionData?.consent?.organizationName}</BodyText>
        </div>
        <div>
          <Text variant="caption-02" color="text-light">
            Período de compartilhamento
          </Text>
          <BodyText>
            {timeCalculated} meses, até{" "}
            {formatarDataHora(interactionData?.consent?.expirationDateTime)}
          </BodyText>
        </div>
      </Card>

      <Card title="Dados compartilhados">
        <S.SharedDataSection>
          <S.BoldCaption variant="caption-02" color="text-light">
            Dados necessários
          </S.BoldCaption>
          <S.Link onClick={() => openModal(AUTORIZACAO_MODALS.dadosCadastrais)}>
            Dados cadastrais
          </S.Link>
          <S.Link
            onClick={() => openModal(AUTORIZACAO_MODALS.dadosComplementares)}
          >
            Informações complementares
          </S.Link>
        </S.SharedDataSection>
        <S.SharedDataSection>
          <S.BoldCaption variant="caption-02" color="text-light">
            Origem dos dados
          </S.BoldCaption>
          <BodyText>Apólice de automóvel</BodyText>
          <S.Link
            onClick={() => openModal(AUTORIZACAO_MODALS.selectedApolices)}
          >
            {apolicesSelecionadas?.length} apólices selecionadas
          </S.Link>
        </S.SharedDataSection>
        <S.SharedDataSection>
          <S.BoldCaption variant="caption-02" color="text-light">
            Dados opcionais
          </S.BoldCaption>
          <S.Link
            onClick={() => openModal(AUTORIZACAO_MODALS.dadosQualitativos)}
          >
            Dados qualitativos
          </S.Link>
        </S.SharedDataSection>
      </Card>
      <Card title={<img src={OpinLogo} alt="Open Insurance Logo" />}>
        <Text variant="body-02-md">
          Agora que sua solicitação foi finalizada, você pode iniciar uma nova
          solicitação ou gerenciar os seus compartilhamentos.
        </Text>
        <S.ButtonContainer>
          <S.Link onClick={() => navigate("/opin/gestao")}>
            Ir para Meus compartilhamentos
          </S.Link>
        </S.ButtonContainer>
      </Card>
      {renderModal()}
    </S.Container>
  );
};

export default AutorizacaoPendente;
