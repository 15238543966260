import React, { useEffect } from "react";
import PrevisulLogo from "assets/icons/logo-previsul-footer.svg";
import OpinLogo from "assets/icons/OpenInsuranceLogo.svg";
import ArrowLineDown from "assets/icons/arrow-line-down.svg";
import * as S from "./Redirecionamento.style";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { useModalsContext } from "hooks/useModalsContext";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import BodyText from "components/BodyText/BodyText";
import { Text } from "@ds/react";
import Card from "components/Card/Card";
import useAuthConfirmation from "hooks/useAuthConfirmation";
import useMutableRef from "hooks/useMutableRef";
import { PREVISUL_NAME_MOCK } from "utils/constants";

const Redirecionamento = () => {
  const { interaction } = useAutorizacaoContext();
  const { renderModal, openModal } = useModalsContext();

  const { interactionId, consent } = interaction?.data || {};
  const { organizationLogo, organizationName } = consent || {};

  const { confirmSolicitation, response, error, loading, redirect } =
    useAuthConfirmation(interactionId);

  const confirmSolicitationRef = useMutableRef(confirmSolicitation);

  useEffect(() => {
    if (confirmSolicitationRef.current) confirmSolicitationRef.current();
  }, [confirmSolicitationRef]);

  useEffect(() => {
    const modalData = {
      loading,
      onTryAgain: confirmSolicitationRef.current,
      organizationName: organizationName,
    };

    if (error) openModal(AUTORIZACAO_MODALS.redirectError, modalData);
    if (response) redirect();
  }, [
    confirmSolicitationRef,
    error,
    loading,
    openModal,
    organizationName,
    redirect,
    response,
  ]);

  return (
    <S.Container>
      <Card title="Aguarde enquanto te redirecionamos..." hasDivider>
        <S.EnterprisesInfo>
          <S.Info>
            <S.OrgLogo
              src={PrevisulLogo}
              alt={`Logo da empresa ${PREVISUL_NAME_MOCK}`}
            />
            <Text variant="body-02-md">{PREVISUL_NAME_MOCK}</Text>
          </S.Info>
          <S.Info center>
            <img src={ArrowLineDown} alt="Arrow Line Down" />
            <BodyText>
              Você está sendo redirecionado para o ambiente da instituição
              selecionada.
            </BodyText>
          </S.Info>
          <S.Info>
            <S.OrgLogo src={organizationLogo} alt={organizationName} />
            <Text variant="body-02-md">{organizationName}</Text>
          </S.Info>
        </S.EnterprisesInfo>
        <div>
          <img src={OpinLogo} alt="OpenInsurance logo" />
          <BodyText>
            Este compartilhamento de dados será realizado através do Open
            Insurance.
          </BodyText>
        </div>
      </Card>

      {renderModal()}
    </S.Container>
  );
};

export default Redirecionamento;
