import { Text } from "@ds/react";
import styled from "styled-components";
export { Link } from "../../Confirmacao.style";

export const SharedDataSection = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
}));

export const BoldCaption = styled(Text)`
  && {
    font-weight: 700;
  }
`;
