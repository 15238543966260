import { Text } from "@ds/react";
import { ReactComponent as WaitingIcon } from "assets/icons/waiting.svg";
import Card from "components/Card";
import * as S from "./Welcome.style";

const Welcome = () => (
  <Card>
    <S.CardContentContainer>
      <WaitingIcon />
      <Text color="primary-01-md" variant="body-01-md">
        Bem vindo ao Portal do Segurado. <br /> <br /> Aguarde um pouco enquanto
        preparamos sua experiência.
      </Text>
    </S.CardContentContainer>
  </Card>
);

export default Welcome;
