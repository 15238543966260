import Footer from "components/Footer";
import HeaderOpin from "components/HeaderOpin";
import Main from "components/Main";

const withTemplate = (Component) => {
  return ({ ...props }) => (
    <>
      <HeaderOpin />
      <Main>
        <Component {...props} />
      </Main>
      <Footer />
    </>
  );
};

export default withTemplate;
