import { useEffect, useState } from "react";
import { getPermissionsByNamesUrl } from "services/endpoints";
import useApi from "./useApi";
import { RESOURCES_READ } from "utils/constants";

const usePermissionsByNames = () => {
  const [namesList, setNamesList] = useState([]);

  const { data, loading, error, fetchData } = useApi({
    url: getPermissionsByNamesUrl(
      namesList.filter((name) => name !== RESOURCES_READ[0])
    ),
    autoRun: false,
  });

  useEffect(() => {
    if (namesList.length && fetchData) fetchData();
  }, [namesList, fetchData]);

  return {
    loading,
    error,
    data,
    getDetailedPermissions: (permissionNames) => setNamesList(permissionNames),
  };
};

export default usePermissionsByNames;
