import { Modal } from "@ds/react";
import styled from "styled-components/macro";

export const OpinModal = styled(Modal)(({ theme }) => ({
  h3: {
    color: theme.color.neutral["02"],
    textTransform: "none",
    fontSize: 28,
  },

  ".open": {
    maxHeight: "calc(100% - 50px)",
  },

  [theme.breakpoint.lg()]: {
    h3: {
      fontSize: 24,
    },
    ".open": {
      maxHeight: "inherit",
    },
  },
}));
