import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { GET_APROVADORES_ERROR_MESSAGE } from "../../constants";
import * as S from "./Compartilhamento.style";
import ApolicesInfoData from "./components/ApolicesDataInfo";
import DataSharingInfo from "./components/DataSharingInfo";
import SemContratosCard from "./components/SemContratosCard";
import { Button, useDeviceType } from "@ds/react";
import { useModalsContext } from "hooks/useModalsContext";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import Card from "components/Card/Card";
import useFetchAutorizacaoData from "hooks/useFetchAutorizacaoData";
import PermissionsDataInfo from "./components/PermissionsDataInfo";

const Compartilhamento = ({ onSubmit }) => {
  const {
    apolices,
    apolicesSelecionadas,
    hasSomeAprovadoresBeingFetched,
    occurredErrorInAprsRequest,
    clearSelectedApolices,
    hasOnlyApolicesPermissions,
    detailedPermissions,
  } = useAutorizacaoContext();
  const { mobile: isSmallDevice } = useDeviceType();
  const { openModal } = useModalsContext();
  const { loading } = useFetchAutorizacaoData();

  const isSubmitDisabled =
    hasOnlyApolicesPermissions && !apolicesSelecionadas.length;

  const handleSubmitShare = () => {
    if (occurredErrorInAprsRequest) {
      clearSelectedApolices();
      openModal(AUTORIZACAO_MODALS.genericError, {
        error: occurredErrorInAprsRequest,
        message: GET_APROVADORES_ERROR_MESSAGE,
      });
    } else onSubmit();
  };

  if (hasOnlyApolicesPermissions && apolices?.total === 0)
    return <SemContratosCard />;

  return (
    <S.Container>
      <DataSharingInfo loading={loading} />

      <Card loading={loading} title="O que será compartilhado?">
        <PermissionsDataInfo permissions={detailedPermissions} />

        <ApolicesInfoData />

        <S.ButtonContainer>
          <Button
            variant={!isSmallDevice ? "primary-01-md" : "full-primary-01"}
            type="button"
            onClick={handleSubmitShare}
            fullWidth={isSmallDevice}
            disabled={isSubmitDisabled}
            loading={hasSomeAprovadoresBeingFetched}
          >
            Continuar
          </Button>
          <S.Link
            onClick={() => openModal(AUTORIZACAO_MODALS.cancelarSolicitacao)}
          >
            Cancelar solicitação
          </S.Link>
        </S.ButtonContainer>
      </Card>
    </S.Container>
  );
};

export default Compartilhamento;
