import useScrollTop from "hooks/useScrollTop";
import { useLocation } from "react-router-dom";
import * as S from "./Main.style";

const Main = ({ children }) => {
  const { pathname } = useLocation();
  useScrollTop({ changeCase: pathname });

  return (
    <S.Main>
      <S.MainContent>
        <S.Content>{children}</S.Content>
      </S.MainContent>
    </S.Main>
  );
};

export default Main;
