import { useAuth } from "oidc-react";
import { RESOURCES_READ } from "utils/constants";
import useApi from "./useApi";
import { useConsentimentoContext } from "./useConsentimentoContext";
import { getDateFormatWithoutMS, getDatePlusMonths } from "utils/helpers";

const getSolicitarConsentimentoPayload = (
  { selectedPermissions, prazo, selectedInstituicao },
  user
) => {
  const increasedDate = getDatePlusMonths(prazo);

  return {
    data: {
      loggedUser: {
        document: {
          identification: user?.profile?.cpf,
          rel: "CPF",
        },
      },
      permissions: [
        ...RESOURCES_READ,
        ...selectedPermissions.required.map(({ chave }) => chave),
        ...selectedPermissions.optional.map(({ chave }) => chave),
      ],
      expirationDateTime: getDateFormatWithoutMS(increasedDate),
    },
    brandId: selectedInstituicao?.AuthorisationServerId,
  };
};

const useSolicitarConsentimento = () => {
  const auth = useAuth();
  const consentimento = useConsentimentoContext();

  const { data, error, loading, fetchData, status } = useApi({
    method: "POST",
    url: "/open-insurance-previsul/v1/operacoes-consentimento/receptora/solicitacoes-consentimentos",
    autoRun: false,
  });

  const solicitarConsentimento = async () => {
    const payload = getSolicitarConsentimentoPayload(
      consentimento,
      auth.userData
    );
    await fetchData({ data: payload });
  };

  return {
    data,
    status,
    error,
    loading,
    solicitarConsentimento,
  };
};

export default useSolicitarConsentimento;
