import React, { useCallback, useEffect, useState } from "react";
import * as S from "./CompartilhamentoBase.style";
import { Checkbox, Divider, Text } from "@ds/react";
import { useModalsContext } from "hooks/useModalsContext";
import { CONSENTIMENTO_MODALS } from "context/modalsContext";
import Card from "components/Card/Card";

const CheckboxItem = ({
  checked,
  onCheck,
  label,
  description,
  openModalHandler,
  openModalText,
  hasOpenModalLink = true,
  isCheckboxDisabled = false,
}) => (
  <S.ItemContainer>
    <S.CheckboxContainer>
      <Checkbox
        className="checkbox"
        value={checked}
        onChange={onCheck}
        disabled={isCheckboxDisabled}
      />
      <Text variant="body-01-md">{label}</Text>
    </S.CheckboxContainer>

    <Text variant="caption-01">{description}</Text>

    {hasOpenModalLink && (
      <S.Link onClick={openModalHandler}>{openModalText}</S.Link>
    )}
  </S.ItemContainer>
);

const DataToShareCard = ({
  lead,
  title,
  description,
  permissions,
  isRequiredData,
  onPermissionsChange = () => null,
}) => {
  const { openModal } = useModalsContext();

  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [isCheckAllSelected, setIsCheckAllSelected] = useState(
    !!isRequiredData
  );

  const checkAllBoxesInfo = isCheckAllSelected
    ? "Desmarcar todas as opções"
    : "Marcar todas as opções";

  const handleCheckPermission = useCallback(
    (permissionId) => {
      const newSelectablePermissions = selectedPermissions.map((permission) => {
        if (permission.id === permissionId)
          return { ...permission, checked: !permission.checked };
        return permission;
      });
      setSelectedPermissions(newSelectablePermissions);
    },
    [selectedPermissions]
  );

  const handleSelectAllCheckbox = useCallback(() => {
    const permissionsWithCheckbox = selectedPermissions.map((permission) => ({
      ...permission,
      checked: !isCheckAllSelected,
    }));

    setIsCheckAllSelected((previous) => !previous);
    setSelectedPermissions(permissionsWithCheckbox);
  }, [isCheckAllSelected, selectedPermissions]);

  const handleOpenModal = ({ id, categoria, dados, checked }) => {
    const textBtn = checked ? "Desativar" : "Ativar";
    const buttonData = {
      label: `${textBtn} o compartilhamento desses dados`,
      action: () => handleCheckPermission(id),
    };

    openModal(CONSENTIMENTO_MODALS.dados, {
      title: categoria,
      dados,
      ...(!isRequiredData ? buttonData : {}),
    });
  };

  useEffect(() => {
    if (!selectedPermissions.length) {
      const permissionsWithCheckbox = permissions.map((permission) => ({
        ...permission,
        checked: isCheckAllSelected,
      }));
      if (permissions.length) setSelectedPermissions(permissionsWithCheckbox);
    }
  }, [permissions, isCheckAllSelected, selectedPermissions]);

  useEffect(() => {
    if (selectedPermissions.length) {
      if (!isRequiredData) onPermissionsChange(selectedPermissions);

      const allSelected = selectedPermissions.every(({ checked }) => checked);
      setIsCheckAllSelected(allSelected);
    }
  }, [selectedPermissions, onPermissionsChange, isRequiredData]);

  return (
    <Card lead={lead} title={title} hasDivider>
      <Text variant="body-01-sm">{description}</Text>

      {!isRequiredData && (
        <>
          <CheckboxItem
            checked={isCheckAllSelected}
            onCheck={handleSelectAllCheckbox}
            label={checkAllBoxesInfo}
            description="Marcando essa opção, você irá compartilhar todos os dados listados abaixo."
            hasOpenModalLink={false}
          />
          <Divider />
        </>
      )}

      {selectedPermissions.map(
        ({ id, categoria, descricao, dados, checked }) => (
          <CheckboxItem
            key={id}
            label={categoria}
            description={descricao}
            checked={checked}
            onCheck={() => handleCheckPermission(id)}
            isCheckboxDisabled={!!isRequiredData}
            openModalText={`Ver todos os ${categoria?.toLowerCase()}`}
            openModalHandler={() =>
              handleOpenModal({ id, categoria, dados, checked })
            }
          />
        )
      )}
    </Card>
  );
};

export default DataToShareCard;
