import { Button } from "@ds/react";
import OpinModal from "components/OpinModal";
import { GESTAO_MODALS, MODAL_CLOSING_TIME } from "context/modalsContext";
import { useModalsContext } from "hooks/useModalsContext";
import React, { useCallback, useEffect, useState } from "react";
import { HTTP_CODE } from "utils/constants";
import * as S from "./CancelarConsentimentoModal.style";
import BodyText from "components/BodyText/BodyText";
import useCancelarConsentimento from "hooks/useCancelarConsentimento";

const GestaoCancelarConsentimentoModal = () => {
  const { cancelarSolicitacao, error, loading, status, clearData } =
    useCancelarConsentimento({ revoke: true });
  const { closeModal, openModal, modalData, openedModalType } =
    useModalsContext();

  const [hasCanceled, setHasCanceled] = useState(false);

  const { compartilhamento, scope } = modalData || {};
  const titleText = hasCanceled
    ? "Compartilhamento de dados finalizado com sucesso!"
    : "Finalizar compartilhamento";

  const handleCloseModal = () => {
    clearData();
    setTimeout(() => setHasCanceled(false), MODAL_CLOSING_TIME);
    closeModal({ reloadPage: true });
  };

  useEffect(() => {
    if (status === HTTP_CODE.NO_CONTENT) setHasCanceled(true);
    if (error) openModal(GESTAO_MODALS.genericError);
  }, [status, error, openModal]);

  const renderContent = useCallback(() => {
    if (hasCanceled)
      return (
        <BodyText variant="body-02-md">
          Caso queira voltar a compartilhar os dados com a instituição&nbsp;
          <strong>{compartilhamento?.empresa}</strong>, você deve iniciar uma
          nova solicitação, combinado?
        </BodyText>
      );

    return (
      <>
        <BodyText variant="body-02-md">
          O processo de finalização do compartilhamento de dados é uma{" "}
          <strong>ação irreversível.</strong>
        </BodyText>
        <BodyText variant="body-02-md">
          Ao finalizar, você pode perder algumas vantagens que seriam oferecidas
          de acordo com o seu perfil.
        </BodyText>
        <BodyText variant="body-02-md">
          <strong>Tem certeza de que deseja finalizar?</strong>
        </BodyText>
        <Button
          variant="secondary-md"
          loading={loading}
          onClick={() => cancelarSolicitacao(compartilhamento?.id, scope)}
        >
          Sim, quero finalizar o compartilhamento
        </Button>
      </>
    );
  }, [
    cancelarSolicitacao,
    compartilhamento?.empresa,
    compartilhamento?.id,
    hasCanceled,
    loading,
    scope,
  ]);

  return (
    <OpinModal
      open={openedModalType === GESTAO_MODALS.finalizeSharing}
      title={titleText}
      lead="Compartilhamento de dados"
      onClose={handleCloseModal}
      closeActionProps={{ text: "Fechar" }}
    >
      <S.Container>{renderContent()}</S.Container>
    </OpinModal>
  );
};

export default GestaoCancelarConsentimentoModal;
