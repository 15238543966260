import { Text } from "@ds/react";
import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",

  [theme.breakpoint.lg()]: {
    gap: "24px",
  },
}));

export const Link = styled.button(({ theme }) => ({
  textDecorationLine: "underline",
  background: "transparent",
  border: "none",
  color: theme.color.brandPrimary.light,
  padding: 0,
  cursor: "pointer",
  fontSize: "16px",
  marginTop: "8px",
  display: "flex",
  alignItems: "flex-end",
  alignSelf: "center",

  "&.request-summary": {
    alignSelf: "flex-start",
  },

  svg: {
    marginLeft: "5px",
  },
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  alignItems: "stretch",
  display: "flex",
  flexDirection: "column",
  gap: "32px",

  [theme.breakpoint.lg()]: {
    alignItems: "center",
    flexDirection: "row",
    gap: "24px",
  },
}));

export const SpecialSection = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderLeftColor: theme.color.brandSecondary.light,
  borderLeftWidth: "2px",
  borderLeftStyle: "solid",
  paddingLeft: "16px",
  gap: "12px",

  [theme.breakpoint.lg()]: {
    gap: "24px",
  },
}));

export const SelectContainer = styled.div(({ theme }) => ({
  [theme.breakpoint.lg()]: {
    width: "30rem",
  },
}));

export const InstituicaoContainer = styled.div(() => ({
  justifyContent: "space-between",
  display: "flex",
  maxWidth: "400px",
  alignItems: "center",
}));

export const SharedDataSection = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
}));

export const SharedDataItemLabel = styled(Text)`
  font-weight: 700;
`;

export const CheckboxContainer = styled.div(() => ({
  display: "flex",
  alignItems: "center",
  margin: "10px 0",
  width: "fit-content",

  "> span": {
    minWidth: "max-content",
  },

  "> .checkbox": {
    border: "none",
    padding: "0 0",
    margin: 0,
  },
}));

export const DeadlineToRequestText = styled(Text).attrs({
  variant: "caption-02",
})(({ theme }) => ({
  color: theme.color.neutral["02"],
}));

export const RedirectToFinishRequestInfo = styled(Text).attrs({
  variant: "body-02-lg",
})(({ theme }) => ({
  color: theme.color.neutral["02"],

  strong: {
    display: "contents",
  },
}));

export const TermsAndConditionsLabel = styled(Text).attrs({
  variant: "body-01-lg",
})(({ theme }) => ({
  color: theme.color.neutral["02"],
}));

export const RequestSummaryItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  span::first-letter {
    text-transform: capitalize;
  }
`;
