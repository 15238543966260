import styled from "styled-components";

export const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  textAlign: "left",

  ul: {
    margin: 0,
  },
});
