import React from "react";
import * as S from "./CompartilhamentoMobile.style";
import { Button, Text } from "@ds/react";
import { formatarDataHora } from "utils/helpers";
import BodyText from "components/BodyText/BodyText";
import { STATUS_KEYS, STATUS_MAPPER } from "utils/constants";
import CogIcon from "assets/icons/configuration.svg";
import PlusIcon from "assets/icons/plus.svg";
import { useGestaoContext } from "hooks/useGestaoContext";

const CompartilhamentoCard = ({ compartilhamento }) => {
  const { setSelectedCompartilhamento } = useGestaoContext();

  const { id, status, dataConfirmacao, empresa } = compartilhamento;

  const handleSelectCompartilhamento = () =>
    setSelectedCompartilhamento(compartilhamento);

  return (
    <S.CompartilhamentoCardContainer key={id}>
      <S.CompartilhamentoCardContent>
        <Text variant="caption-01">{formatarDataHora(dataConfirmacao)}</Text>
        <BodyText>{empresa}</BodyText>
      </S.CompartilhamentoCardContent>

      <S.StatusTag
        label={STATUS_MAPPER[status].label}
        variant={STATUS_MAPPER[status].tag}
      />

      <S.ButtonsContainer>
        <Button onClick={handleSelectCompartilhamento}>
          <S.Icon src={PlusIcon} />
          <Text variant="button-01-md">Mais detalhes</Text>
        </Button>

        {status !== STATUS_KEYS.REJECTED && (
          <Button onClick={handleSelectCompartilhamento}>
            <S.Icon src={CogIcon} />
            <Text variant="button-01-md">Gerenciar</Text>
          </Button>
        )}
      </S.ButtonsContainer>
    </S.CompartilhamentoCardContainer>
  );
};

export default CompartilhamentoCard;
