import { Text } from "@ds/react";
import * as S from "./Home.style";

export const knowMoreData = [
  {
    title: "O que é o Open Insurance?",
    body: (
      <Text variant="body-02-md">
        O <strong>Open Insurance</strong>, ou Sistema de Seguros Aberto, é a
        possibilidade de consumidores de produtos e serviços de seguros,
        previdência complementar aberta e capitalização permitirem o
        compartilhamento de suas informações entre diferentes Seguradoras, de
        forma segura, ágil, precisa e conveniente.
        <br />
        <br /> Para entregar esses benefícios ao consumidor, o Open Insurance
        padroniza o compartilhamento de dados e serviços por meio de abertura e
        integração de sistemas, com privacidade e alto nível de segurança.
      </Text>
    ),
  },
  {
    title: "Como vai funcionar?",
    body: (
      <Text variant="body-02-md">
        O open Insurance será implementado de forma gradual.
        <br />
        <br />A <strong>primeira fase</strong> teve início em 15 de dezembro de
        2021 e foi concluída em 30 de junho de 2022 com um caráter mais
        burocrático contemplando o compartilhamento de dados públicos das
        empresas referentes a produtos, canais de atendimentos, rede
        referenciada e intermediários.
        <br />
        <br /> Na <strong>segunda fase</strong>, prevista para começar em 1º de
        setembro de 2022, os clientes poderão compartilhar seus dados pessoais e
        de movimentação de suas apólices a partir de seu consentimento. <br />
        <br />
        Já a <strong>terceira fase</strong>, que prevê a iniciação de serviços
        por meio do Open Insurance, terá início em 1º de dezembro de 2022.
      </Text>
    ),
  },
  {
    title:
      "Como eu sei que é seguro compartilhar meus dados pelo Open Insurance?",
    body: (
      <Text variant="body-02-md">
        O que torna o Open Insurance um sistema mais ágil e seguro para o
        mercado, são suas regras e rigorosos requisitos que garantem a
        autenticidade, segurança e sigilo das informações compartilhadas. <br />
        <br />
        Além disso, somente as instituições supervisionadas pela SUSEP estão
        autorizadas a participar. <br />
        <br />
        Saiba quais são as empresas participantes no{" "}
        <S.BodyLink href="https://opinbrasil.com.br/" target="_blank">
          Portal do Open Insurance
        </S.BodyLink>
        .
      </Text>
    ),
  },
  {
    title: "O Open Insurance está de acordo com a LGPD?",
    body: (
      <Text variant="body-02-md">
        Sim, o Open Insurance segue todas as orientações da Lei Geral de
        Proteção de Dados. <br /> <br /> Para saber mais como a CNP Brasil
        protege os seus dados, acesse a nossa{" "}
        <S.BodyLink
          href="https://portalprivacidade.cnpbrasil.com.br/s/"
          target="_blank"
        >
          Política de Privacidade
        </S.BodyLink>
        .
      </Text>
    ),
  },
  {
    title: "Estarei automaticamente inscrito no Open Insurance?",
    body: (
      <Text variant="body-02-md">
        <strong>
          Não, é preciso que você dê o seu consentimento primeiro.
        </strong>
        <br />
        <br />
        Sempre que for necessário o compartilhamento dos seus dados em alguma
        das instituições participantes, você será questionado se deseja ou não
        compartilhar.
        <br />
        <br />
        Após confirmar o desejo de compartilhar você será redirecionado para a
        instituição portadora dos dados para se autenticar e confirmar esta
        solicitação.
        <br />
        <br />
        Somente após esse processo é que seus dados serão compartilhados.
      </Text>
    ),
  },
  {
    title:
      "Como e por onde eu vou autorizar o compartilhamento dos meus dados no Open Insurance?",
    body: (
      <Text variant="body-02-md">
        Todo processo será realizado somente nos canais digitais das
        instituições, com toda segurança e comodidade para você.
        <br />
        <br />
        • Primeiro você precisa se identificar (com login e senha, por exemplo)
        em um ambiente digital (como um App ou Site) da instituição que você
        quer que tenha acesso aos seus dados.
        <br />
        <br />
        • Depois, você será redirecionado para a instituição que possui seus
        dados para confirmar e autorizar o compartilhamento.
        <br />
        <br />• Por fim, você retorna para o ambiente onde solicitou o
        compartilhamento e só assim seus dados serão informados para a outra
        instituição.
      </Text>
    ),
  },
  {
    title: "Quais informações serão compartilhadas?",
    body: (
      <Text variant="body-02-md">
        Dados pessoais e empresariais, como nome, telefone, CPF; e dados de
        movimentação de suas apólices, como valor de prêmios, endossos,
        coberturas, sinistros etc. É você quem escolhe o quer compartilhar e com
        quem, a partir de agrupamentos de dados!
      </Text>
    ),
  },
  {
    title: "É obrigatório o compartilhamento dos meus dados?",
    body: (
      <Text variant="body-02-md">
        Não! No Open Insurance, o compartilhamento dos seus dados só acontece se
        você quiser e permitir. Nenhuma instituição financeira terá acesso às
        suas informações sem autorização.
      </Text>
    ),
  },
  {
    title:
      "Recebi uma mensagem pedindo para confirmar o compartilhamento dos meus dados. O que devo fazer?",
    body: (
      <Text variant="body-02-md">
        As seguradoras continuam sendo responsáveis pela proteção e segurança de
        seus clientes. Por isso, se você tiver dúvidas ou receber qualquer
        solicitação de compartilhamento dos seus dados que seja suspeita, entre
        em contato com a instituição que mandou a mensagem.
      </Text>
    ),
  },
  {
    title: "O compartilhamento de dados terá período de vigência?",
    body: (
      <Text variant="body-02-md">
        Sim, você decide o prazo, que pode ser de até 12 meses. Após esse
        período, sua permissão expira e será necessário fazer um novo
        compartilhamento.
      </Text>
    ),
  },
  {
    title:
      "Como faço para cancelar um compartilhamento dos meus dados que já autorizei?",
    body: (
      <Text variant="body-02-md">
        O cancelamento pode ser solicitado tanto na instituição que em você
        permitiu o compartilhamento de seus dados, quanto na que disponibilizou
        suas informações, através dos canais digitais no chamado Ambiente Open
        Insurance, onde poderá fazer a gestão de todos os seus
        compartilhamentos.
      </Text>
    ),
  },
  {
    title: "Preciso pagar por isso?",
    body: (
      <Text variant="body-02-md">
        Não, todo o processo é gratuito aos clientes.
      </Text>
    ),
  },
];
