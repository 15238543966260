import { Button, Text } from "@ds/react";
import OpinModal from "../../../../components/OpinModal";
import React from "react";
import { useNavigate } from "react-router";
import { useModalsContext } from "hooks/useModalsContext";
import { CONSENTIMENTO_MODALS } from "context/modalsContext";

const RecepcaoRedirectErrorModal = () => {
  const navigate = useNavigate();
  const { closeModal, modalData, openedModalType } = useModalsContext();

  const { onganizationName } = modalData || {};

  const handleCloseModal = () => {
    closeModal();
    navigate("/opin/gestao");
  };

  return (
    <OpinModal
      open={openedModalType === CONSENTIMENTO_MODALS.redirectError}
      onClose={closeModal}
      title="Ops, algo deu errado :("
      description="Estamos com algum problema em nossos
    sistemas e não conseguimos fazer o
    redirecionamento."
    >
      <Text variant="body-01-md">O que fazer agora?</Text>
      <Text variant="body-02-md">
        Acesse os compartilhamentos, verifique em "Dados recebidos" se essa
        solicitacäo é apresentada com status "Pendente". Se sim, acesse o site
        ou app da instituição {onganizationName} pra confirmar o
        compartilhamento.
      </Text>
      <Text variant="body-02-md">
        Caso contrário, vocé deve iniciar uma nova solicitação de
        compartilhamento.
      </Text>
      <Button onClick={handleCloseModal}>Ir para meus compartilhamentos</Button>
      <Button onClick={() => window.location.reload()} variant="secondary-md">
        Iniciar uma nova solicitação
      </Button>
    </OpinModal>
  );
};

export default RecepcaoRedirectErrorModal;
