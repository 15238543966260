import { useEffect } from "react";
import { useAutorizacaoContext } from "./useAutorizacaoContext";
import { useModalsContext } from "./useModalsContext";
import usePermissionsByNames from "./usePermissionsByNames";
import { AUTORIZACAO_MODALS } from "context/modalsContext";

const useAutorizacaoPermissions = () => {
  const { openModal } = useModalsContext();
  const { data: permissions, error, ...restProps } = usePermissionsByNames();
  const { detailedPermissions, setDetailedPermissions } =
    useAutorizacaoContext();

  useEffect(() => {
    if (permissions) setDetailedPermissions(permissions);
    // TODO: create and use a specific error modal component below
    if (error) openModal(AUTORIZACAO_MODALS.genericError);
  }, [permissions, error, setDetailedPermissions, openModal]);

  return {
    detailedPermissions,
    ...restProps,
  };
};

export default useAutorizacaoPermissions;
