import React from "react";
import { AutorizacaoProvider } from "context/autorizacaoContext";
import Authorization from "./Autorizacao";

const AuthorizationWithProviders = () => (
  <AutorizacaoProvider>
    <Authorization />
  </AutorizacaoProvider>
);

export default AuthorizationWithProviders;
