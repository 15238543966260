import React, { useState, useEffect, useCallback } from "react";
import {
  DEFAULT_DATA,
  ERROR_DATA,
  SUCCESS_DATA,
  TIME_TO_REDIRECT_LIMIT,
} from "./constants";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { useModalsContext } from "hooks/useModalsContext";
import OpinModal from "components/OpinModal/OpinModal";
import { Button } from "@ds/react";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import useCancelarConsentimento from "hooks/useCancelarConsentimento";
import useIsSecondaryAprovador from "hooks/useIsSecondaryAprovador";

const CancelarConsentimentoModal = () => {
  const { interaction } = useAutorizacaoContext();
  const isSecondApprover = useIsSecondaryAprovador();
  const { closeModal, openedModalType } = useModalsContext();
  const { loading, error, data, redirect, cancelarSolicitacao } =
    useCancelarConsentimento({ isSecondApprover });

  const [content, setContent] = useState(DEFAULT_DATA);
  const [isCancelationOk, setIsCancelationOk] = useState(false);

  const onCancelSuccess = useCallback(() => {
    setContent(SUCCESS_DATA);
    setIsCancelationOk(true);
    setTimeout(redirect, TIME_TO_REDIRECT_LIMIT);
  }, [redirect]);

  const onCancelError = useCallback(() => setContent(ERROR_DATA), []);

  const handleCancelarSolicitacao = () =>
    cancelarSolicitacao(interaction?.data?.interactionId);

  const handleOnClose = useCallback(() => {
    setContent(DEFAULT_DATA);
    setIsCancelationOk(false);

    closeModal();
    if (isCancelationOk) redirect();
  }, [closeModal, redirect, isCancelationOk]);

  useEffect(() => {
    if (error) onCancelError();
    if (data) onCancelSuccess();
  }, [error, data, onCancelError, onCancelSuccess]);

  return (
    <OpinModal
      open={openedModalType === AUTORIZACAO_MODALS.cancelarSolicitacao}
      onClose={handleOnClose}
      lead="Compartilhamento de dados"
      title={content.title}
      description={content.description}
    >
      {content.showBtnAction && (
        <Button
          variant="secondary-md"
          onClick={handleCancelarSolicitacao}
          loading={loading}
        >
          Cancelar Solicitação
        </Button>
      )}
    </OpinModal>
  );
};

export default CancelarConsentimentoModal;
