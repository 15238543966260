import styled from "styled-components/macro";

import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo-previsul.svg";

export const Header = styled.header(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "16px 20px",
  backgroundColor: theme.color.brandPrimary.light,
  color: theme.color.neutral["08"],
  [theme.breakpoint.lg()]: {
    padding: "1rem 5rem",
  },
}));

export const Content = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "60%",
  height: "fit-content",
  placeSelf: "flex-end",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoint.lg()]: {
    width: "58%",
  },
}));

export const Menu = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "24px",
  alignItems: "center",
});

export const LogoutContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
});

export const LogoutIcon = styled(Logout)({
  width: "27px",
  transform: "translateY(-1px)",
  cursor: "pointer",
});

export const LogoPrevisul = styled(Logo)(({ theme }) => ({
  width: "67px",
  height: "32px",
  [theme.breakpoint.lg()]: {
    width: "117px",
    height: "auto",
  },
}));
