export const SEGMENTO_KEYS = {
  PATRIMONIAL: "PATRIMONIAL",
  RESPONSABILIDADE: "RESPONSABILIDADE",
  RISCOS_FINANCEIROS: "RISCOS_FINANCEIROS",
  AUTOMOVEIS: "AUTOMOVEIS",
  HABITACIONAL: "HABITACIONAL",
  PESSOAS: "PESSOAS",
};

export const PREVISUL_NAME_MOCK = "Previsul Seguros";

export const SCOPE = {
  TRANSMISSAO: "transmissora",
  RECEPCAO: "receptora",
};

export const REQUEST_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const MODAIS = {
  apolice: "apolice",
  dadosApolice: "dadosApolice",
  cancelarSolicitacao: "cancelarSolicitacao",
  origemDados: "origemDados",
  error: "error",
  differentCpfErrorModal: "differentCpfErrorModal",
};

export const HTTP_CODE = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export const ETAPAS = ["Compartilhamento", "Confirmação"];

export const SEGMENT_BY_TYPE = {
  AUTO: "Automóvel",
  PATRIMONIAL: "Patrimônio",
  "FINANCIAL-RISK": "Riscos Financeiros",
  RESPONSIBILITY: "Responsabilidade",
  HOUSING: "Seguro Habitacional",
};

export const DADOS_OPCIONAIS_DIC = {
  RESOURCES_READ: {
    title: "Dados de apólice",
    modal: "dadosApolice",
  },
  CUSTOMERS_PERSONAL_QUALIFICATION_READ: {
    title: "Dados qualitativos",
    modal: "dadosQualitativos",
  },
};

export const aprovadores = [
  {
    cpf: "***.456.789-**",
    nome: "Flávia do Nascimento",
    aprovado: true,
    solicitante: true,
  },
];

export const DADOS_CADASTRAIS_PF_PERMISSIONS = [
  "CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ",
];

export const QUALIFICACAO_PF_PERMISSIONS = [
  "CUSTOMERS_PERSONAL_QUALIFICATION_READ",
];

export const INFORMACOESCOMPLEMENTARES_PF_PERMISSIONS = [
  "CUSTOMERS_PERSONAL_ADDITIONALINFO_READ",
];

export const RESOURCES_READ = ["RESOURCES_READ"];

export const SEGMENTOS_MOCK = {
  TITULOS_DE_CAP_PRODUTOSDE_TITULOSDE_CAP:
    "Títulos de Cap. Produtos de Títulos de Cap",
  PREVIDENCIA_PRODUTOSDE_PREVIDÊNCIA_RISCO:
    "Previdência	Produtos de Previdência Risco",
  PATRIMONIAL: "Patrimonial",
  AERONAUTICOS: "Aeronáuticos",
  MARITIMO: "Marítimo",
  NUCLEAR: "Nuclear",
  PETROLEO: "Petróleo",
  RESPONSABILIDADE: "Responsabilidade",
  TRANSPORTES: "Transportes",
  RISCOS_FINANCEIROS: "Riscos Financeiros",
  RURAL: "Rural",
  AUTOMOVEIS: "Automóveis",
  HABITACIONAL: "Habitacional",
  ACEITACAO_E_SUCURSALNOEXTERIOR: "Aceitação e Sucursal no exterior",
  PESSOAS: "Pessoas",
};

export const GESTAO_ACTION = {
  RENEW: "RENEW",
  APPROVE: "APPROVE",
  CHANGE: "CHANGE",
  FINALIZE: "FINALIZE",
};

export const STATUS_KEYS = {
  AUTHORISED: "AUTHORISED",
  AWAITING_AUTHORISATION: "AWAITING_AUTHORISATION",
  REJECTED: "REJECTED",
};

export const STATUS_MAPPER = {
  [STATUS_KEYS.AUTHORISED]: {
    label: "Ativo",
    tag: "active",
    deadlineMsg: "Data da confirmação",
  },
  [STATUS_KEYS.AWAITING_AUTHORISATION]: {
    label: "Pendente",
    tag: "alert",
    deadlineMsg: "Solicitação expira em",
  },
  [STATUS_KEYS.REJECTED]: {
    label: "Expirado",
    tag: "inactive",
    deadlineMsg: "Solicitação expirada em",
  },
};

export const RADICAL_PERMISSION_BY_SEGMENTO_MAPPER = {
  [SEGMENTO_KEYS.PATRIMONIAL]: "DAMAGES_AND_PEOPLE_PATRIMONIAL",
  [SEGMENTO_KEYS.RESPONSABILIDADE]: "DAMAGES_AND_PEOPLE_RESPONSIBILITY",
  [SEGMENTO_KEYS.RISCOS_FINANCEIROS]: "DAMAGES_AND_PEOPLE_FINANCIAL_RISKS",
  [SEGMENTO_KEYS.AUTOMOVEIS]: "DAMAGES_AND_PEOPLE_AUTO",
  [SEGMENTO_KEYS.HABITACIONAL]: "DAMAGES_AND_PEOPLE_HOUSING",
  [SEGMENTO_KEYS.PESSOAS]: "DAMAGES_AND_PEOPLE_PERSON",
};

export const SEGMENTOS = {
  [SEGMENTO_KEYS.AUTOMOVEIS]: "Automóvel",
  [SEGMENTO_KEYS.PATRIMONIAL]: "Patrimônio",
  [SEGMENTO_KEYS.RISCOS_FINANCEIROS]: "Riscos Financeiros",
  [SEGMENTO_KEYS.RESPONSABILIDADE]: "Responsabilidade",
  [SEGMENTO_KEYS.HABITACIONAL]: "Seguro Habitacional",
  [SEGMENTO_KEYS.PESSOAS]: "Pessoas",
};

export const SEGMENTOS_DESCRICAO = {
  [SEGMENTO_KEYS.PATRIMONIAL]: "Patrimonial",
  [SEGMENTO_KEYS.RESPONSABILIDADE]: "Responsabilidade",
  [SEGMENTO_KEYS.RISCOS_FINANCEIROS]: "Riscos Financeiros",
  [SEGMENTO_KEYS.AUTOMOVEIS]: "Automóveis",
  [SEGMENTO_KEYS.HABITACIONAL]: "Habitacional",
  [SEGMENTO_KEYS.PESSOAS]: "Pessoas",
};
