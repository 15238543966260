import React, { useCallback } from "react";
import BodyText from "components/BodyText/BodyText";
import Card from "components/Card/Card";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import { SEGMENT_BY_TYPE } from "utils/constants";
import * as S from "./SharedDataCard.styles";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { useModalsContext } from "hooks/useModalsContext";

const SharedDataCard = () => {
  const { openModal } = useModalsContext();
  const {
    interaction,
    apolicesSelecionadas,
    detailedPermissions,
    hasApolicesPermissions,
  } = useAutorizacaoContext();

  const handleOpenModal = useCallback(
    ({ categoria, dados }) =>
      openModal(AUTORIZACAO_MODALS.dados, { title: categoria, dados }),
    [openModal]
  );

  return (
    <Card title="Dados compartilhados">
      {detailedPermissions?.length && (
        <S.SharedDataSection>
          {detailedPermissions.map((permission) => (
            <S.Link
              key={permission.id}
              onClick={() => handleOpenModal(permission)}
            >
              {permission.categoria}
            </S.Link>
          ))}
        </S.SharedDataSection>
      )}

      {hasApolicesPermissions && (
        <S.SharedDataSection>
          <S.BoldCaption variant="caption-02" color="text-light">
            Segmento/produto
          </S.BoldCaption>
          <BodyText>
            Apólice de {SEGMENT_BY_TYPE[interaction?.data?.segment]}
          </BodyText>
          {!!apolicesSelecionadas?.length && (
            <S.Link
              onClick={() =>
                openModal(AUTORIZACAO_MODALS.selectedApolices, {
                  apolices: apolicesSelecionadas,
                })
              }
            >
              {apolicesSelecionadas?.length} apólices selecionadas
            </S.Link>
          )}
        </S.SharedDataSection>
      )}
    </Card>
  );
};

export default SharedDataCard;
