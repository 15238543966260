/**
 * Formats a date and time string in the "DD/MM/YYYY HH:mm:ss" format.
 *
 * @param {string} data - The date and time string to format.
 * @returns {string|null} The formatted date and time string, or null if the input is null or undefined.
 */
export const formatarDataHora = (data) =>
  data
    ? new Intl.DateTimeFormat("pt-br", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format(new Date(data))
    : null;

/**
 * Calculates the number of months between two dates.
 *
 * @param {Date} startDate - The start date.
 * @param {Date} endDate - The end date.
 * @returns {number} The number of months between the start and end dates.
 */
export const getMonthDifference = (startDate, endDate) =>
  endDate.getMonth() -
  startDate.getMonth() +
  12 * (endDate.getFullYear() - startDate.getFullYear());

/**
 * Calculates the date that is a specified number of months after the current date.
 *
 * @param {number} monthsToAdd - The number of months to add to the current date.
 * @returns {string} The date that is a specified number of months after the current date in the "DD/MM/YYYY" format.
 */
export const getCurrentDatePlusMonths = (monthsToAdd) => {
  const currentDate = new Date();
  const resultedDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + parseInt(monthsToAdd, 10),
    currentDate.getDate()
  );

  const day = resultedDate.getDate().toString().padStart(2, "0");
  const month = (resultedDate.getMonth() + 1).toString().padStart(2, "0");
  const year = resultedDate.getFullYear().toString();
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
};

/**
 * Debounces a function, ensuring it is only called after a specified delay since the last invocation.
 *
 * @param {Function} fn - The function to debounce.
 * @param {number} ms - The delay in milliseconds.
 * @returns {Function} The debounced function.
 */
export const debounce = (fn, ms) => {
  let timeoutId;

  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn(...args), ms);
  };
};

/**
 * Formats a date string to a specific format without milliseconds.
 *
 * @param {Date} date - The date object to format.
 * @returns {string} The formatted date string without milliseconds.
 */
export const getDateFormatWithoutMS = (date) =>
  date?.toISOString().slice(0, -5) + "Z";

/**
 * Calculates the date that is a specified number of months after the current date.
 *
 * @param {number} months - The number of months to add to the current date.
 * @returns {Date} The date that is a specified number of months after the current date.
 */
export const getDatePlusMonths = (months) => {
  const date = new Date();

  date.setMonth(date.getMonth() + parseInt(months, 10));
  return date;
};

/**
 * Converts a phone number string to a clickable href link.
 *
 * @param {string} phone - The phone number string.
 * @returns {string} The href link for the phone number.
 */
export const phoneToHref = (phone) => `tel:${phone.replace(/ /g, "")}`;

/**
 * Formats a CPF (Brazilian individual taxpayer registry) string by adding separators.
 *
 * @param {string} cpf - The CPF string to format.
 * @returns {string|null} The formatted CPF string, or null if the input is null or undefined.
 */
export const formatCPF = (cpf) =>
  cpf
    ?.replace(/[^\d]/g, "")
    ?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");

/**
 * Masks a CPF (Brazilian individual taxpayer registry) string by replacing some digits with asterisks.
 *
 * @param {string} cpf - The CPF string to mask.
 * @returns {string|null} The masked CPF string, or null if the input is null or undefined.
 */
export const partialCPF = (cpf) =>
  cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "***.$2.$3-**");

/**
 * Formats a CNPJ (Brazilian company taxpayer registry) string by adding separators.
 *
 * @param {string} cnpj - The CNPJ string to format.
 * @returns {string|null} The formatted CNPJ string, or null if the input is null or undefined.
 */
export const formatCNPJ = (cnpj) =>
  cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");

/**
 * Checks if a session storage item exists with the given key.
 *
 * @param {string} key - The key to check in the session storage.
 * @returns {boolean} Returns true if the session storage item exists with the given key, otherwise returns false.
 */
export const hasSessionStorageItemByKey = (key) =>
  sessionStorage.getItem(key) !== "undefined";

/**
 * Slices an array into chunks of a specified size.
 *
 * @param {Array} arr - The array to slice.
 * @param {number} chunkSize - The size of each chunk.
 * @returns {Array|null} An array of chunks, or null if the input is not an array or empty.
 */
export const sliceIntoChunks = (arr, chunkSize) => {
  if (!Array.isArray(arr) || !arr.length) return null;

  const numChunks = Math.ceil(arr.length / chunkSize);
  return Array.from({ length: numChunks }, (_, i) => {
    const start = i * chunkSize;
    return arr.slice(start, start + chunkSize);
  });
};

/**
 * Transforms a word to its plural form.
 *
 * @param {string} word - The word to transform.
 * @returns {string} The plural form of the word.
 */
export const transformToPlural = (word) =>
  word.endsWith("s") ? word : `${word}s`;

/**
 * Formats a date string in the "DD/MM/YYYY" format.
 *
 * @param {string} data - The date string to format.
 * @returns {string} The formatted date string.
 */
export const formatDate = (data) =>
  new Intl.DateTimeFormat("pt-br", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(new Date(data));

/**
 * Truncate a string to a specified length and add ellipsis.
 *
 * @param {string} str - The string to truncate.
 * @param {number} length - The length to truncate the string.
 * @returns {string}  The truncated string.
 */
export const truncateStr = (str, length) =>
  str?.length > length ? `${str.substring(0, length)}...` : str;
