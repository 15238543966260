import Card from "components/Card/Card";
import { CONSENTIMENTO_MODALS } from "context/modalsContext";
import { useModalsContext } from "hooks/useModalsContext";
import React, { useCallback } from "react";
import * as S from "./DisabledDataToShareCard.style";
import BodyText from "components/BodyText/BodyText";

const DisabledDataToShareCard = ({ cardTitle, permissions, segmento }) => {
  const { openModal } = useModalsContext();

  const handleOpenModal = useCallback(
    ({ categoria, dados }) =>
      openModal(CONSENTIMENTO_MODALS.dados, { title: categoria, dados }),
    [openModal]
  );

  const renderPermissionItems = useCallback(
    (items) =>
      items.map((item) => (
        <S.Link
          key={item.id}
          className="request-summary"
          onClick={() => handleOpenModal(item)}
        >
          {item.categoria}
        </S.Link>
      )),
    [handleOpenModal]
  );

  return (
    <Card title={cardTitle}>
      {!!permissions?.required?.length && (
        <S.SharedDataSection>
          <S.SharedDataItemLabel variant="caption-01">
            Dados necessários
          </S.SharedDataItemLabel>
          {renderPermissionItems(permissions?.required)}
        </S.SharedDataSection>
      )}

      {!!permissions?.optional?.length && (
        <S.SharedDataSection>
          <S.SharedDataItemLabel variant="caption-01">
            Dados opcionais
          </S.SharedDataItemLabel>
          {renderPermissionItems(permissions?.optional)}
        </S.SharedDataSection>
      )}

      {!!segmento && (
        <S.SharedDataSection>
          <S.SharedDataItemLabel variant="caption-02">
            Segmento/produto
          </S.SharedDataItemLabel>
          <BodyText>Apólice de {segmento}</BodyText>
        </S.SharedDataSection>
      )}
    </Card>
  );
};

export default DisabledDataToShareCard;
