import { useCallback } from "react";
import useApi from "hooks/useApi";
import {
  getConsentimentoOperationUrl,
  getSecondApproverConsentimentoOperationUrl,
} from "services/endpoints";
import { REQUEST_METHODS, SCOPE } from "utils/constants";

const useCancelarConsentimento = ({
  revoke = false,
  isSecondApprover = false,
}) => {
  const { data, fetchData, ...restMethods } = useApi({
    method: REQUEST_METHODS.DELETE,
    autoRun: false,
  });

  const urlMounter = isSecondApprover
    ? getSecondApproverConsentimentoOperationUrl
    : getConsentimentoOperationUrl;

  const cancelarSolicitacao = (id, scope = SCOPE.TRANSMISSAO) =>
    fetchData({ url: urlMounter({ id, scope, isRevoked: revoke }) });

  const redirect = useCallback(
    () => window.open(data?.url, "_self"), // NOSONAR
    [data]
  );

  return {
    data,
    redirect,
    cancelarSolicitacao,
    ...restMethods,
  };
};

export default useCancelarConsentimento;
