import React from "react";
import * as S from "./ListaCompartilhamentos.style";
import { Text } from "@ds/react";
import { transformToPlural } from "utils/helpers";

const FiltersSection = ({ filters, selectedFilter, onSelectFilter }) => (
  <S.FiltersSection>
    <Text variant="caption-01">Filtrar por status</Text>

    <S.FiltersContainer>
      {filters.map((filtro) => (
        <S.FilterButton
          key={filtro.value}
          selected={filtro.value === selectedFilter.value}
          onClick={() => onSelectFilter(filtro)}
        >
          <S.SuccessIcon />
          <Text variant="body-02-md" color="inherit">
            {transformToPlural(filtro.label)}
          </Text>
        </S.FilterButton>
      ))}
    </S.FiltersContainer>
  </S.FiltersSection>
);

export default FiltersSection;
