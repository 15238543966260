import React, { useCallback, useEffect, useState } from "react";
import * as S from "./SearchInstituicaoModal.style";
import { Divider, Skeleton, Text } from "@ds/react";
import { useModalsContext } from "hooks/useModalsContext";
import OpinModal from "components/OpinModal";
import InstituicaoCard from "./components/InstituicaoCard/InstituicaoCard";
import SearchInput from "./components/SearchInput/SearchInput";
import { useConsentimentoContext } from "hooks/useConsentimentoContext";
import useInstituicoes from "hooks/useInstituicoes";
import { debounce } from "utils/helpers";
import { AUTORIZACAO_MODALS } from "context/modalsContext";

const DEBOUNCE_TIME = 500;

const SearchInstituicaoModal = () => {
  const { closeModal, openModal, openedModalType } = useModalsContext();
  const { setSelectedInstituicao } = useConsentimentoContext();
  const {
    getInstituicoes,
    loading,
    data: instituicoesList,
    error,
  } = useInstituicoes();
  const [searchInputValue, setSearchInputValue] = useState("");

  const isModalOpened = openedModalType === AUTORIZACAO_MODALS.instituicoes;

  const handleSelectInstituicao = useCallback(
    (instituicao) => {
      setSelectedInstituicao(instituicao);
      closeModal();
    },
    [closeModal, setSelectedInstituicao]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getDebouncedGetInstituicoes = useCallback(
    debounce(getInstituicoes, DEBOUNCE_TIME),
    [getInstituicoes]
  );

  useEffect(() => {
    if (isModalOpened) getDebouncedGetInstituicoes(searchInputValue);
  }, [getDebouncedGetInstituicoes, isModalOpened, searchInputValue]);

  useEffect(() => {
    if (error) openModal(AUTORIZACAO_MODALS.genericError);
  }, [error, openModal]);

  const renderResultContent = useCallback(() => {
    if (loading)
      return (
        <S.Container>
          <S.Instituicao>
            <S.InstituicaoContent>
              <Text variant="headline-06">
                <Skeleton lines={1} />
              </Text>
              <Text variant="caption-02">
                <Skeleton lines={1} />
              </Text>
            </S.InstituicaoContent>
            <Divider />
            <S.ButtonContent>
              <Skeleton lines={1} />
            </S.ButtonContent>
          </S.Instituicao>
        </S.Container>
      );

    if (instituicoesList?.length === 0)
      return (
        <S.Container>
          <Text variant="body-02-sm">
            Ops, não encontramos nenhuma instituição com esse nome. Por favor,
            verifique e tente novamente.
          </Text>
        </S.Container>
      );

    return instituicoesList?.map((instituicao) => (
      <InstituicaoCard
        key={instituicao.AuthorisationServerId}
        instituicao={instituicao}
        onSelect={() => handleSelectInstituicao(instituicao)}
      />
    ));
  }, [handleSelectInstituicao, instituicoesList, loading]);

  return (
    <OpinModal
      open={isModalOpened}
      onClose={closeModal}
      title="Qual instituição irá compartilhar seus dados com a gente?"
      hasDivider
    >
      <SearchInput
        value={searchInputValue}
        onChange={(value) => setSearchInputValue(value)}
      />

      {renderResultContent()}
    </OpinModal>
  );
};

export default SearchInstituicaoModal;
