import { Button, Text, useDeviceType } from "@ds/react";
import Card from "components/Card/Card";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { useConsentimentoContext } from "hooks/useConsentimentoContext";
import { useModalsContext } from "hooks/useModalsContext";
import usePermissoesBySegmento from "hooks/usePermissoesBySegmento";
import { SearchInstituicaoButton } from "pages/Consentimento/components/SearchInstituicaoButton";
import { useCallback, useEffect } from "react";
import * as S from "./CompartilhamentoBase.style";
import DataToShareCard from "./DataToShareCard";
import { PRAZOS_MOCK } from "pages/Consentimento/constants";
import DisabledDataToShareCard from "pages/Consentimento/components/DisabledDataToShareCard/DisabledDataToShareCard";
import CardLoading from "components/CardLoading/CardLoading";

const CompartilhamentoBase = ({
  shareDataCardContent,
  shareDataCardTitle,
  cancelActionText,
  onCancelAction,
  disableEditPermissions = false,
  disableEditInstitution = false,
}) => {
  const { lg: isLargeDevice } = useDeviceType();
  const { renderModal } = useModalsContext();
  const {
    selectedInstituicao,
    stepForward,
    selectedPermissions,
    setSelectedPermissions,
    prazo,
    setPrazo,
    segmentosHandler,
  } = useConsentimentoContext();
  const { optionalPermissions, requiredPermissions, loading } =
    usePermissoesBySegmento();

  const handlePermissionsChange = useCallback(
    (newOptionalPermissions) =>
      setSelectedPermissions((previous) => ({
        ...previous,
        optional: newOptionalPermissions.filter(({ checked }) => checked),
      })),
    [setSelectedPermissions]
  );

  useEffect(() => {
    if (!disableEditPermissions)
      setSelectedPermissions((previous) => ({
        ...previous,
        required: requiredPermissions,
      }));
  }, [disableEditPermissions, requiredPermissions, setSelectedPermissions]);

  const renderCompartilhamentoData = () => {
    if (loading) return <CardLoading />;

    if (disableEditPermissions)
      return (
        <DisabledDataToShareCard
          cardTitle="O que será compartilhado?"
          permissions={selectedPermissions}
          segmento={segmentosHandler?.filledSegmento?.nomeClassificacao}
        />
      );

    return (
      <>
        {!!requiredPermissions?.length && (
          <DataToShareCard
            title="Dados necessários"
            lead="O que será compartilhado"
            description="Estes são os dados mínimos exigidos para você fazer o compartilhamento. Vamos lá?"
            permissions={requiredPermissions || []}
            isRequiredData
          />
        )}

        {!!optionalPermissions?.length && (
          <DataToShareCard
            title="Dados opcionais"
            lead="O que pode ser compartilhado"
            description="Você também pode compartilhar estes dados opcionais. Assim, a gente
            entende melhor o seu perfil e oferece vantagens exclusivas pra você."
            permissions={optionalPermissions || []}
            onPermissionsChange={handlePermissionsChange}
          />
        )}
      </>
    );
  };

  return (
    <>
      <S.Container>
        <Card title={shareDataCardTitle} hasDivider>
          {shareDataCardContent}
          <SearchInstituicaoButton
            disabled={disableEditInstitution}
            instituicao={selectedInstituicao}
            description="Instituição que vai compartilhar os dados"
          />
        </Card>

        {renderCompartilhamentoData()}

        <Card lead="Período de compartilhamento">
          <Text variant="body-01-md">
            <strong>
              Por quanto tempo deseja compartilhar seus dados com a{" "}
              {selectedInstituicao?.CustomerFriendlyName}?
            </strong>
          </Text>

          <CustomSelect
            label="Selecione o prazo"
            placeholder="Selecione o prazo"
            options={PRAZOS_MOCK}
            value={prazo}
            onChange={({ target }) => setPrazo(target.value)}
          />

          <S.ButtonContainer>
            <Button
              variant="primary-01-md"
              onClick={stepForward}
              fullWidth={!isLargeDevice}
              disabled={!prazo}
            >
              Continuar
            </Button>
            <S.Link onClick={onCancelAction}>{cancelActionText}</S.Link>
          </S.ButtonContainer>
        </Card>

        {renderModal()}
      </S.Container>
    </>
  );
};

export default CompartilhamentoBase;
