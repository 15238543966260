import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAutorizacaoContext } from "./useAutorizacaoContext";
import { useModalsContext } from "./useModalsContext";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import useApi from "./useApi";
import { loginConsentimentoUrl } from "services/endpoints";
import { useAuth } from "oidc-react";
import useMutableRef from "./useMutableRef";
import { DIFFERENT_CPF_ERROR_TYPE } from "pages/Autorizacao/constants";
import { REQUEST_METHODS } from "utils/constants";

const useConsentimentoByInteractionId = () => {
  const auth = useAuth();
  const { state = {} } = useLocation();
  const { openModal, openedModalType } = useModalsContext();
  const { interaction, setInteraction } = useAutorizacaoContext();

  const urlInteractionId = state?.interactionId;
  const isDataAlreadyLoaded = interaction?.id === urlInteractionId;
  const payload = {
    accessToken: auth?.userData?.access_token,
    refreshToken: auth?.userData?.refresh_token,
  };

  const { loading, error, data, fetchData } = useApi({
    method: REQUEST_METHODS.POST,
    body: payload,
    url: loginConsentimentoUrl(urlInteractionId),
    autoRun: false,
  });

  const fetchConsentimento = useMutableRef(fetchData);

  useEffect(() => {
    if (!isDataAlreadyLoaded && fetchConsentimento.current)
      fetchConsentimento.current();
  }, [isDataAlreadyLoaded, fetchConsentimento]);

  useEffect(() => {
    if (data) setInteraction({ id: urlInteractionId, data });

    if (error) {
      const opinLoginErrorType = error?.response?.data?.errors?.[0]?.code;
      const modalTypeToOpen =
        opinLoginErrorType === DIFFERENT_CPF_ERROR_TYPE
          ? AUTORIZACAO_MODALS.erroCPFdiferente
          : AUTORIZACAO_MODALS.genericError;

      openModal(modalTypeToOpen);
    }
  }, [
    data,
    setInteraction,
    urlInteractionId,
    openModal,
    error,
    openedModalType,
  ]);

  return { loading, response: data, success: !loading && !!data };
};

export default useConsentimentoByInteractionId;
