import React, { useCallback } from "react";
import * as S from "./DataModal.style";
import { Button, Divider, Text } from "@ds/react";
import OpinModal from "components/OpinModal";
import { useModalsContext } from "hooks/useModalsContext";
import { COMMON_MODALS } from "context/modalsContext";

const DataModal = () => {
  const { closeModal, modalData, openedModalType } = useModalsContext();
  const { action, label, dados, title } = modalData || {};

  const handleButtonClick = useCallback(() => {
    action();
    closeModal();
  }, [action, closeModal]);

  const renderActionButton = useCallback(() => {
    if (!action || !label) return null;

    return (
      <Button onClick={handleButtonClick} variant="primary-01-md">
        {label}
      </Button>
    );
  }, [action, label, handleButtonClick]);

  return (
    <OpinModal
      open={openedModalType === COMMON_MODALS.dados}
      onClose={closeModal}
      lead="O que será compartilhado"
      title={title}
      description="Essa é a lista completa de todos os dados que serão compartilhados."
      hasDivider
    >
      <Divider />

      <S.List>
        {dados?.map((item) => (
          <S.ListItem key={item}>
            <Text variant="body-02-md">{item}</Text>
          </S.ListItem>
        ))}
      </S.List>

      <Divider />

      <Text variant="caption-02">
        Para mais detalhes, acesse o&nbsp;
        <S.Link href="https://opinbrasil.com.br/" target="_blank">
          Portal do Open Insurance
        </S.Link>
      </Text>

      {renderActionButton()}

      <Button onClick={closeModal} variant="secondary-md">
        Fechar
      </Button>
    </OpinModal>
  );
};

export default DataModal;
