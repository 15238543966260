import { useDeviceType } from "@ds/react";
import React, { useCallback } from "react";
import * as S from "./Gestao.style";
import ListaCompartilhamentos from "./components/ListaCompartilhamentos";
import MaisDetalhes from "./components/MaisDetalhes";
import { useGestaoContext } from "hooks/useGestaoContext";
import { useModalsContext } from "hooks/useModalsContext";
import NavigationHeader from "components/NavigationHeader/NavigationHeader";
import Card from "components/Card/Card";
import BodyText from "components/BodyText/BodyText";
import { useNavigate } from "react-router-dom";
import withTemplate from "hoc/withTemplate";

const Gestao = () => {
  const navigate = useNavigate();
  const { lg: isLargeDevice } = useDeviceType();
  const { renderModal } = useModalsContext();
  const { selectedCompartilhamento } = useGestaoContext();

  const renderContent = useCallback(() => {
    if (selectedCompartilhamento && !isLargeDevice) return <MaisDetalhes />;

    return (
      <>
        <NavigationHeader onBack={() => navigate("/opin")} />
        <S.Container>
          <Card
            title="Meus Compartilhamentos"
            lead="OPEN INSURANCE"
            direction="column"
          >
            <BodyText>
              Aqui você controla o compartilhamento dos seus dados com
              facilidade e segurança. Confere status, renova, altera e finaliza
              o processo quando quiser. Isso sim é transparência!
            </BodyText>
          </Card>

          <ListaCompartilhamentos />
        </S.Container>
      </>
    );
  }, [selectedCompartilhamento, isLargeDevice, navigate]);

  return (
    <>
      {renderContent()}
      {renderModal()}
    </>
  );
};

export default withTemplate(Gestao);
