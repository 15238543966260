import useGerenciarConsentimento from "hooks/useGerenciarConsentimento";
import useSolicitarConsentimento from "hooks/useSolicitarConsentimento";
import useMutableRef from "hooks/useMutableRef";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useModalsContext } from "hooks/useModalsContext";
import { useConsentimentoContext } from "hooks/useConsentimentoContext";
import { CONSENTIMENTO_MODALS } from "context/modalsContext";

const useHandleRedirect = () => {
  const { state } = useLocation();
  const { openModal } = useModalsContext();
  const { selectedInstituicao } = useConsentimentoContext();
  const requestConsentData = useSolicitarConsentimento();
  const manageConsentData = useGerenciarConsentimento();

  const { data, error, solicitarConsentimento, updateConsentimento } =
    state?.action ? manageConsentData : requestConsentData;

  const executeRef = useMutableRef(
    state?.action ? updateConsentimento : solicitarConsentimento
  );

  const onganizationName =
    selectedInstituicao?.CustomerFriendlyName ||
    state?.compartilhamento?.empresa;

  useEffect(() => {
    if (executeRef.current) executeRef.current();
  }, [executeRef]);

  useEffect(() => {
    if (error)
      openModal(CONSENTIMENTO_MODALS.redirectError, { onganizationName });
    if (data) window.open(data?.authorization_url, "_self");
  }, [data, error, executeRef, onganizationName, openModal]);

  return { onganizationName };
};

export default useHandleRedirect;
