import {
  GESTAO_ACTION,
  REQUEST_METHODS,
  RESOURCES_READ,
} from "utils/constants";
import useApi from "./useApi";
import { useConsentimentoContext } from "./useConsentimentoContext";
import { getDateFormatWithoutMS, getDatePlusMonths } from "utils/helpers";
import { useLocation } from "react-router-dom";

const methodByChangeType = {
  [GESTAO_ACTION.CHANGE]: REQUEST_METHODS.PUT,
  [GESTAO_ACTION.RENEW]: REQUEST_METHODS.PATCH,
};

const getGerirConsentimentoPayload = (
  { selectedPermissions, prazo },
  method
) => {
  const expirationDateTime = getDateFormatWithoutMS(getDatePlusMonths(prazo));
  const payload = { expirationDateTime };

  if (method === REQUEST_METHODS.PATCH) return payload;

  return {
    ...payload,
    permissions: [
      ...RESOURCES_READ,
      ...selectedPermissions.required.map(({ chave }) => chave),
      ...selectedPermissions.optional.map(({ chave }) => chave),
    ],
  };
};

const useGerenciarConsentimento = () => {
  const { state } = useLocation();
  const consentimento = useConsentimentoContext();
  const { error, loading, fetchData, status, data } = useApi({
    autoRun: false,
  });

  const { compartilhamento, action } = state || {};

  const updateConsentimento = async () => {
    const method = methodByChangeType[action];

    await fetchData({
      method,
      url: `/open-insurance-previsul/v1/operacoes-consentimento/receptora/consentimentos/${compartilhamento.id}`,
      data: getGerirConsentimentoPayload(consentimento, method),
    });
  };

  return {
    data,
    status,
    error,
    loading,
    updateConsentimento,
  };
};

export default useGerenciarConsentimento;
