import { Text } from "@ds/react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GESTAO_ACTION } from "utils/constants";
import CompartilhamentoBase from "pages/Consentimento/components/CompartilhamentoBase";

const Compartilhamento = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const renderEditCompartilhamento = useCallback(
    () => (
      <CompartilhamentoBase
        disableEditInstitution
        shareDataCardTitle="Alteração do compartilhamento de dados"
        shareDataCardContent={
          <Text variant="body-02-md">
            Você pode alterar um compartilhamento quando quiser. Seu
            compartilhamento atual será finalizado para o novo ser criado.
          </Text>
        }
        cancelActionText="Meus compartilhamentos"
        onCancelAction={() => navigate("/opin/gestao")}
      />
    ),
    [navigate]
  );

  const renderNewCompartilhamento = useCallback(
    () => (
      <CompartilhamentoBase
        shareDataCardTitle="Compartilhamento de Dados"
        shareDataCardContent={
          <Text variant="body-02-md">
            Para a <strong>contratação do seguro</strong>, vamos precisar dos
            seus <strong>dados cadastrais</strong>. Caso queira um pacote de
            serviço personalizado, compartilhe também os&nbsp;
            <strong>dados da sua apólice</strong>. Seus dados serão utilizados
            somente para esta finalidade. Na CNP, você pode confiar!
          </Text>
        }
        cancelActionText="Cancelar solicitação"
        onCancelAction={() => navigate("/opin")}
      />
    ),
    [navigate]
  );

  return state?.action === GESTAO_ACTION.CHANGE
    ? renderEditCompartilhamento()
    : renderNewCompartilhamento();
};

export default Compartilhamento;
