import { Button, Text } from "@ds/react";
import OpinModal from "components/OpinModal";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import { useModalsContext } from "hooks/useModalsContext";

const AutorizacaoRedirectErrorModal = () => {
  const { closeModal, modalData, openedModalType } = useModalsContext();
  const { loading, onTryAgain, organizationName } = modalData || {};

  return (
    <OpinModal
      open={openedModalType === AUTORIZACAO_MODALS.redirectError}
      onClose={closeModal}
      title="Ops, algo deu errado :("
    >
      <Text variant="body-02-md">
        Ocorreu algum problema na comunicação entre as duas instituições e não
        foi possível fazer o redirecionamento
      </Text>
      <Text variant="body-02-md">
        <strong>O que fazer agora?</strong>
      </Text>
      <Text variant="body-02-md">
        Acesse os compartilhamentos, verifique em &quot;Dados enviados&quot; se
        essa solicitação é apresentada com status &quot;Ativo&quot;. Se sim,
        seus dados estão sendo compartilhados com a instituição{" "}
        {organizationName}
      </Text>
      <Text variant="body-02-md">
        Caso contrário, você deve iniciar uma nova solicitação de
        compartilhamento
      </Text>
      <Button variant="primary-01-md" onClick={onTryAgain} loading={loading}>
        Tentar novamente
      </Button>
    </OpinModal>
  );
};

export default AutorizacaoRedirectErrorModal;
