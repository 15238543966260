import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useApi from "./useApi";
import { REQUEST_METHODS } from "utils/constants";

const useSecondaryAprovadorConfirmation = (consentInteractionId) => {
  const navigate = useNavigate();

  const { response, loading, error, execute } = useApi({
    method: REQUEST_METHODS.POST,
    url: `fake-url/${consentInteractionId}`,
    autoRun: false,
  });

  const redirect = useCallback(() => navigate("/opin"), [navigate]);

  return {
    loading,
    confirmSolicitation: execute,
    error,
    response,
    redirect,
  };
};

export default useSecondaryAprovadorConfirmation;
