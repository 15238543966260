import React, { useCallback } from "react";
import * as S from "../CompartilhamentoDesktop.style";
import BodyText from "components/BodyText/BodyText";
import {
  formatDate,
  formatarDataHora,
  getMonthDifference,
} from "utils/helpers";
import { STATUS_MAPPER } from "utils/constants";

const AccordionItemTitle = ({ compartilhamento }) => {
  const { status, empresa, dataSolicitacao, dataExpiracao, dataConfirmacao } =
    compartilhamento;

  const renderDeadlineMessage = useCallback(() => {
    if (status === "REJECTED")
      return `Expirado em ${formatDate(dataConfirmacao)}`;

    return `${getMonthDifference(
      new Date(dataSolicitacao),
      new Date(dataExpiracao)
    )} meses, até ${formatDate(dataExpiracao)}`;
  }, [dataConfirmacao, dataExpiracao, dataSolicitacao, status]);

  return (
    <S.Title key={status}>
      <BodyText>{empresa}</BodyText>
      <S.StatusTag
        label={STATUS_MAPPER[status]?.label}
        status={STATUS_MAPPER[status]?.tag}
      />
      <BodyText>{formatarDataHora(dataSolicitacao)}</BodyText>
      <BodyText>{renderDeadlineMessage()}</BodyText>
    </S.Title>
  );
};

export default AccordionItemTitle;
