export const FOOTER_LINKS = [
  {
    title: "Meu Seguro",
    items: [
      {
        name: "2a via de Documentos",
        href: "https://portal-corretor-hm.previsul.com.br/Validate",
      },
      {
        name: "Beneficiários",
        href: "https://previsulseguradora.force.com/portalsegurado/s/beneficiarios",
      },
      {
        name: "Clube +Vantagens",
        href: "https://previsulseguradora.force.com/portalsegurado/s/clube-vantagens",
      },
    ],
  },
  {
    title: "Informações Financeiras",
    items: [
      {
        name: "Status de Pagamento",
        href: "https://previsulseguradora.force.com/portalsegurado/s/informacoes-financeiras",
      },
      {
        name: "Forma de Pagamento",
        href: "https://previsulseguradora.force.com/portalsegurado/s/forma-de-pagamento",
      },
      {
        name: "Quitação de Débitos",
        href: "https://previsulseguradora.force.com/portalsegurado/s/quitacao-de-debito",
      },
    ],
  },
  {
    title: "Sinistros",
    items: [
      {
        name: "Acompanhamento",
        href: "https://previsulseguradora.force.com/portalsegurado/s/sinistro",
      },
      {
        name: "Abertura",
        href: "https://previsulseguradora.force.com/portalsegurado/s/sinistro",
      },
    ],
  },
  {
    title: "Meus Dados",
    items: [
      {
        name: "Atualização Cadastral",
        href: "https://previsulseguradora.force.com/portalsegurado/s/atualizacao-cadastral",
      },
      { name: "Open Insurance", href: "" },
    ],
  },
  {
    title: "Ajuda",
    items: [
      {
        name: "Dúvidas Frequentes",
        href: "https://previsulseguradora.force.com/portalsegurado/s/perguntas-frequentes",
      },
      {
        name: "Status de Atendimento",
        href: "https://previsulseguradora.force.com/portalsegurado/s/central-de-solicitacao",
      },
    ],
  },
];

export const DESCRIPTION =
  "Tire dúvidas, encontre informações, faça solicitações de documentos ou cancelamentos: de segunda-feira a sexta-feira, das 8h às 18h. Exceto feriados nacionais.";
