import { Button } from "@ds/react";
import styled from "styled-components/macro";

export const NavButton = styled(Button)(({ theme }) => ({
  "&&": {
    background: "#fff",
    width: "100%",
    borderRadius: 0,
    textAlign: "left",
    padding: "24px 32px",
    margin: 0,

    svg: {
      marginRight: theme.spacing.inset["xs"],
      fill: theme.color.brandPrimary.light,
    },
  },
}));
