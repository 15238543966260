import { Button, Text } from "@ds/react";
import useActionsData from "pages/Gestao/hooks/useActionsData";
import { STATUS_KEYS } from "utils/constants";
import * as S from "../CompartilhamentoDesktop.style";

const ManageCompartilhamentoItemFooter = ({ compartilhamento }) => {
  const { actionsData, enabledActions } = useActionsData(compartilhamento);

  if (compartilhamento.status === STATUS_KEYS.REJECTED) return null;

  return (
    <>
      <Text variant="caption-01" color="text-light">
        Gerenciar compartilhamento
      </Text>

      <S.ActionsGrid>
        {actionsData.map(
          ({ type, title, description, label, icon, handler }) => (
            <S.ActionSection key={type}>
              <Button
                variant="primary-02-inverse-md"
                onClick={handler}
                disabled={!enabledActions.includes(type)}
              >
                {icon}
                <Text variant="button-01-md">{label}</Text>
              </Button>
              <Text variant="body-02-md">
                <strong>{title}</strong>: {description}
              </Text>
            </S.ActionSection>
          )
        )}
      </S.ActionsGrid>
    </>
  );
};

export default ManageCompartilhamentoItemFooter;
