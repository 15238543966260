import { Text } from "@ds/react";
import * as S from "../MaisDetalhes.style";
import { formatCPF, formatarDataHora } from "utils/helpers";
import { useGestaoContext } from "hooks/useGestaoContext";
import { STATUS_KEYS, STATUS_MAPPER } from "utils/constants";
import { useMemo } from "react";
import { useAuth } from "oidc-react";

const ResumoSolicitacaoSection = () => {
  const { userData } = useAuth();
  const { selectedCompartilhamento } = useGestaoContext();

  const renderDeadlineMessage = useMemo(() => {
    const requestDate = new Date(selectedCompartilhamento.dataSolicitacao);
    const requestDeadline = requestDate.setHours(requestDate.getHours() + 1);
    const deadline =
      selectedCompartilhamento.status === STATUS_KEYS.AWAITING_AUTHORISATION
        ? requestDeadline
        : selectedCompartilhamento.dataConfirmacao;

    return formatarDataHora(deadline);
  }, [
    selectedCompartilhamento.dataConfirmacao,
    selectedCompartilhamento.dataSolicitacao,
    selectedCompartilhamento.status,
  ]);

  return (
    <S.CompartilhamentoInfo>
      <Text variant="caption-01">Resumo do compartilhamento</Text>

      <S.SectionItem>
        <Text variant="caption-01">CPF</Text>
        <Text variant="body-02-md">
          {formatCPF(selectedCompartilhamento.cpf)}
        </Text>
      </S.SectionItem>

      <S.SectionItem>
        <Text variant="caption-01">Nome</Text>
        <Text variant="body-02-md">{userData.profile.nome}</Text>
      </S.SectionItem>

      <S.SectionItem>
        <Text variant="caption-01">Instituição que compartilha os dados</Text>
        <Text variant="body-02-md">{selectedCompartilhamento.empresa}</Text>
      </S.SectionItem>

      <S.SectionItem>
        <Text variant="caption-01">Segmento</Text>
        <Text variant="body-02-md">
          {selectedCompartilhamento.segmento || "--"}
        </Text>
      </S.SectionItem>

      <S.SectionItem>
        <Text variant="caption-01">
          {STATUS_MAPPER[selectedCompartilhamento.status].deadlineMsg}
        </Text>
        <Text variant="body-02-md">{renderDeadlineMessage}</Text>
      </S.SectionItem>
    </S.CompartilhamentoInfo>
  );
};

export default ResumoSolicitacaoSection;
