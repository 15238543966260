import React from "react";
import * as S from "./ApolicesModal.style";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { Skeleton, Text } from "@ds/react";
import { formatDate } from "utils/helpers";
import BodyText from "components/BodyText/BodyText";

const ApoliceCheckbox = ({ apolice, onSelect, isSelected, isDisabled }) => {
  const { numeroContrato, descricao, dataVigencia } = apolice || {};

  return (
    <S.ItemCheckBoxContainer>
      <S.CustomCheckbox
        value={isSelected}
        onChange={onSelect}
        disabled={isDisabled}
      />

      <S.CheckBoxContent>
        <Text variant="body-02-sm">Apólice {numeroContrato}</Text>
        <BodyText variant="body-02-md">{descricao || "-"}</BodyText>
        <S.VigenciaText variant="caption-02">
          Vigência: {formatDate(dataVigencia)}
        </S.VigenciaText>
      </S.CheckBoxContent>
    </S.ItemCheckBoxContainer>
  );
};

const ApolicesList = ({ loading, disabled }) => {
  const { apolices, apolicesSelecionadas, setApolicesSelecionadas } =
    useAutorizacaoContext();

  const toggleApolice = (apolice) => {
    const apoliceSelecionadaIndex = apolicesSelecionadas?.findIndex(
      ({ numeroContrato }) => numeroContrato === apolice?.numeroContrato
    );
    if (apoliceSelecionadaIndex !== -1) {
      const newApolicesSelecionadas = [...apolicesSelecionadas];
      newApolicesSelecionadas.splice(apoliceSelecionadaIndex, 1);
      setApolicesSelecionadas(newApolicesSelecionadas);
    } else {
      setApolicesSelecionadas([...apolicesSelecionadas, apolice]);
    }
  };

  if (loading) return <Skeleton lines={4} />;

  return (
    <S.ApolicesListContainer>
      {apolices?.conteudo?.map((apolice) => {
        const isApoliceSelected = apolicesSelecionadas?.some(
          ({ numeroContrato }) => numeroContrato === apolice?.numeroContrato
        );

        return (
          <ApoliceCheckbox
            key={apolice.numeroContrato}
            apolice={apolice}
            isSelected={isApoliceSelected}
            onSelect={() => toggleApolice(apolice)}
            isDisabled={disabled}
          />
        );
      })}
    </S.ApolicesListContainer>
  );
};

export default ApolicesList;
