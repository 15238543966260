import { Button, useDeviceType } from "@ds/react";
import BodyText from "components/BodyText/BodyText";
import Card from "components/Card/Card";
import * as S from "./ActionsCard.styles";
import React from "react";
import { useModalsContext } from "hooks/useModalsContext";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { AUTORIZACAO_MODALS } from "context/modalsContext";

const ActionsCard = () => {
  const { lg: isLargeDevice } = useDeviceType();
  const { openModal } = useModalsContext();
  const { interaction, setStatus } = useAutorizacaoContext();

  const handleConfirmAutorization = () => setStatus("redirect");

  return (
    <Card title="Confirmação" hasDivider>
      <div>
        <BodyText>
          Para concluir o compartilhamento, iremos te redirecionar
          <strong> de forma segura,</strong> para o site ou app da instituição:
        </BodyText>
      </div>

      <div>
        <BodyText>
          <strong>{interaction?.data?.consent?.organizationName}</strong>
        </BodyText>
      </div>

      <S.ButtonContainer>
        <Button
          variant="primary-01-md"
          type="button"
          onClick={handleConfirmAutorization}
          fullWidth={!isLargeDevice}
        >
          Confirmar
        </Button>
        <S.Link
          center
          onClick={() => openModal(AUTORIZACAO_MODALS.cancelarSolicitacao)}
        >
          Cancelar solicitação
        </S.Link>
      </S.ButtonContainer>
    </Card>
  );
};

export default ActionsCard;
