import * as S from "./CustomSelect.style";

// TODO: remove the below component when the DS's Select component is ready
const CustomSelect = ({ label, options = [], placeholder, ...props }) => (
  <S.Container>
    <S.Label>{label}</S.Label>

    <S.CustomSelect {...props}>
      {placeholder && <option value="">{placeholder}</option>}

      {options.map(({ id, value }) => (
        <option key={id} value={value}>
          {value}
        </option>
      ))}
    </S.CustomSelect>
  </S.Container>
);

export default CustomSelect;
