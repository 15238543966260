import { Button } from "@ds/react";
import styled from "styled-components";

export const SearchInstituicaoButton = styled(Button)(({ theme }) => ({
  width: "100%",

  svg: {
    marginRight: "10px",
  },

  [theme.breakpoint.lg()]: {
    width: "fit-content",
  },
}));

// TODO: remover cursor: pointer quando desabilitado
export const SearchInstituicaoComponentContainer = styled.button(
  ({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth: "400px",
    marginTop: 10,
    background: "none",
    border: "none",
    color: theme.color.brandPrimary.light,

    svg: {
      cursor: "pointer",
      marginRight: "10px",
    },

    "&:hover": {
      cursor: "pointer",
    },
  })
);
