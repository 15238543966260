import { useCallback, useMemo, useState } from "react";

const INITIAL_CALLS_NUMBER = 0;

const useIsFetchingAprovadores = () => {
  const [isFetchingAprovadoresCount, setIsFetchingAprovadoresCount] =
    useState(INITIAL_CALLS_NUMBER);

  const hasSomeAprovadoresBeingFetched = useMemo(
    () => isFetchingAprovadoresCount > INITIAL_CALLS_NUMBER,
    [isFetchingAprovadoresCount]
  );

  const addNewCallToGetAprovadores = useCallback(
    () => setIsFetchingAprovadoresCount((previousCount) => previousCount + 1),
    [setIsFetchingAprovadoresCount]
  );

  const removeCallToGetAprovadores = useCallback(
    () => setIsFetchingAprovadoresCount((previousCount) => previousCount - 1),
    [setIsFetchingAprovadoresCount]
  );

  return {
    hasSomeAprovadoresBeingFetched,
    addNewCallToGetAprovadores,
    removeCallToGetAprovadores,
  };
};

export default useIsFetchingAprovadores;
