import React, { useCallback, useEffect, useState } from "react";
import * as S from "./ApolicesModal.style";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import useIsSecondaryAprovador from "hooks/useIsSecondaryAprovador";
import { Text } from "@ds/react";
import BodyText from "components/BodyText/BodyText";

const ApolicesHeaderContent = ({ isPaginationEnabled }) => {
  const { apolices, apolicesSelecionadas, setApolicesSelecionadas } =
    useAutorizacaoContext();
  const isSecondaryAprovador = useIsSecondaryAprovador();

  const [selectAll, setSelectAll] = useState(false);

  const selectedApolicesAmount = isSecondaryAprovador
    ? apolices?.total
    : apolicesSelecionadas?.length;

  const toggleAll = useCallback(
    (checked) => {
      setSelectAll(!!checked);
      setApolicesSelecionadas(checked ? apolices?.conteudo : []);
    },
    [apolices?.conteudo, setApolicesSelecionadas]
  );

  useEffect(() => {
    const isAllApolicesSelected =
      apolices?.conteudo?.length === apolicesSelecionadas?.length;

    setSelectAll(isAllApolicesSelected);
  }, [apolices, apolicesSelecionadas]);

  useEffect(() => {
    if (isSecondaryAprovador) toggleAll(true);
  }, [isSecondaryAprovador, toggleAll]);

  if (isPaginationEnabled)
    return (
      <Text variant="body-02-md" color="primary-01-md">
        {selectedApolicesAmount} apólices selecionadas
      </Text>
    );

  return (
    <S.HeaderCheckboxContainer>
      <S.CustomCheckbox
        onChange={(value) => toggleAll(value)}
        value={selectAll}
        disabled={!!isSecondaryAprovador}
      />
      <BodyText>Selecionar todas</BodyText>
    </S.HeaderCheckboxContainer>
  );
};

export default ApolicesHeaderContent;
