import { Text } from "@ds/react";
import styled from "styled-components";

export const InstituicaoContainer = styled.div(({ theme }) => ({
  borderRadius: "12px",
  padding: 20,
  border: "1px solid #D1DBE3",
  boxShadow: "0px 4px 5px rgba(45, 41, 38, 0.08)",
  gap: 16,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  color: theme.color.neutral["02"],
}));

export const InstituicaoContent = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  textAlign: "left",
}));

export const InstituicaoInfo = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",

  div: {
    display: "flex",
    alignItems: "start",
    flexDirection: "column",
  },

  svg: {
    cursor: "pointer",
  },
}));

export const ParticipantesQuantityContainer = styled.div(() => ({
  background: "#F7F9FA",
  borderRadius: "4px",
  padding: "8px 15px",
  textAlign: "left",
}));

export const InstituicoesList = styled.ul(() => ({
  listStyleType: "initial",
  paddingLeft: 20,
}));

export const InstituicaoItem = styled.li(() => ({}));

export const InstituicaoCardHeaderText = styled(Text)(({ theme }) => ({
  "&.title": {
    marginBottom: 4,
  },
  "&.subtitle": {
    color: theme.color.neutral["03"],
  },
}));

export const ChooseButton = styled.a(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.color.brandPrimary.light,
  cursor: "pointer",
}));

export const BodyLink = styled.a(({ theme }) => ({
  display: "contents",
  color: theme.color.brandSecondary.light,
}));
