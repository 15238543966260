import { useModalsContext } from "hooks/useModalsContext";
import OpinModal from "../OpinModal";
import { COMMON_MODALS } from "context/modalsContext";

const GenericErrorModal = () => {
  const { closeModal, openedModalType } = useModalsContext();

  return (
    <OpinModal
      open={openedModalType === COMMON_MODALS.genericError}
      title="Ops, algo deu errado :("
      description="Estamos com algum problema em nossos sistemas e não conseguimos concluir sua solicitação. Por favor, tente de novo mais tarde."
      onClose={closeModal}
      closeActionProps={{ text: "Fechar" }}
    />
  );
};

export default GenericErrorModal;
