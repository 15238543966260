import styled from "styled-components/macro";
import { Text } from "@ds/react";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  marginBottom: "20px",
  strong: {
    color: theme.color.neutral["02"],
  },
}));

export const Info = styled.div(({ center, theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: center ? "34px" : "16px",

  img: {
    height: "28px",

    [theme.breakpoint.lg()]: {
      height: "40px",
    },
  },

  h5: {
    fontWeight: "500",
  },
}));

export const Link = styled.button(({ theme }) => ({
  textDecorationLine: "underline",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  color: theme.color.brandPrimary.dark,
  padding: 0,
  fontSize: "16px",
}));

export const DataSection = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "12px",
});

export const SharedDataSection = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "12px",

  [theme.breakpoint.lg()]: {
    gap: "20px",
  },
}));

export const BoldCaption = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["03"],
}));

export const ButtonContainer = styled.div({
  display: "flex",
});
