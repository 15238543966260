import styled from "styled-components/macro";

export const CardContentContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  color: theme.color.brandPrimary.dark,
  gap: "32px",
  svg: {
    fill: theme.color.brandPrimary.dark,
    width: "64px",
  },
}));
