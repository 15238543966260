import styled from "styled-components/macro";

export const FirtAccessModalContent = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  paddingTop: 50,
  textAlign: "left",
}));

export const FirtAccessModalImage = styled.img(({ theme }) => ({
  width: "188px",

  [theme.breakpoint.lg()]: {
    width: "247px",
  },
}));

export const BodyLink = styled.a(({ theme }) => ({
  display: "contents",
  color: theme.color.brandSecondary.light,
}));
