import React from "react";
import { Text } from "@ds/react";
import Card from "../../../../../../components/Card";
import * as S from "./SemContratosCard.style";
import * as ParentStyle from "../../Compartilhamento.style";
import { useModalsContext } from "../../../../../../hooks/useModalsContext";
import { SEGMENT_BY_TYPE } from "../../../../../../utils/constants";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import BodyText from "components/BodyText/BodyText";

const SemContratosCard = () => {
  const { openModal } = useModalsContext();
  const { interaction } = useAutorizacaoContext();

  return (
    <ParentStyle.Container>
      <Card title="Compartilhamento de Dados" lead="O que será compartilhado">
        <Text variant="body-02-md">
          Ops! Não é possível continuar com essa solicitação.
        </Text>
        <BodyText variant="body-02-md">
          <strong>
            Identificamos que você não possui produtos do segmento de&nbsp;
            {SEGMENT_BY_TYPE[interaction?.data?.segment]?.toLowerCase()}
          </strong>
          &nbsp;para o compartilhamento de dados e, por esse motivo, essa
          solicitação deve ser cancelada.
        </BodyText>
        <S.CancelarButton
          onClick={() => openModal(AUTORIZACAO_MODALS.cancelarSolicitacao)}
        >
          Cancelar Solicitação
        </S.CancelarButton>
      </Card>
    </ParentStyle.Container>
  );
};

export default SemContratosCard;
