import styled from "styled-components";

export const Link = styled.button(({ theme }) => ({
  display: "inline-flex",
  textDecorationLine: "underline",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  color: theme.color.brandPrimary.light,
  padding: 0,
  fontSize: "16px",
}));
