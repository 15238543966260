import React from "react";
import * as S from "./ApolicesSelecionadasModal.style";
import { useModalsContext } from "hooks/useModalsContext";
import { GESTAO_MODALS } from "context/modalsContext";
import OpinModal from "components/OpinModal/OpinModal";
import { Text } from "@ds/react";
import BodyText from "components/BodyText/BodyText";
import { formatDate } from "utils/helpers";

const ApoliceItem = ({ apolice }) => (
  <S.ApoliceContainer>
    <Text variant="body-02-sm">Apólice {apolice.numeroContrato}</Text>
    <BodyText variant="body-02-md">{apolice.descricao || "-"}</BodyText>
    <S.VigenciaText variant="caption-02">
      Vigência {formatDate(apolice.dataVigencia)}
    </S.VigenciaText>
  </S.ApoliceContainer>
);

const ApolicesSelecionadasModal = () => {
  const { closeModal, modalData, openedModalType } = useModalsContext();
  const { apolices } = modalData || {};

  return (
    <OpinModal
      open={openedModalType === GESTAO_MODALS.selectedApolices}
      title="Apólices selecionadas"
      onClose={closeModal}
      description="Confira as apólices da origem dos dados a serem compartilhados."
      hasDivider
      closeActionProps={{ text: "Fechar" }}
    >
      <S.Apolices>
        {apolices?.map((apolice) => (
          <ApoliceItem key={apolice.numeroContrato} apolice={apolice} />
        ))}
      </S.Apolices>
    </OpinModal>
  );
};

export default ApolicesSelecionadasModal;
