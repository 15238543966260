import useIsSecondaryAprovador from "./useIsSecondaryAprovador";
import useSecondaryAprovadorConfirmation from "./useSecondaryAprovadorConfirmation";
import useSolicitanteConfirmation from "./useSolicitanteConfirmation";

const useAuthConfirmation = (consentInteractionId) => {
  const isSecondaryAprovador = useIsSecondaryAprovador();

  const solicitanteConfirmationData =
    useSolicitanteConfirmation(consentInteractionId);
  const secondaryAprovadorConfirmationData =
    useSecondaryAprovadorConfirmation(consentInteractionId);

  return isSecondaryAprovador
    ? secondaryAprovadorConfirmationData
    : solicitanteConfirmationData;
};

export default useAuthConfirmation;
