import styled from "styled-components";

export const SearchInputContainer = styled.button(() => ({
  display: "flex",
  borderRadius: "12px",
  padding: "20px",
  border: "1px solid #D1DBE3",
  boxShadow: "0px 4px 5px rgba(45, 41, 38, 0.08)",
  background: "none",
  cursor: "pointer",
}));

export const SearchInput = styled.input(() => ({
  display: "block",
  border: "none",
  marginLeft: 10,
  flex: 1,

  ":focus": {
    outline: "none",
  },
}));
