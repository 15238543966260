import styled from "styled-components/macro";
import { Text } from "@ds/react";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  paddingBottom: "16px",

  "span, h6": { fontWeight: 400 },

  strong: {
    color: theme.color.neutral["02"],
  },

  [theme.breakpoint.lg()]: {
    gap: "24px",
    paddingBottom: "5px",
  },
}));

export const EnterprisesInfo = styled.div(({ theme }) => ({
  padding: "40px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  background: theme.color.neutral["08"],
  boxShadow: theme.shadow.level["01"],
  width: "100%",

  [theme.breakpoint.lg()]: {
    padding: "60px 32px",
  },
}));

export const Title = styled(Text)(({ theme }) => ({
  textTransform: "none",
  color: theme.color.neutral["02"],
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "32px",

  [theme.breakpoint.lg()]: {
    flexDirection: "row",
    gap: "24px",
  },
}));

export const Info = styled.div(({ center }) => ({
  display: "flex",
  alignItems: "center",
  gap: center ? "34px" : "16px",
}));

export const DataSection = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "12px",
}));

export const Link = styled.button(({ theme }) => ({
  textDecorationLine: "underline",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  color: theme.color.brandPrimary.light,
  padding: 0,
  fontSize: "16px",
}));

export const SharedDataSection = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "12px",
}));

export const Caption = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["03"],
}));
