import React, { useCallback, useEffect } from "react";
import * as ParentStyle from "../../Compartilhamento.style";
import * as S from "./ApolicesDataInfo.style";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { useModalsContext } from "hooks/useModalsContext";
import useMutableRef from "hooks/useMutableRef";
import { Text } from "@ds/react";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import BodyText from "components/BodyText/BodyText";
import { SEGMENT_BY_TYPE } from "utils/constants";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import useIsSecondaryAprovador from "hooks/useIsSecondaryAprovador";

const UNIQUE_APOLICE_NUMBER = 1;

const ApolicesDataInfo = () => {
  const {
    apolices,
    apolicesSelecionadas,
    setApolicesSelecionadas,
    getAprovadores,
    interaction,
    hasApolicesPermissions,
  } = useAutorizacaoContext();
  const isSecondaryAprovador = useIsSecondaryAprovador();
  const { openModal } = useModalsContext();

  const getAprovadoresRef = useMutableRef(getAprovadores);

  const hasOnlyOneApolice = apolices?.total === UNIQUE_APOLICE_NUMBER;
  const hasNoApolices = !apolices?.total;

  useEffect(() => {
    if (hasOnlyOneApolice) {
      setApolicesSelecionadas(apolices.conteudo);
      getAprovadoresRef.current(apolices?.conteudo[0]);
    }
  }, [
    hasOnlyOneApolice,
    apolices?.conteudo,
    setApolicesSelecionadas,
    getAprovadoresRef,
  ]);

  const renderAmountApolicesInfo = useCallback(() => {
    const noApolicesInfo = "Você não possui apólices";
    const numberApolicesYouHaveInfo = hasOnlyOneApolice
      ? `Você possui ${apolices?.total} apólice`
      : `Você possui ${apolices?.total} apólices`;

    return (
      <Text variant="body-02-md">
        {hasNoApolices ? noApolicesInfo : numberApolicesYouHaveInfo}
      </Text>
    );
  }, [apolices?.total, hasOnlyOneApolice, hasNoApolices]);

  const renderApolicesModalLink = useCallback(() => {
    if (hasNoApolices) return null;

    const selectedApolicesNumber = isSecondaryAprovador
      ? apolices?.total
      : apolicesSelecionadas?.length;
    const selectApolicesInfo = selectedApolicesNumber
      ? `Os dados de ${selectedApolicesNumber} apólices serão
      compartilhados`
      : "Selecionar apólices para compartilhar os dados";

    return (
      <ParentStyle.Link
        onClick={() => openModal(AUTORIZACAO_MODALS.apolices)}
        disabled={hasOnlyOneApolice}
      >
        {selectApolicesInfo}
        {!!apolicesSelecionadas?.length && <EditIcon />}
      </ParentStyle.Link>
    );
  }, [
    apolicesSelecionadas?.length,
    hasOnlyOneApolice,
    openModal,
    apolices?.total,
    isSecondaryAprovador,
    hasNoApolices,
  ]);

  if (!hasApolicesPermissions) return null;

  return (
    <S.SpecialSection>
      <S.DataOriginContainer>
        <Text variant="caption-02">Origem dos dados</Text>
        <BodyText>
          Apólice de {SEGMENT_BY_TYPE[interaction?.data?.segment] || ""}
        </BodyText>
      </S.DataOriginContainer>

      <div>
        {renderAmountApolicesInfo()}
        {renderApolicesModalLink()}
      </div>
    </S.SpecialSection>
  );
};

export default ApolicesDataInfo;
