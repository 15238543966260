import styled from "styled-components/macro";

export const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "20px",
  span: {
    display: "inline-block",
  },
}));
