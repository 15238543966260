import React, { useMemo } from "react";
import { useModalsContext } from "hooks/useModalsContext";
import * as S from "./AprovadoresList.style";
import { Tag, Text } from "@ds/react";
import BodyText from "components/BodyText/BodyText";
import { STATUS_MAPPER } from "utils/constants";
import Link from "components/Link/Link";
import { GESTAO_MODALS } from "context/modalsContext";

const AprovadorItem = ({
  identification,
  name,
  status,
  apolices,
  onOpenModal,
}) => (
  <S.AprovadorContainer>
    <S.AprovadorMainData>
      <S.DataItem>
        <Text variant="caption" color="text-light">
          CPF
        </Text>
        <BodyText>{identification}</BodyText>
      </S.DataItem>
      <S.DataItem>
        <Text variant="caption" color="text-light">
          Nome
        </Text>
        <BodyText>{name}</BodyText>
      </S.DataItem>
      <S.StatusTag>
        <Tag
          variant={STATUS_MAPPER[status]?.tag}
          value={STATUS_MAPPER[status]?.label}
        />
      </S.StatusTag>
    </S.AprovadorMainData>

    <Link onClick={() => onOpenModal(apolices)}>
      Aprovação necessária em {apolices?.length} apólices
    </Link>
  </S.AprovadorContainer>
);

const AprovadoresList = ({ aprovadores }) => {
  const { openModal } = useModalsContext();

  const solicitante = useMemo(
    () => aprovadores.find((aprovador) => aprovador.solicitante),
    [aprovadores]
  );

  const secondaryAprovadores = useMemo(
    () => aprovadores.filter((aprovador) => !aprovador.solicitante),
    [aprovadores]
  );

  const handleOpenApolicesModal = (apolices) => {
    openModal(GESTAO_MODALS.selectedApolices, { apolices });
  };

  return (
    <>
      <BodyText>
        Identificamos que será necessária uma ou mais autorizações para permitir
        o acesso aos dados.
      </BodyText>
      <div>
        <S.ListaAprovadores>
          <Text variant="body-medium2">A solicitação foi realizada por</Text>
          <AprovadorItem
            {...solicitante}
            onOpenModal={handleOpenApolicesModal}
          />
        </S.ListaAprovadores>

        <S.ListaAprovadores>
          <Text variant="body-medium2">Outros aprovadores</Text>
          {secondaryAprovadores.map((aprovador) => (
            <AprovadorItem
              key={aprovador.identification}
              {...aprovador}
              onOpenModal={handleOpenApolicesModal}
            />
          ))}
        </S.ListaAprovadores>
      </div>
    </>
  );
};

export default AprovadoresList;
