import { ThemeProvider } from "@ds/react";
import { previsul } from "@ds/tema-previsul";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Autorizacao from "pages/Autorizacao";
import Home from "pages/Home";
import OpinAuthProvider from "components/OpinAuthProvider";
import Efetivacao from "pages/Efetivacao";
import { ModalsProvider } from "context/modalsContext";
import Consentimento from "pages/Consentimento";
import Gestao from "pages/Gestao";
import Redirect from "pages/Redirect";
import "./App.css";

export default function App() {
  return (
    <ThemeProvider theme={previsul}>
      <ModalsProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<Navigate to="/opin" />} />
            <Route path="opin" element={<OpinAuthProvider />}>
              <Route index element={<Home />} />
              <Route path="autorizacao" element={<Autorizacao />} />
              <Route path="efetivacao" element={<Efetivacao />} />
              <Route path="consentimento" element={<Consentimento />} />
              <Route path="gestao" element={<Gestao />} />
              <Route path="redirect" element={<Redirect />} />
            </Route>
            <Route path="*" element={<Navigate to="/opin" />} />
          </Routes>
        </BrowserRouter>
      </ModalsProvider>
    </ThemeProvider>
  );
}
