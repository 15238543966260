import { Text } from "@ds/react";
import styled from "styled-components/macro";

export const SharedDataSection = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
}));

export const SharedDataItemLabel = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["03"],
}));

// TODO: Alterar para usar o Link padrão da Previsul
export const Link = styled.button(({ theme }) => ({
  textDecorationLine: "underline",
  background: "transparent",
  border: "none",
  color: theme.color.brandPrimary.light,
  padding: 0,
  cursor: "pointer",
  fontSize: "16px",
  marginTop: "8px",
  display: "flex",
  alignItems: "flex-end",
  alignSelf: "center",

  "&.request-summary": {
    alignSelf: "flex-start",
  },

  svg: {
    marginLeft: "5px",
  },
}));
