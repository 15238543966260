import React, { useCallback } from "react";
import * as S from "./OrigemDadosModal.style";
import { useModalsContext } from "hooks/useModalsContext";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import OpinModal from "components/OpinModal/OpinModal";

const Apolice = ({ apolice }) => (
  <S.Apolice>
    <S.ApoliceText variant="body-02-md">
      Apólice {apolice.numeroContrato}
    </S.ApoliceText>
    <S.ApoliceText>{apolice.descricao}</S.ApoliceText>
    <S.ApoliceText variant="caption-02" color="text-light">
      Vigência {new Date(apolice.dataVigencia).toLocaleDateString("pt-br")}
    </S.ApoliceText>
  </S.Apolice>
);

const OrigemDadosModal = () => {
  const { closeModal, openedModalType } = useModalsContext();
  const { apolicesSelecionadas } = useAutorizacaoContext();

  const renderApolices = useCallback(
    () =>
      apolicesSelecionadas?.map((apolice) => (
        <Apolice key={apolice.numeroContrato} apolice={apolice} />
      )),
    [apolicesSelecionadas]
  );

  return (
    <OpinModal
      open={openedModalType === AUTORIZACAO_MODALS.origemDados}
      title="Origem dos dados"
      onClose={closeModal}
      description="Essa instituição irá compartilhar os dados das seguintes apólices:"
      hasDivider
    >
      <S.Apolices>{renderApolices()}</S.Apolices>
    </OpinModal>
  );
};

export default OrigemDadosModal;
