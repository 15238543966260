import { useMemo } from "react";
import * as S from "../MaisDetalhes.style";
import { Text } from "@ds/react";
import { useGestaoContext } from "hooks/useGestaoContext";
import {
  formatDate,
  formatarDataHora,
  getMonthDifference,
} from "utils/helpers";
import { STATUS_KEYS, STATUS_MAPPER } from "utils/constants";

const MainInformationSection = () => {
  const { selectedCompartilhamento } = useGestaoContext();

  const deadlineMessage = useMemo(() => {
    const expirationDate = formatDate(selectedCompartilhamento.dataExpiracao);
    const confirmedDate = formatDate(selectedCompartilhamento.dataConfirmacao);
    const months = getMonthDifference(
      new Date(selectedCompartilhamento.dataSolicitacao),
      new Date(selectedCompartilhamento.dataExpiracao)
    );

    return selectedCompartilhamento.status === STATUS_KEYS.REJECTED
      ? `Expirado em ${confirmedDate}`
      : `${months} meses, até ${expirationDate}`;
  }, [selectedCompartilhamento]);

  return (
    <S.CompartilhamentoInfo>
      <S.SectionItem>
        <Text variant="caption-02">
          {formatarDataHora(selectedCompartilhamento.dataConfirmacao)}
        </Text>
        <Text variant="body-01-md">{selectedCompartilhamento.empresa}</Text>
      </S.SectionItem>

      <S.SectionItem>
        <Text variant="caption-02">Prazo do compartilhamento</Text>
        <Text variant="body-02-md">{deadlineMessage}</Text>
      </S.SectionItem>

      <S.StatusTag
        label={STATUS_MAPPER[selectedCompartilhamento.status].label}
        variant={STATUS_MAPPER[selectedCompartilhamento.status].tag}
      />
    </S.CompartilhamentoInfo>
  );
};

export default MainInformationSection;
