import { useCallback } from "react";
import { useAutorizacaoContext } from "./useAutorizacaoContext";
import useApi from "./useApi";
import { acaoConsentimentoUrl } from "services/endpoints";

const useSolicitanteConfirmation = (consentInteractionId) => {
  const { apolicesSelecionadas } = useAutorizacaoContext();

  const requestData = {
    metadata: {
      resources: apolicesSelecionadas.map((apolice) => ({
        resourceId: apolice.numeroContrato,
        type: apolice.tipoRamo,
        indContrato: apolice.indContrato,
      })),
    },
  };

  const { data, error, loading, fetchData } = useApi({
    method: "post",
    body: requestData,
    url: acaoConsentimentoUrl(consentInteractionId),
    autoRun: false,
  });

  const redirect = useCallback(
    () => window.open(data?.url, "_self"), // NOSONAR
    [data]
  );

  return {
    loading,
    confirmSolicitation: fetchData,
    error,
    response: data,
    redirect,
  };
};

export default useSolicitanteConfirmation;
