import { useLocation } from "react-router-dom";

const SECONDARY_APROVADOR_PATH = "/opin/aprovacao-recurso";
const SECONDARY_APROVADOR_PARAM = "codigoConsentimento";

const useIsSecondaryAprovador = () => {
  const { pathname, search } = useLocation();

  return (
    pathname === SECONDARY_APROVADOR_PATH &&
    search.includes(SECONDARY_APROVADOR_PARAM)
  );
};

export default useIsSecondaryAprovador;
