import React from "react";
import { ConsentimentoProvider } from "../../context/consentimentoContext";
import Consentimento from "./Consentimento";

const ConsentimentoWithProviders = () => (
  <ConsentimentoProvider>
    <Consentimento />
  </ConsentimentoProvider>
);

export default ConsentimentoWithProviders;
