import { GESTAO_ACTION, SCOPE, STATUS_KEYS } from "utils/constants";

export const MAX_COMPARTILHAMENTOS_BY_PAGE = 10;

export const INITIAL_PAGE_NUMBER = 1;

export const FILTROS = [
  { label: "Todos", value: "ALL" },
  { label: "Ativos", value: "AUTHORISED" },
  { label: "Pendentes", value: "AWAITING_AUTHORISATION" },
  { label: "Expirados", value: "REJECTED" },
];

export const TABLE_HEADERS = [
  "Instituição",
  "Status",
  "Solicitado em",
  "Prazo do compartilhamento",
];

export const getActionsByConditions = (scope, status) => {
  const ENABLED_ACTIONS = {
    [SCOPE.RECEPCAO]: {
      [STATUS_KEYS.AUTHORISED]: [
        GESTAO_ACTION.RENEW,
        GESTAO_ACTION.CHANGE,
        GESTAO_ACTION.FINALIZE,
      ],
      [STATUS_KEYS.AWAITING_AUTHORISATION]: [GESTAO_ACTION.FINALIZE],
      [STATUS_KEYS.REJECTED]: [],
    },
    [SCOPE.TRANSMISSAO]: {
      [STATUS_KEYS.AUTHORISED]: [GESTAO_ACTION.FINALIZE],
      [STATUS_KEYS.AWAITING_AUTHORISATION]: [
        GESTAO_ACTION.APPROVE,
        GESTAO_ACTION.FINALIZE,
      ],
      [STATUS_KEYS.REJECTED]: [],
    },
  };

  return ENABLED_ACTIONS[scope][status] || [];
};
