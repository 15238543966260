import { Text } from "@ds/react";
import BodyText from "components/BodyText/BodyText";
import Card from "components/Card/Card";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { useAuth } from "oidc-react";
import React from "react";
import { SEGMENT_BY_TYPE } from "utils/constants";
import * as S from "./ResumoSolicitacaoCard.style";
import { formatCPF, formatarDataHora, getMonthDifference } from "utils/helpers";

const ResumoSolicitacaoCard = () => {
  const auth = useAuth();
  const { interaction, hasApolicesPermissions } = useAutorizacaoContext();

  const { consent, segment, expirationDateTime } = interaction?.data || {};
  const identificationDocument = consent?.loggedUser?.document?.identification;
  const requestPeriodInfo = `${getMonthDifference(
    new Date(consent?.creationDateTime),
    new Date(consent?.expirationDateTime)
  )} meses, até ${formatarDataHora(consent?.expirationDateTime)}`;

  const summaryData = [
    {
      label: "Solicitação criada em",
      value: formatarDataHora(consent?.creationDateTime),
    },
    { label: "Realizada por", value: auth?.userData?.profile.nome },
    { label: "CPF", value: formatCPF(identificationDocument) },
    {
      label: "Segmento",
      value: SEGMENT_BY_TYPE[segment] || "--",
      hidden: !hasApolicesPermissions,
    },
    {
      label: "Instituição que vai receber os dados",
      value: consent?.organizationName,
    },
    { label: "Período de compartilhamento", value: requestPeriodInfo },
  ];

  return (
    <Card
      lead="Compartilhamento de dados"
      title="Resumo da solicitação"
      hasDivider
    >
      {summaryData
        .filter(({ hidden }) => !hidden)
        .map(({ label, value, hidden }) => (
          <S.ResumoItemContainer key={label}>
            <Text variant="caption-02" className="title">
              {label}
            </Text>
            <BodyText variant="body-02-md">{value}</BodyText>
          </S.ResumoItemContainer>
        ))}

      <S.ResumoItemContainer>
        <Text variant="caption-02" className="title">
          Validade da solicitação
        </Text>
        <Text variant="body-02-md">
          Essa solicitação deve ser confirmada até{" "}
          {formatarDataHora(expirationDateTime)}
        </Text>
        <Text variant="caption-02">
          Após esse prazo, você deve fazer uma nova solicitação.
        </Text>
      </S.ResumoItemContainer>
    </Card>
  );
};

export default ResumoSolicitacaoCard;
