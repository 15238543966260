import styled from "styled-components/macro";

export const LoadingContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.color.neutral["08"],
  boxShadow: theme.shadow.base,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "60px 32px",
  gap: "18px",
  marginTop: "20px",
  width: "100%",
}));
