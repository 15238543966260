import { Button, Text } from "@ds/react";
import BodyText from "components/BodyText/BodyText";
import { useGestaoContext } from "hooks/useGestaoContext";
import useActionsData from "pages/Gestao/hooks/useActionsData";
import { STATUS_KEYS } from "utils/constants";
import * as S from "../MaisDetalhes.style";

const ActionSection = () => {
  const { selectedCompartilhamento } = useGestaoContext();
  const { actionsData, enabledActions } = useActionsData(
    selectedCompartilhamento
  );

  if (selectedCompartilhamento.status === STATUS_KEYS.REJECTED) return null;

  return (
    <S.CompartilhamentoInfo>
      <Text variant="caption-01">Gerenciar compartilhamento</Text>

      {actionsData.map(({ type, title, description, label, icon, handler }) => (
        <S.ActionContainer key={type}>
          <BodyText>
            <strong>{title}</strong>: {description}
          </BodyText>
          <Button
            variant="primary-02-inverse-md"
            onClick={handler}
            disabled={!enabledActions.includes(type)}
          >
            {icon}
            <Text variant="button-01-md">{label}</Text>
          </Button>
        </S.ActionContainer>
      ))}
    </S.CompartilhamentoInfo>
  );
};

export default ActionSection;
