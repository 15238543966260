export const TIME_TO_REDIRECT_LIMIT = 5000;

export const DEFAULT_DATA = {
  title: 'Deseja cancelar essa solicitação?',
  description:
    'Se você cancelar, poderá solicitar novamente. Basta acessar o Open Insurance e fazer este mesmo processo, ok?',
  showBtnAction: true,
};

export const SUCCESS_DATA = {
  title: 'A solicitação foi cancelada com sucesso!',
  description:
    'Estamos sempre abertos para enviar seus dados sempre que quiser. Basta solicitar novamente o compartilhamento na intituição para onde você quer que vá os dados.',
  showBtnAction: false,
};

export const ERROR_DATA = {
  title: 'Ops, algo deu errado :(',
  description:
    'Estamos com algum problema em nossos sistemas e não conseguimos concluir sua solicitação. Por favor, tente de novo mais tarde.',
  showBtnAction: false,
};
