import styled from 'styled-components';

export const AprovadorMainData = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoint.lg()]: {
    flexDirection: 'row',
    gap: '20px',
  },
}));

export const AprovadorContainer = styled.div(({ theme }) => ({
  borderBottom: `1px solid ${theme.color.base.secondary.light}`,
  padding: '8px 0 12px 0',
}));

export const DataItem = styled.div({
  minWidth: '200px',

  '&:not(:first-child)': {
    flexGrow: '1',
  },
});

export const StatusTag = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  top: '10px',

  [theme.breakpoint.lg()]: {
    position: 'relative',
  },
}));

export const ListaAprovadores = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',

  '&:first-child': {
    marginBottom: '20px',
  },
});
