import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import * as S from "./Home.style";
import { ReactComponent as Arrows } from "../../assets/icons/transfer-arrows.svg";
import { ReactComponent as Person } from "../../assets/icons/person-ticked.svg";
import Card from "../../components/Card";
import { knowMoreData } from "./constants";
import { Button, Text } from "@ds/react";
import { useModalsContext } from "hooks/useModalsContext";
import { HOME_MODALS } from "../../context/modalsContext";
import withTemplate from "hoc/withTemplate";

const Home = () => {
  const navigate = useNavigate();
  const { openModal, renderModal } = useModalsContext();

  useEffect(() => {
    const hasMadeFirstAccess = localStorage.getItem("opin-first-access");
    if (!hasMadeFirstAccess) {
      localStorage.setItem("opin-first-access", true);
      openModal(HOME_MODALS.primeiroAcesso);
    }
  }, [openModal]);

  return (
    <>
      <S.Container>
        <Card logo={<S.OpinLogo />}>
          <S.ButtonContainer>
            <S.NewShareButton onClick={() => navigate("/opin/consentimento")}>
              <Arrows fontSize={25} />
              <Text variant="body-01-lg">Novo compartilhamento</Text>
            </S.NewShareButton>
            <Button
              variant="secondary-md"
              onClick={() => navigate("/opin/gestao")}
            >
              <Person fontSize={25} />
              <Text variant="body-01-lg">Meus compartilhamentos</Text>
            </Button>
          </S.ButtonContainer>
        </Card>

        <Card lead="Open InSurance" title="Saiba Mais">
          <S.CustomAccordion type="arrow" items={knowMoreData} />

          <S.Link
            onClick={() => window.open("https://opinbrasil.com.br/", "_blank")}
          >
            Acessar Portal do Open Insurance
          </S.Link>
        </Card>
      </S.Container>

      {renderModal()}
    </>
  );
};

export default withTemplate(Home);
