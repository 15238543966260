import { useDeviceType } from "@ds/react";
import { GESTAO_MODALS } from "context/modalsContext";
import { useGestaoContext } from "hooks/useGestaoContext";
import { useModalsContext } from "hooks/useModalsContext";
import useDataByScope from "pages/Gestao/hooks/useDataByScope";
import {
  FILTROS,
  INITIAL_PAGE_NUMBER,
  MAX_COMPARTILHAMENTOS_BY_PAGE,
} from "pages/Gestao/utils";
import { useCallback, useEffect, useState } from "react";
import { SCOPE } from "utils/constants";
import { sliceIntoChunks } from "utils/helpers";
import CompartilhamentoDesktop from "../CompartilhamentoDesktop/CompartilhamentoDesktop";
import CompartilhamentoMobile from "../CompartilhamentoMobile/CompartilhamentoMobile";
import FiltersSection from "./FiltersSection";
import * as S from "./ListaCompartilhamentos.style";
import CardLoading from "components/CardLoading/CardLoading";

const ListaCompartilhamentos = () => {
  const { lg: isLargeDevice } = useDeviceType();
  const { tabType, setTabType } = useGestaoContext();
  const { openModal, modalData, addModalData } = useModalsContext();
  const { data, loading, error, fetchData } = useDataByScope(tabType);

  const [pageData, setPageData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(FILTROS[0]);
  const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER);

  useEffect(() => {
    if (!data?.length) return;

    const filterValue = selectedFilter?.value;
    const filteredCompartilhamentos =
      filterValue === "ALL"
        ? data
        : data.filter(({ status }) => status === filterValue);

    const compartilhamentosInChunks = sliceIntoChunks(
      filteredCompartilhamentos,
      MAX_COMPARTILHAMENTOS_BY_PAGE
    );
    const hasPageDataChanged =
      JSON.stringify(pageData) !== JSON.stringify(compartilhamentosInChunks);

    if (!hasPageDataChanged) return;

    setPageNumber(INITIAL_PAGE_NUMBER);
    setPageData(compartilhamentosInChunks);
  }, [selectedFilter?.value, data, pageData]);

  useEffect(() => {
    if (error) openModal(GESTAO_MODALS.genericError);
  }, [openModal, error]);

  useEffect(() => {
    if (modalData?.reloadPage) {
      fetchData();
      addModalData({ reloadPage: false });
    }
  }, [modalData?.reloadPage, fetchData, addModalData]);

  const renderCompartilhamentoMainContent = useCallback(() => {
    const pieces = pageData?.[pageNumber - 1];
    const Component = isLargeDevice
      ? CompartilhamentoDesktop
      : CompartilhamentoMobile;

    return <Component compartilhamentos={pieces} />;
  }, [isLargeDevice, pageNumber, pageData]);

  if (loading) return <CardLoading />;

  return (
    <>
      <S.Container>
        <S.CompartilhamentoTypeContainer>
          <S.CompartilhamentoType
            selected={tabType === SCOPE.RECEPCAO}
            onClick={() => setTabType(SCOPE.RECEPCAO)}
          >
            Dados recebidos
          </S.CompartilhamentoType>
          <S.CompartilhamentoType
            selected={tabType === SCOPE.TRANSMISSAO}
            onClick={() => setTabType(SCOPE.TRANSMISSAO)}
          >
            Dados enviados
          </S.CompartilhamentoType>
        </S.CompartilhamentoTypeContainer>

        <FiltersSection
          filters={FILTROS}
          selectedFilter={selectedFilter}
          onSelectFilter={setSelectedFilter}
        />

        {renderCompartilhamentoMainContent()}
      </S.Container>

      <S.Pagination
        page={pageNumber}
        onChange={setPageNumber}
        total={pageData?.length || INITIAL_PAGE_NUMBER}
      />
    </>
  );
};

export default ListaCompartilhamentos;
