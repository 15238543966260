import { AuthProvider, useAuth } from "oidc-react";
import { Outlet } from "react-router-dom";
import Welcome from "components/Welcome";
import withTemplate from "hoc/withTemplate";

const oidcConfig = {
  clientId: process.env.REACT_APP_OPIN_CLIENT_ID,
  redirectUri: `${window.location.origin}/opin/redirect${window.location.search}`,
  authority: process.env.REACT_APP_SSO_HOST,
  scope: "api1 openid profile offline_access app-opin-previsul",
};

const OpinAuth = () => {
  const auth = useAuth();

  if (auth.isLoading) {
    const Loading = withTemplate(() => <Welcome />);
    return <Loading />;
  }

  return <Outlet />;
};

const OpinAuthProvider = () => {
  const delimiter = window.location.href.includes("#") ? "#" : "?";
  if (
    window.location.href.includes("id_token") ||
    window.location.href.includes("error")
  ) {
    sessionStorage.setItem(
      "efetivacaoData",
      window.location.href.split(delimiter)[1]
    );
    window.location.href = window.location.href
      .replace("/efetivacao", "/redirect")
      .split(delimiter)[0];
    return null;
  }

  if (
    window.location.href.includes("interactionId") &&
    !window.location.href.includes("redirect")
  ) {
    const interactionId = window.location.href.split("?")[1]?.split("=")[1];
    sessionStorage.setItem("interactionId", interactionId);
    window.location.href = window.location.href.replace(
      "/autorizacao",
      "/redirect"
    );
  }

  return (
    <AuthProvider {...oidcConfig}>
      <OpinAuth />
    </AuthProvider>
  );
};

export default OpinAuthProvider;
