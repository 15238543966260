const HOST = process.env.REACT_APP_APIC_HOST;

export const loginConsentimentoUrl = (interactionId) =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/transmissora/consentimentos/${interactionId}/interacao`;

export const acaoConsentimentoUrl = (interactionId) =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/transmissora/consentimentos/${interactionId}`;

export const getConsentimentoOperationUrl = ({ id, scope, isRevoked }) =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/${scope}/consentimentos/${id}${
    isRevoked ? "/revogado" : ""
  }`;

export const getSecondApproverConsentimentoOperationUrl = () =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/`; // TODO: endpoint de segunda aprovação

export const getApolicesUrl = (consentId, pageNumber, segmento, pageSize) =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/processos/UICCONSENT/etapas/OPINCOMPD/consentimentos/${consentId}/recursos/${segmento}/dados-compartilhados?pagina=${pageNumber}&tamanhoPagina=${pageSize}`;

// GET permissions by names
export const getPermissionsByNamesUrl = (names) => {
  const query = names?.length ? `?nomes=${names.join(",")}` : "";
  return `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/permissoes${query}`;
};

// GET segmentos
export const getSegmentosUrl = () =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/segmentos`;

// GET permissions by segmento
export const getPermissionsBySegmento = (sigla) =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/segmentos/${sigla}/permissoes?tipoPermissao=F`;

export const pegarParticipantesUrl = (search) =>
  `${HOST}/open-insurance-previsul/v1/operacoes-participantes/participantes${
    search ? `?name=${search}` : ""
  }`;

export const pegarParticipanteByOrgIdUrl = (organisationId) =>
  `${HOST}/open-insurance-previsul/v1/operacoes-participantes/participantes/${organisationId}`;

export const solicitarConsentimentoUrl = () =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/receptora/solicitacoes-consentimentos`;

export const getCallbackDataUrl = () =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/receptora/consentimentos-callback`;

export const getConsentimentoRecebidoUrl = (consentId) =>
  `${HOST}/open-insurance-previsul/v1/operacoes-consentimento/receptora/consentimentos-recebidos/${consentId}`;
