import { Button, Select, Text } from "@ds/react";
import styled from "styled-components";

export const Link = styled.button(({ theme }) => ({
  textDecorationLine: "underline",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  color: theme.color.brandPrimary.light,
  padding: 0,
  fontSize: "16px",
  alignSelf: "center",
  display: "flex",

  svg: {
    marginLeft: "5px",
  },
}));

export const SearchInstituicaoButton = styled(Button)(({ fullWidth }) => ({
  cursor: "pointer",
  width: fullWidth ? "100%" : "fit-content",

  svg: {
    marginRight: "10px",
  },
}));

export const SearchInstituicaoComponentContainer = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "400px",

  svg: {
    cursor: "pointer",
    marginRight: "10px",
  },
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  gap: "32px",
  marginTop: 8,

  [theme.breakpoint.lg()]: {
    flexDirection: "row",
    marginTop: "32px",
    gap: "24px",
  },
}));

export const SectionContainer = styled.div(() => ({
  gap: "24px",
  display: "flex",
  flexDirection: "column",
}));

export const SectionTitle = styled(Text).attrs({
  variant: "headline-07",
})(() => ({
  textTransform: "inherit",
}));

export const Caption = styled.div.attrs({
  variant: "caption-02",
})(({ theme }) => ({
  fontSize: "14px",
  color: theme.color.neutral["03"],
}));

export const CustomSelect = styled(Select)(() => ({
  "&.no-overlaid": {
    zIndex: 0,
  },
}));

export const SectionSubtitle = styled(Text).attrs({
  variant: "body-02-sm",
})(() => ({
  fontWeight: 500,
}));

export const IdentificationInfoContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 5,

  "> *:first-child": {
    color: theme.color.neutral["03"],
  },
}));
