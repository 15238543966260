import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as S from "./Consentimento.style";
import {
  ETAPAS_ALTERACAO,
  ETAPAS_CRIACAO,
  ETAPAS_RENOVACAO,
} from "./constants";
import Compartilhamento from "./etapas/Compartilhamento";
import Redirecionamento from "./etapas/Redirecionamento";
import Renovacao from "./etapas/Renovacao";
import Solicitacao from "./etapas/Solicitacao";
import Termos from "./etapas/Termos";
import { useLocation, useNavigate } from "react-router-dom";
import { useConsentimentoContext } from "hooks/useConsentimentoContext";
import NavigationHeader from "components/NavigationHeader/NavigationHeader";
import Steps from "components/Steps";
import withTemplate from "hoc/withTemplate";
import useScrollTop from "hooks/useScrollTop";
import { GESTAO_ACTION } from "utils/constants";
import useDetailedConsentimento from "hooks/useDetailedConsentimento";
import useMutableRef from "hooks/useMutableRef";
import Welcome from "components/Welcome/Welcome";

const Consentimento = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { getDetailedConsentimento, loading } = useDetailedConsentimento();
  const {
    isFirstStep,
    currentStep,
    stepBack,
    changeSteps,
    setInitialConsentimentoData,
  } = useConsentimentoContext();

  const [isRedirectVisible, setIsRedirectVisible] = useState(false);

  const stepsByAction = useMemo(() => {
    const stepsByActionMapper = {
      [GESTAO_ACTION.CHANGE]: ETAPAS_ALTERACAO,
      [GESTAO_ACTION.RENEW]: ETAPAS_RENOVACAO,
    };
    return stepsByActionMapper[state?.action] || ETAPAS_CRIACAO;
  }, [state?.action]);
  const changeCaseArr = useMemo(
    () => [currentStep, isRedirectVisible],
    [currentStep, isRedirectVisible]
  );

  useScrollTop({ changeCase: changeCaseArr });

  const ETAPA_COMPONENT_MAPPER = useMemo(
    () => ({
      Renovação: <Renovacao />,
      Solicitação: <Solicitacao />,
      Compartilhamento: <Compartilhamento />,
      "Termos e condições": (
        <Termos onConfirmation={() => setIsRedirectVisible(true)} />
      ),
    }),
    []
  );

  const renderEtapa = useCallback(
    () => ETAPA_COMPONENT_MAPPER[currentStep],
    [ETAPA_COMPONENT_MAPPER, currentStep]
  );

  const getConsentimento = useCallback(async () => {
    const response = await getDetailedConsentimento(state?.compartilhamento);
    if (response) setInitialConsentimentoData(response);
  }, [
    getDetailedConsentimento,
    setInitialConsentimentoData,
    state?.compartilhamento,
  ]);

  const getConsentimentoRef = useMutableRef(getConsentimento);

  useEffect(() => {
    if (state) getConsentimentoRef.current();
  }, [getConsentimentoRef, state]);

  useEffect(() => {
    changeSteps(stepsByAction);
  }, [changeSteps, stepsByAction]);

  if (loading) return <Welcome />; // TODO: adicionar componente de 'loading'

  if (isRedirectVisible)
    return (
      <Redirecionamento
        backToTermsAndConditions={() => setIsRedirectVisible(false)}
      />
    );

  return (
    <S.ConsentimentoContainer>
      <NavigationHeader onBack={isFirstStep ? () => navigate(-1) : stepBack} />
      <Steps steps={stepsByAction} currentStep={currentStep} />

      {renderEtapa()}
    </S.ConsentimentoContainer>
  );
};

export default withTemplate(Consentimento);
