import React, { useState, useEffect } from "react";
import ApolicesList from "./ApolicesList";
import ApolicesHeaderContent from "./ApolicesHeaderContent";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import useIsSecondaryAprovador from "hooks/useIsSecondaryAprovador";
import { useModalsContext } from "hooks/useModalsContext";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import useApolices, {
  DEFAULT_APOLICES_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
} from "hooks/useApolices";
import useMutableRef from "hooks/useMutableRef";
import OpinModal from "components/OpinModal/OpinModal";
import { Divider, Pagination } from "@ds/react";

const ApolicesModal = () => {
  const { apolices } = useAutorizacaoContext();
  const isSecondaryAprovador = useIsSecondaryAprovador();
  const { closeModal, openedModalType } = useModalsContext();

  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);

  const isModalOpened = openedModalType === AUTORIZACAO_MODALS.apolices;

  const { getApolices, loading } = useApolices(page);
  const getApolicesRef = useMutableRef(getApolices);

  const showPagination = apolices?.total > DEFAULT_APOLICES_PAGE_SIZE;

  useEffect(() => {
    if (isModalOpened && getApolicesRef.current) getApolicesRef.current();
  }, [getApolicesRef, page, isModalOpened]);

  return (
    <OpinModal
      open={openedModalType === AUTORIZACAO_MODALS.apolices}
      title="Origem dos dados"
      onClose={closeModal}
      description="Selecione as apólices da origem dos dados a serem compartilhados."
    >
      <ApolicesHeaderContent isPaginationEnabled={!!showPagination} />

      <Divider />

      <ApolicesList loading={loading} disabled={isSecondaryAprovador} />

      {showPagination && (
        <Pagination
          total={Math.ceil(apolices?.total / DEFAULT_APOLICES_PAGE_SIZE)}
          page={page}
          onChange={setPage}
        />
      )}
    </OpinModal>
  );
};

export default ApolicesModal;
