import OpinModal from "components/OpinModal";
import React from "react";
import * as S from "./FirstAccessModal.style";
import OpinLogo from "../../../../assets/icons/OpenInsuranceLogo.svg";
import { Text } from "@ds/react";

const FirstAccessModal = ({ show, close }) => (
  <OpinModal open={show} onClose={close}>
    <S.FirtAccessModalContent>
      <S.FirtAccessModalImage src={OpinLogo} alt="Opin Logo" />
      <Text variant="body-02-md">
        O Open Insurance, implementado pela Resolução nº 415/2021 do Conselho
        Nacional de Seguros Privados - CNSP, é um sistema de compartilhamento
        padronizado de dados e serviços, mediante seu devido prévio
        consentimento, que pode auxiliar na prestação de novos serviços e
        produtos, com maior eficiência tecnológica e operacional, por meio de
        abertura e integração de sistemas no âmbito dos mercados de seguros,
        previdência complementar aberta e capitalização.
      </Text>
      <Text variant="body-02-md">
        Se quiser saber mais sobre como o Open Insurance funciona e como é capaz
        de mudar para melhor a vida de muitos brasileiros, acesse o{"  "}
        <S.BodyLink href="https://opinbrasil.com.br/" target="_blank">
          Portal do Open Insurance.
        </S.BodyLink>
      </Text>
    </S.FirtAccessModalContent>
  </OpinModal>
);

export default FirstAccessModal;
