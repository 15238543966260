import styled from "styled-components";

export const SpecialSection = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderLeftColor: theme.color.brandSecondary.light,
  borderLeftWidth: "2px",
  borderLeftStyle: "solid",
  paddingLeft: "16px",
  gap: "12px",

  [theme.breakpoint.lg()]: {
    gap: "24px",
  },
}));

export const DataOriginContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
}));
