import styled from "styled-components";

export const TermosText = styled.div({
  textAlign: "justify",
  ul: {
    margin: "12px 0",
  },
  ol: {
    paddingInlineStart: "20px",
    li: {
      margin: "12px 0",
    },
  },
  "li:first-letter": {
    textTransform: "capitalize",
  },
});
