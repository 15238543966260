import { Divider } from "@ds/react";
import React from "react";

import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/arrow-left.svg";

import * as S from "./NavigationHeader.style";

const NavigationHeader = ({ onBack, ...props }) => (
  <div {...props}>
    <S.NavButton variant="tertiary-02-md-icon" onClick={onBack} data-testid="back-button">
      <ArrowLeftIcon />
      Voltar
    </S.NavButton>
    <Divider />
  </div>
);

export default NavigationHeader;
