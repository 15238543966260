import React from "react";
import { Text, useDeviceType } from "@ds/react";

import * as S from "./HeaderOpin.style";
import { useAuth } from "oidc-react";

const HeaderOpin = () => {
  const { lg } = useDeviceType();
  const auth = useAuth();

  return (
    <S.Header>
      <S.Content>
        <a href="/">
          <S.LogoPrevisul data-label="header home logo" />
        </a>
        <S.Menu>
          {lg && (
            <Text variant="caption-02">{auth?.userData?.profile.nome}</Text>
          )}
          <S.LogoutIcon onClick={() => auth?.signOutRedirect()} />
        </S.Menu>
      </S.Content>
    </S.Header>
  );
};

export default HeaderOpin;
