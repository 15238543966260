import { COMMON_MODALS } from "context/modalsContext";
import { useState } from "react";
import { getPermissionsByNamesUrl } from "services/endpoints";
import { useModalsContext } from "./useModalsContext";
import { useOpinAuth } from "./useOpinAuth";

// TODO: definir com o time a mensagem de erro
const GET_CONSENT_DETAILS_ERROR_MESSAGE =
  "Erro ao buscar detalhes do consentimento.";

const useDetailedConsentimento = () => {
  const api = useOpinAuth();
  const { openModal } = useModalsContext();

  const [loading, setLoading] = useState(false);

  const getDetailedConsentimento = async (consentimento) => {
    const { permissions, empresa, empresaLogo } = consentimento || {};

    if (!permissions) return;

    setLoading(true);

    try {
      const { data: detailedPermissions } = await api.get(
        getPermissionsByNamesUrl(permissions)
      );

      return {
        ...consentimento,
        instituicao: {
          CustomerFriendlyName: empresa,
          CustomerFriendlyLogoUri: empresaLogo,
        },
        permissions: {
          required: detailedPermissions.filter((p) => p.obrigatorio),
          optional: detailedPermissions.filter((p) => !p.obrigatorio),
        },
      };
    } catch (error) {
      openModal(COMMON_MODALS.genericError, {
        error,
        message: GET_CONSENT_DETAILS_ERROR_MESSAGE,
      });
    } finally {
      setLoading(false);
    }
  };

  return { loading, getDetailedConsentimento };
};

export default useDetailedConsentimento;
