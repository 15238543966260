import { GESTAO_MODALS } from "context/modalsContext";
import { useGestaoContext } from "hooks/useGestaoContext";
import { useModalsContext } from "hooks/useModalsContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import { GESTAO_ACTION, SCOPE } from "utils/constants";
import { getActionsByConditions } from "../utils";
import { ReactComponent as CancelIcon } from "assets/icons/cancel.svg";
import { ReactComponent as EditIcon } from "assets/icons/edit.svg";
import { ReactComponent as RefreshIcon } from "assets/icons/refresh.svg";

const useActionsData = (compartilhamento) => {
  const navigate = useNavigate();
  const { openModal } = useModalsContext();
  const { tabType } = useGestaoContext();

  const navigateToUpdateConsent = (action) =>
    navigate("/opin/consentimento", {
      state: { action, compartilhamento: compartilhamento },
    });

  const handleOpenModal = () =>
    openModal(GESTAO_MODALS.finalizeSharing, {
      compartilhamento: compartilhamento,
      scope: tabType,
    });

  const enabledActions = getActionsByConditions(
    tabType,
    compartilhamento.status
  );

  const actionButtonsData = [
    {
      type: GESTAO_ACTION.RENEW,
      title: "Renovação",
      description:
        "crie um novo compartilhamento usando seus dados atuais, sem alterá-los.",
      label: "Renovar compartilhamento",
      icon: <RefreshIcon />,
      handler: () => navigateToUpdateConsent(GESTAO_ACTION.RENEW),
    },
    {
      type: GESTAO_ACTION.CHANGE,
      title: "Alteração",
      description:
        "crie um novo compartilhamento usando seus dados. Essa opção encerra o compartilhamento atual.",
      label: "Alterar compartilhamento",
      icon: <EditIcon />,
      handler: () => navigateToUpdateConsent(GESTAO_ACTION.CHANGE),
    },
    {
      type: GESTAO_ACTION.FINALIZE,
      title: "Finalização",
      description:
        tabType === SCOPE.RECEPCAO
          ? `a instituição ${compartilhamento.empresa} para de compartilhar os seus dados com a gente.`
          : `a gente para de compartilhar os seus dados com a instituição ${compartilhamento.empresa}.`,
      label: "Finalizar compartilhamento",
      icon: <CancelIcon />,
      handler: handleOpenModal,
    },
  ];

  return {
    actionsData: actionButtonsData.filter((action) =>
      enabledActions.includes(action.type)
    ),
    enabledActions,
  };
};

export default useActionsData;
