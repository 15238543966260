import styled from "styled-components";
import { Checkbox, Text } from "@ds/react";

export const HeaderCheckboxContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "12px",
}));

export const CheckBoxContent = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",
}));

export const ItemCheckBoxContainer = styled.div(() => ({
  display: "flex",
  gap: "8px",
}));

export const ApolicesListContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  margin: "16px 0",
}));

export const CustomCheckbox = styled(Checkbox)(() => ({
  width: "fit-content",
  margin: "0",
  padding: "0",
  border: "none",
  alignSelf: "center",
}));

export const VigenciaText = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["03"],
}));
