import { Text } from "@ds/react";
import styled from "styled-components";

export const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "20px",
}));

export const List = styled.ul(({ theme }) => ({
  maxHeight: "140px",
  paddingLeft: 16,
  overflowY: "scroll",
  listStyleType: "disc",
  listStylePosition: "outside",

  [theme.breakpoint.sm()]: {
    maxHeight: "300px",
  },
}));

export const ListItem = styled.li(() => ({
  textAlign: "left",
  listStylePosition: "outside",
  textIndent: "0em",
}));

export const Lead = styled(Text)(() => ({
  textTransform: "uppercase",
}));

export const Link = styled.a(() => ({
  textDecoration: "underline",
}));

export const Title = styled(Text)`
  && {
    font-weight: 500;
  }
`;
