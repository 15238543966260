import React from "react";
import * as S from "../CompartilhamentoDesktop.style";
import { Text } from "@ds/react";
import BodyText from "components/BodyText/BodyText";
import { formatarDataHora, partialCPF } from "utils/helpers";
import { useAuth } from "oidc-react";
import { STATUS_MAPPER } from "utils/constants";

const ResumoSolicitacaoItemSection = ({ compartilhamento }) => {
  const auth = useAuth();

  const { status, dataConfirmacao, cpf, empresa, segmento, dataSolicitacao } =
    compartilhamento;

  const prazoSolicitacao = new Date(dataSolicitacao).setHours(
    new Date(dataSolicitacao).getHours() + 1
  );

  return (
    <S.Section>
      <Text variant="caption-01" color="text-light">
        Resumo da solicitação
      </Text>

      <S.DataSection>
        <S.DetailsGrid>
          <S.CPFSection>
            <Text variant="caption-01" color="text-light">
              CPF
            </Text>
            <BodyText>{partialCPF(cpf)}</BodyText>
          </S.CPFSection>
          <S.EmpresaSection>
            <Text variant="caption-01" color="text-light">
              Instituição que recebe os dados
            </Text>
            <BodyText>{empresa}</BodyText>
          </S.EmpresaSection>
          <S.DataLimiteSection>
            <Text variant="caption-01" color="text-light">
              {STATUS_MAPPER[status].deadlineMsg}
            </Text>
            <BodyText>
              {formatarDataHora(
                status === "AWAITING_AUTHORISATION"
                  ? prazoSolicitacao
                  : dataConfirmacao
              )}
            </BodyText>
          </S.DataLimiteSection>
          <S.NomeSection>
            <Text variant="caption-01" color="text-light">
              Nome
            </Text>
            <BodyText>{auth?.userData?.profile.nome}</BodyText>
          </S.NomeSection>
          <S.SegmentoSection>
            <Text variant="caption-01" color="text-light">
              Segmento
            </Text>
            <BodyText>{segmento || "--"}</BodyText>
          </S.SegmentoSection>
        </S.DetailsGrid>
      </S.DataSection>
    </S.Section>
  );
};

export default ResumoSolicitacaoItemSection;
