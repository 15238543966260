import { useEffect } from "react";
import { useAutorizacaoContext } from "./useAutorizacaoContext";
import useApolices from "./useApolices";
import useAutorizacaoPermissions from "./useAutorizacaoPermissions";
import useConsentimentoByInteractionId from "./useConsentimentoByInteractionId";

const useFetchAutorizacaoData = () => {
  const { interaction, hasApolicesPermissions } = useAutorizacaoContext();
  const { loading: loadingConsentimento } = useConsentimentoByInteractionId();
  const { loading: apolicesLoading, getApolices } = useApolices();
  const { getDetailedPermissions, loading: loadingPermissions } =
    useAutorizacaoPermissions();

  useEffect(() => {
    if (interaction?.data)
      getDetailedPermissions(interaction.data?.consent.permissions || []);
  }, [interaction?.data, getDetailedPermissions]);

  useEffect(() => {
    if (!!getApolices && hasApolicesPermissions) getApolices();
  }, [getApolices, hasApolicesPermissions]);

  return {
    loading: loadingConsentimento || apolicesLoading || loadingPermissions,
  };
};

export default useFetchAutorizacaoData;
