import React from "react";
import { Divider, Text } from "@ds/react";
import * as S from "./DataSharingInfo.style";
import Card from "../../../../../../components/Card";
import BodyText from "../../../../../../components/BodyText";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import { formatarDataHora } from "utils/helpers";

const DataSharingInfo = ({ loading }) => {
  const { interaction } = useAutorizacaoContext();
  const { consent, expirationDateTime } = interaction?.data || {};

  return (
    <Card
      loading={loading}
      lead="O que será compartilhado"
      title="Compartilhamento de dados"
      hasDivider
    >
      <S.PayAttentionInfoContainer>
        <BodyText>
          <strong>Fique atento:</strong>&nbsp;Essa solicitação deve ser
          confirmada até {formatarDataHora(expirationDateTime)}
        </BodyText>
        <Text variant="caption-02">
          Após esse prazo, você deve fazer uma nova solicitação.
        </Text>
      </S.PayAttentionInfoContainer>
      <Divider />
      <BodyText>
        Antes de compartilhar seus dados com a instituição&nbsp;
        <strong>{consent?.organizationName}</strong>, confirme as informações
        abaixo.
      </BodyText>
    </Card>
  );
};
export default DataSharingInfo;
