import React from "react";
import Gestao from "./Gestao";
import { GestaoProvider } from "context/gestaoContext";

const GestaoWithProviders = () => (
  <GestaoProvider>
    <Gestao />
  </GestaoProvider>
);

export default GestaoWithProviders;
