import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",

  [theme.breakpoint.lg()]: {
    gap: "24px",
  },
}));

export const Link = styled.button(({ theme, center }) => ({
  textDecorationLine: "underline",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  color: theme.color.brandPrimary.light,
  textAlign: center ? "center" : "left",
  padding: 0,
  fontSize: "16px",
}));
