import { Navigate } from "react-router-dom";

const extractParams = (params) => {
  return [...params.split("&")].reduce((prev, curr) => {
    const [key, value] = curr.split("=");
    prev[key] = value;
    return prev;
  }, {});
};

const Redirect = () => {
  const params = sessionStorage.getItem("efetivacaoData");
  if (params) {
    const { code, id_token, state, error, error_description } =
      extractParams(params);

    return (
      <Navigate
        to="/opin/efetivacao"
        state={{
          code,
          state,
          idToken: id_token,
          error: error,
          errorDescription: error_description,
        }}
      />
    );
  }

  if (sessionStorage.getItem("interactionId")) {
    return (
      <Navigate
        to="/opin/autorizacao"
        state={{ interactionId: sessionStorage.getItem("interactionId") }}
      />
    );
  }

  return <Navigate to="/opin" />;
};

export default Redirect;
