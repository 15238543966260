import { Button } from "@ds/react";
import BodyText from "components/BodyText/BodyText";
import styled from "styled-components";

export const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  gap: "20px",
}));

export const CheckboxContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "12px",
}));

export const Apolice = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
}));

export const Apolices = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "24px",
  margin: "16px 0",
}));
export const ApoliceText = styled(BodyText)(() => ({
  justifySelf: "flex-start",
}));

export const CloseButton = styled(Button)(() => ({
  width: "100%",
  alignSelf: "center",
}));
