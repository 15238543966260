import useDadosEnviados from "hooks/useDadosEnviados";
import useDadosRecebidos from "hooks/useDadosRecebidos";
import { useMemo } from "react";
import { SCOPE } from "utils/constants";

const apiDataToCompartilhamentosMapper = (consentimentosData) =>
  consentimentosData.map((consentimento) => ({
    id: consentimento.consentId,
    empresa: consentimento.organizationName,
    empresaLogo: consentimento.organizationLogo,
    status: consentimento.status,
    dataSolicitacao: consentimento.creationDateTime,
    dataConfirmacao: consentimento.statusUpdateDateTime,
    dataExpiracao: consentimento.expirationDateTime,
    prazo: consentimento.consentTerm,
    cpf: consentimento.loggedUser.document.identification,
    segmento: "Patrimonial", // TODO: add segmento property dynamically when the API's ready
    permissions: consentimento.permissions,
    apolices: consentimento?.data && Array.isArray(consentimento.data.resources) ? 
    consentimento.data.resources.map((apolice) =>
    ({
            numeroContrato: apolice.resourceId,
            descricao: apolice.type,
            // TODO: add aprovadores property here when it's Ok show it
          }))
        : [],
    }));

const useDataByScope = (scope = SCOPE.RECEPCAO) => {
  const receivedData = useDadosRecebidos();
  const sentData = useDadosEnviados();

  const dataByScope = useMemo(
    () => ({
      [SCOPE.RECEPCAO]: receivedData,
      [SCOPE.TRANSMISSAO]: sentData,
    }),
    [receivedData, sentData]
  );

  const data = useMemo(
    () => apiDataToCompartilhamentosMapper(dataByScope[scope].data ?? []),
    [dataByScope, scope]
  );

  return {
    data,
    loading: receivedData.loading || sentData.loading,
    error: receivedData.error || sentData.error,
    fetchData: dataByScope[scope].fetchData,
  };
};

export default useDataByScope;
