import { Divider, Text } from "@ds/react";
import BodyText from "components/BodyText/BodyText";
import { useGestaoContext } from "hooks/useGestaoContext";
import useDadosCompartilhados from "pages/Gestao/hooks/useDadosCompartilhados";
import * as S from "../MaisDetalhes.style";

const DadosCompartilhamentoSection = () => {
  const { selectedCompartilhamento } = useGestaoContext();
  const { renderPermissions, renderSelectedApolicesInfo } =
    useDadosCompartilhados();

  const { segmento } = selectedCompartilhamento || {};

  return (
    <S.CompartilhamentoInfo>
      <Text variant="caption-01">Dados compartilhados</Text>
      {renderPermissions()}

      <Divider />

      {segmento && (
        <S.SectionItem>
          <Text variant="caption-01">Segmento/produto</Text>
          <BodyText>Apólice de {segmento || "--"}</BodyText>
          {renderSelectedApolicesInfo()}
        </S.SectionItem>
      )}
    </S.CompartilhamentoInfo>
  );
};

export default DadosCompartilhamentoSection;
