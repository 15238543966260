import useEtapas from "hooks/useEtapas";
import useSegmentos from "hooks/useSegmentos";
import { createContext, useCallback, useMemo, useState } from "react";

const ConsentimentoContext = createContext({});

export const ConsentimentoProvider = ({ children }) => {
  const etapasHandler = useEtapas([]);
  const segmentosHandler = useSegmentos();

  const [selectedInstituicao, setSelectedInstituicao] = useState(null);
  const [prazo, setPrazo] = useState();
  const [isTermosAccepeted, setIsTermosAccepeted] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState({});

  const setInitialConsentimentoData = useCallback(
    (compartilhamento) => {
      segmentosHandler.setSelectedSegmento(compartilhamento?.segmento);
      setSelectedInstituicao(compartilhamento.instituicao);
      setPrazo(compartilhamento.consentTerm);
      setSelectedPermissions(compartilhamento.permissions);
    },
    [segmentosHandler]
  );

  const contextValues = useMemo(
    () => ({
      selectedInstituicao,
      setSelectedInstituicao,
      segmentosHandler,
      isTermosAccepeted,
      setIsTermosAccepeted,
      prazo,
      setPrazo,
      selectedPermissions,
      setSelectedPermissions,
      setInitialConsentimentoData,
      ...etapasHandler,
    }),
    [
      selectedInstituicao,
      segmentosHandler,
      isTermosAccepeted,
      prazo,
      selectedPermissions,
      setInitialConsentimentoData,
      etapasHandler,
    ]
  );

  return (
    <ConsentimentoContext.Provider value={contextValues}>
      {children}
    </ConsentimentoContext.Provider>
  );
};

export default ConsentimentoContext;
