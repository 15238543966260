import React, { useCallback, useMemo } from "react";
import { useGestaoContext } from "hooks/useGestaoContext";
import AccordionItemTitle from "./components/AccordionItemTitle";
import AprovadoresItemSection from "./components/AprovadoresItemSection";
import * as S from "./CompartilhamentoDesktop.style";
import DadosCompartilhadosItemSection from "./components/DadosCompartilhadosItemSection";
import ManageCompartilhamentoItemFooter from "./components/ManageCompartilhamentoItemFooter";
import ResumoSolicitacaoItemSection from "./components/ResumoSolicitacaoItemSection";
import { Accordion, Divider, Text } from "@ds/react";
import { TABLE_HEADERS } from "pages/Gestao/utils";

const CompartilhamentoDesktop = ({ compartilhamentos = [] }) => {
  const { setSelectedCompartilhamento } = useGestaoContext();

  const renderTableHeaders = useCallback(
    () =>
      TABLE_HEADERS.map((header) => (
        <Text key={header} variant="caption-01">
          {header}
        </Text>
      )),
    []
  );

  const accordionItems = useMemo(
    () =>
      compartilhamentos.map((item) => ({
        title: <AccordionItemTitle compartilhamento={item} />,
        body: (
          <S.Item>
            <ResumoSolicitacaoItemSection compartilhamento={item} />

            <DadosCompartilhadosItemSection />

            <AprovadoresItemSection apolices={item.apolices} />

            <ManageCompartilhamentoItemFooter compartilhamento={item} />
          </S.Item>
        ),
      })),
    [compartilhamentos]
  );

  return (
    <S.Lista>
      <S.TableHeadersContainer>{renderTableHeaders()}</S.TableHeadersContainer>

      <Divider />

      <Accordion
        items={accordionItems}
        onChange={(idx) => setSelectedCompartilhamento(compartilhamentos[idx])}
      />
    </S.Lista>
  );
};

export default CompartilhamentoDesktop;
