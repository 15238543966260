import React, { useRef } from "react";
import { ReactComponent as SearchIcon } from "../../../../../../assets/icons/search.svg";
import * as S from "./SearchInput.style";

const SearchInput = ({ value, onChange }) => {
  const inputRef = useRef();

  const handleInputFocus = () => inputRef.current?.focus();

  return (
    <S.SearchInputContainer onClick={handleInputFocus}>
      <SearchIcon />

      <S.SearchInput
        ref={inputRef}
        autoFocus
        placeholder="Nome da instituição"
        value={value}
        onChange={({ target: { value: inputValue } }) => onChange(inputValue)}
      />
    </S.SearchInputContainer>
  );
};

export default SearchInput;
