import useApi from "./useApi";

const useDadosRecebidos = () => {
  const { data, error, loading, fetchData } = useApi({
    method: "get",
    url: `/open-insurance-previsul/v1/operacoes-consentimento/receptora/consentimentos-recebidos`,
  });

  return {
    loading,
    data,
    error,
    fetchData,
  };
};

export default useDadosRecebidos;
