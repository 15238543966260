import styled from "styled-components";
export { Link } from "../../Confirmacao.style";

export const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "32px",

  [theme.breakpoint.lg()]: {
    flexDirection: "row",
    gap: "24px",
  },
}));
