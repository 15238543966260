import styled from "styled-components";

export const ResumoItemContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",

  "& .title": {
    color: theme.color.neutral["03"],
  },
}));
