import { Text } from "@ds/react";
import styled from "styled-components";

export const Apolices = styled.div(() => ({
  gap: "24px",
  margin: "16px 0",
  flexDirection: "column",
  display: "flex",
}));

export const CheckboxContainer = styled.div(() => ({
  alignItems: "center",
  display: "flex",
  gap: "12px",
  flexDirection: "row",
}));

export const VigenciaText = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["03"],
}));

export const ApoliceContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));
