import { Button, Checkbox, Divider, Text, useDeviceType } from "@ds/react";
import Card from "components/Card/Card";
import { CONSENTIMENTO_MODALS } from "context/modalsContext";
import { useConsentimentoContext } from "hooks/useConsentimentoContext";
import { useModalsContext } from "hooks/useModalsContext";
import DisabledDataToShareCard from "pages/Consentimento/components/DisabledDataToShareCard/DisabledDataToShareCard";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCurrentDatePlusMonths } from "utils/helpers";
import * as S from "./Termos.style";
import { GESTAO_ACTION } from "utils/constants";

const Termos = ({ onConfirmation }) => {
  const navigate = useNavigate();
  const { openModal, renderModal } = useModalsContext();
  const { lg: isLargeDevice } = useDeviceType();
  const {
    selectedInstituicao,
    segmentosHandler,
    isTermosAccepeted,
    setIsTermosAccepeted,
    selectedPermissions,
    prazo,
  } = useConsentimentoContext();

  const { state } = useLocation();

  const monthsToAdd = prazo?.replace(/\D+/g, "");
  const summaryCardTitle =
    state?.action === GESTAO_ACTION.CHANGE
      ? "Resumo da alteração"
      : "Resumo da solicitação";

  const finalDate = useMemo(
    () => getCurrentDatePlusMonths(monthsToAdd),
    [monthsToAdd]
  );

  return (
    <S.Container>
      <Card
        lead="Compartilhamento de dados"
        title={summaryCardTitle}
        hasDivider
      >
        <S.RequestSummaryItemContainer>
          <Text variant="caption-02">
            Instituição que vai compartilhar os dados
          </Text>
          <Text variant="body-02-md">
            {selectedInstituicao?.CustomerFriendlyName ||
              state?.compartilhamento?.empresa}
          </Text>
        </S.RequestSummaryItemContainer>
        <S.RequestSummaryItemContainer>
          <Text variant="caption-02">Segmento</Text>
          <Text variant="body-02-md">
            {segmentosHandler.filledSegmento?.nomeClassificacao}
          </Text>
        </S.RequestSummaryItemContainer>
        <S.RequestSummaryItemContainer>
          <Text variant="caption-02">Período de compartilhamento</Text>
          <Text variant="body-02-md">
            {monthsToAdd} meses, até {finalDate}
          </Text>
        </S.RequestSummaryItemContainer>
        <S.RequestSummaryItemContainer>
          <Text variant="caption-02">Validade da solicitação</Text>
          <Text variant="body-01-sm">
            Você deve concluir a solicitação em até 60 minutos após a
            confirmação.
          </Text>
          <S.DeadlineToRequestText>
            Após esse prazo, você deve fazer uma nova solicitação.
          </S.DeadlineToRequestText>
        </S.RequestSummaryItemContainer>
      </Card>

      <DisabledDataToShareCard
        cardTitle="O que será compartilhado?"
        permissions={selectedPermissions}
      />

      <Card lead="Termos e condições" hasDivider>
        <S.TermsAndConditionsLabel>
          Antes de confirmar, leia os termos e condições do compartilhamento de
          dados
        </S.TermsAndConditionsLabel>
        <div>
          <Button
            variant="secondary-md"
            onClick={() => openModal(CONSENTIMENTO_MODALS.termos)}
          >
            Ler os termos e condições
          </Button>
        </div>

        <S.CheckboxContainer>
          <Checkbox
            className="checkbox"
            value={isTermosAccepeted}
            onChange={() => setIsTermosAccepeted((previous) => !previous)}
          />
          <Text variant="body-02-md">Li e aceito os termos e condições</Text>
        </S.CheckboxContainer>

        <Divider />
        <S.RedirectToFinishRequestInfo>
          Para concluir a solicitação, você será redirecionado&nbsp;
          <strong>de forma segura</strong>, para o site ou app da instituição:
        </S.RedirectToFinishRequestInfo>
        <S.TermsAndConditionsLabel>
          {selectedInstituicao?.nome || state?.compartilhamento?.empresa}
        </S.TermsAndConditionsLabel>
        <S.ButtonContainer>
          <Button
            onClick={onConfirmation}
            fullWidth={!isLargeDevice}
            disabled={!isTermosAccepeted}
          >
            Continuar
          </Button>
          <S.Link onClick={() => navigate("/")}>Cancelar solicitação</S.Link>
        </S.ButtonContainer>
      </Card>

      {renderModal()}
    </S.Container>
  );
};

export default Termos;
