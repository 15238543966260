import React, { useCallback } from "react";
import * as ParentStyle from "../../Compartilhamento.style";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import { useModalsContext } from "hooks/useModalsContext";

const PermissionsDataInfo = ({ permissions = [] }) => {
  const { openModal } = useModalsContext();

  const handleOpenModal = useCallback(
    ({ categoria, dados }) =>
      openModal(AUTORIZACAO_MODALS.dados, { title: categoria, dados }),
    [openModal]
  );

  if (!permissions.length) return null;

  return (
    <div>
      {permissions.map((permission) => (
        <ParentStyle.Link
          key={permission.id}
          onClick={() => handleOpenModal(permission)}
        >
          {permission.categoria}
        </ParentStyle.Link>
      ))}
    </div>
  );
};
export default PermissionsDataInfo;
