import GenericErrorModal from "components/GenericErrorModal/GenericErrorModal";
import ApolicesModal from "pages/Autorizacao/components/ApolicesModal/ApolicesModal";
import AutorizacaoCancelarConsentimentoModal from "pages/Autorizacao/components/CancelarConsentimentoModal/CancelarConsentimentoModal";
import OrigemDadosModal from "pages/Autorizacao/components/OrigemDadosModal/OrigemDadosModal";
import DataModal from "pages/Consentimento/components/DataModal/DataModal";
import AutorizacaoRedirectErrorModal from "pages/Autorizacao/components/AutorizacaoRedirectErrorModal/AutorizacaoRedirectErrorModal";
import SearchInstituicaoModal from "pages/Consentimento/components/SearchInstituicaoModal/SearchInstituicaoModal";
import TermosModal from "pages/Consentimento/components/TermosModal/TermosModal";
import ApolicesSelecionadasModal from "pages/Gestao/components/ApolicesSelecionadasModal/ApolicesSelecionadasModal";
import GestaoCancelarConsentimentoModal from "pages/Gestao/components/CancelarConsentimentoModal/CancelarConsentimentoModal";
import FirstAccessModal from "pages/Home/components/FirstAccessModal/FirstAccessModal";
import React, { createContext, useCallback, useMemo, useState } from "react";
import RecepcaoRedirectErrorModal from "pages/Consentimento/components/RecepcaoRedirectErrorModal/RecepcaoRedirectErrorModal";
import BlockConsentFlowModal from "pages/Consentimento/components/BlockConsentFlowModal/BlockConsentFlowModal";
import DifferentCpfModal from "pages/Autorizacao/components/DifferentCpfModal/DifferentCpfModal";

export const MODAL_CLOSING_TIME = 500;

export const COMMON_MODALS = {
  dados: "dados",
  genericError: "genericError",
  selectedApolices: "selectedApolices",
};

export const CONSENTIMENTO_MODALS = {
  ...COMMON_MODALS,
  termos: "termos",
  instituicoes: "instituicoes",
  redirectError: "receptionRedirectError",
  blockConsentFlow: "blockConsentFlow",
};

export const AUTORIZACAO_MODALS = {
  ...COMMON_MODALS,
  apolices: "apolices",
  cancelarSolicitacao: "cancelarSolicitacao",
  termos: "termos",
  instituicoes: "instituicoes",
  origemDados: "origemDados",
  erroCPFdiferente: "erroCPFdiferente",
  redirectError: "authRedirectError",
};

export const HOME_MODALS = {
  primeiroAcesso: "primeiroAcesso",
};

export const EFETIVACAO_MODALS = {
  ...COMMON_MODALS,
};

export const GESTAO_MODALS = {
  ...COMMON_MODALS,
  finalizeSharing: "finalizeSharing",
};

const ModalsContext = createContext({});

export const ModalsProvider = ({ children }) => {
  const [openedModalType, setOpenedModalType] = useState(null);
  const [modalData, setModalData] = useState({});

  const addModalData = useCallback(
    (newData) => setModalData((prevData) => ({ ...prevData, ...newData })),
    []
  );

  const openModal = useCallback((type, data) => {
    setOpenedModalType(type);
    if (data) setModalData((prevData) => ({ ...prevData, ...data }));
  }, []);

  const closeModal = useCallback((data = null) => {
    setOpenedModalType(null);
    setTimeout(() => setModalData(data), MODAL_CLOSING_TIME);
  }, []);

  const renderModal = useCallback(
    () => (
      <>
        <ApolicesModal />
        <OrigemDadosModal />
        <TermosModal />
        <GenericErrorModal />
        <SearchInstituicaoModal />
        <FirstAccessModal />
        <DataModal />
        <DifferentCpfModal />
        <ApolicesSelecionadasModal />
        <BlockConsentFlowModal />
        <RecepcaoRedirectErrorModal />
        <AutorizacaoRedirectErrorModal />
        <GestaoCancelarConsentimentoModal />
        <AutorizacaoCancelarConsentimentoModal />
      </>
    ),
    []
  );

  const contextValues = useMemo(
    () => ({
      openModal,
      closeModal,
      modalData,
      addModalData,
      openedModalType,
      renderModal,
    }),
    [
      addModalData,
      closeModal,
      modalData,
      openModal,
      openedModalType,
      renderModal,
    ]
  );

  return (
    <ModalsContext.Provider value={contextValues}>
      {children}
    </ModalsContext.Provider>
  );
};

export default ModalsContext;
