import styled from "styled-components/macro";
import { Text } from "@ds/react";

export const CardContainer = styled.div(({ theme }) => ({
  background: theme.color.neutral["08"],
  textAlign: "left",
  padding: "40px 16px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  boxShadow: theme.shadow.level["01"],
  width: "100%",

  [theme.breakpoint.lg()]: {
    flexDirection: "row",
    padding: "60px 32px",
  },
}));

export const SkeletonContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

export const CardLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
`;

export const CardRight = styled.div(({ hasDivider }) => ({
  flex: 0.6,
  gap: hasDivider ? "24px" : "40px",
  display: "flex",
  flexDirection: "column",
}));

export const Lead = styled(Text)(({ theme }) => ({
  color: theme.color.neutral["03"],
  textTransform: "uppercase",
}));

export const Title = styled(Text)(({ theme }) => ({
  margin: `${theme.spacing.inset["xs"]} 0`,
  color: theme.color.neutral["02"],
  "&&": {
    textTransform: "none",
  },
}));
