import CompartilhamentoCard from "./CompartilhamentoCard";
import * as S from "./CompartilhamentoMobile.style";

const CompartilhamentoMobile = ({ compartilhamentos = [] }) => (
  <S.CompartilhamentosContainer>
    {compartilhamentos
      .filter((compartilhamento) => compartilhamento)
      .map((compartilhamento) => (
        <CompartilhamentoCard
          key={compartilhamento.id}
          compartilhamento={compartilhamento}
        />
      ))}
  </S.CompartilhamentosContainer>
);

export default CompartilhamentoMobile;
