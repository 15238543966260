import { useCallback, useEffect, useState } from "react";
import { pegarParticipantesUrl } from "services/endpoints";
import useApi from "./useApi";

const PREVISUL_BRAND_ID = "f69e7283-3c1a-4244-94ec-7330016343df";

const useInstituicoes = () => {
  const [instituicoes, setInstituicoes] = useState(null);
  const [lastUrl, setLastUrl] = useState("");

  const { response, error, loading, success, fetchData } = useApi({
    method: "get",
    autoRun: false,
  });

  useEffect(() => {
    if (response?.data) {
      const result = response?.data.reduce((acc, organisation) => {
        const participantes = organisation.AuthorisationServers.map(
          (participante) => ({
            ...participante,
            organisationId: organisation.OrganisationId,
          })
        );
        acc.push(...participantes);
        return acc;
      }, []);

      const resultWithoutPrevisul = result.filter(
        (item) => item.AuthorisationServerId !== PREVISUL_BRAND_ID
      );

      if (lastUrl === response?.config?.url) {
        setInstituicoes(resultWithoutPrevisul);
      }
    }
  }, [lastUrl, response]);

  const getInstituicoes = useCallback(
    (search) => {
      const url = pegarParticipantesUrl(search);
      setLastUrl(url);
      return fetchData({ url });
    },
    [fetchData]
  );

  return {
    loading,
    data: instituicoes,
    success,
    error,
    getInstituicoes,
  };
};

export default useInstituicoes;
