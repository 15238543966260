import { Tag } from "@ds/react";
import styled from "styled-components";

export const ObjetivoSection = styled.div({
  gridArea: "2 / 2 / 3 / 3",
});

export const Lista = styled.div({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  "div[class*=Container]": {
    padding: "0",
  },
  "div[class*=Item]": {
    margin: "25px 0",
  },
  "div[class^=Accordionstyle__Section-sc-16os7b6-4], div[class^=Accordionstyle__SectionPrimary-sc-16os7b6-5]":
    {
      display: "initial",
      height: "20px",
    },
});

export const Title = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  gridTemplateRows: "1fr",
  gridColumnGap: "0px",
  gridRowGap: "0px",
  span: {
    fontSize: "14px",
    alignSelf: "center",
  },
  "&:hover": {
    color: theme.color.brandPrimary.dark,
  },
}));

export const StatusTag = styled(Tag)({
  maxWidth: "80px",
  justifySelf: "center",
});

export const TableHeadersContainer = styled.div(() => ({
  display: "grid",
  gridTemplateColumns: "32% 16.5% 24% 27.5%",
  gridTemplateRows: "1fr",
  gridColumnGap: "0px",
  gridRowGap: "0px",
  padding: "8px 0",
  borderBottom: "1px solid #C6C2C5",
}));

export const Item = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#f4f3f3",
  width: "100%",
  padding: "20px",
  gap: "24px",
});

export const Section = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
});

export const DataSection = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  borderRadius: "8px",
  padding: "20px",
  backgroundColor: theme.color.neutral["08"],
  border: `1px solid ${theme.color.line.medium}`,
  gap: "12px",
  flex: "1",

  "&.distributed": {
    flexDirection: "row",
    flexWrap: "wrap",

    "& > *": {
      whiteSpace: "nowrap",
    },
  },
}));

export const DetailsGrid = styled.div(({ type }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "repeat(2, 1fr)",
  gridColumnGap: "20px",
  gridRowGap: "20px",
  flex: "1",
}));

export const SharedDataSection = styled.div({
  display: "flex",
  flexDirection: "row",
  gap: "24px",
  width: "100%",
});

export const Link = styled.button(({ theme }) => ({
  display: "inline-flex",
  textDecorationLine: "underline",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  color: theme.color.brandPrimary.dark,
  padding: 0,
  fontSize: "16px",
}));

export const CPFSection = styled(Section)({
  gridArea: "1 / 1 / 2 / 2",
});

export const EmpresaSection = styled(Section)({
  gridArea: "2 / 1 / 3 / 2",
});

export const DataLimiteSection = styled(Section)(({ type }) => ({
  gridArea: "2 / 3 / 3 / 4",
}));

export const NomeSection = styled(Section)({
  gridArea: "1 / 2 / 2 / 3",
});

export const SegmentoSection = styled(Section)(({ type }) => ({
  gridArea: "2 / 2 / 3 / 3",
}));

export const ActionsGrid = styled.div({
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)",
  gridTemplateRows: "1fr",
  gridColumnGap: "24px",
  flex: "1",
});

export const ActionSection = styled.div({
  display: "flex",
  flex: "1",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "16px",

  button: {
    width: "100%",

    span: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",

      svg: {
        top: "0px",
      },
    },
  },

  span: {
    display: "initial",
  },
});

export const Icon = styled.div({
  display: "inline-block",
  marginRight: "6px",
});
