import React from "react";
import * as S from "./DifferentCpfModal.style";
import { useModalsContext } from "hooks/useModalsContext";
import OpinModal from "components/OpinModal/OpinModal";
import { AUTORIZACAO_MODALS } from "context/modalsContext";
import BodyText from "components/BodyText/BodyText";
import { Divider } from "@ds/react";
import { useAuth } from "oidc-react";

const DifferentCpfModal = () => {
  const auth = useAuth();
  const { openedModalType } = useModalsContext();

  return (
    <OpinModal
      open={openedModalType === AUTORIZACAO_MODALS.erroCPFdiferente}
      onClose={auth?.signOutRedirect}
      title="Ops, houve um problema com a solicitação!"
    >
      <S.Container>
        <BodyText>
          Identificamos que seu acesso vem do redirecionamento da solicitação
          de&nbsp;
          <strong>Compartilhamento de dados.</strong>
        </BodyText>
        <BodyText>
          No entanto,&nbsp;
          <strong>
            o CPF informado para o compartilhamento não é o mesmo utilizado para
            acessar a conta.
          </strong>
          &nbsp; e não podemos prosseguir com esta solicitação de
          compartilhamento de dados.
        </BodyText>
        <Divider />
        <BodyText>
          <strong>Para resolver o problema, você deve:</strong>
        </BodyText>
        <ul>
          <li>
            <BodyText>
              <strong>
                Retornar à instituição receptora, acessar uma conta com o mesmo
                CPF
              </strong>
              &nbsp; que você usa para acessar a instituição transmissora&nbsp;
              <strong>e refazer a solicitação</strong>.
            </BodyText>
          </li>
          <br />
          <li>
            <BodyText>
              <strong>
                Refazer a solicitação e acessar uma conta que possua o mesmo CPF
              </strong>
              informado na solicitação de compartilhamento.
            </BodyText>
          </li>
        </ul>
      </S.Container>
    </OpinModal>
  );
};

export default DifferentCpfModal;
