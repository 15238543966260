import styled from "styled-components";
import { ReactComponent as Success } from "../../../../assets/icons/success-check.svg";
import { Pagination as PaginationDS } from "@ds/react";

export const FiltersContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  gap: "16px",
  flexWrap: "wrap",
}));

export const Container = styled.div(({ theme }) => ({
  backgroundColor: theme.color.neutral["08"],
  boxShadow: theme.shadow.base,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "60px 32px",
  gap: "18px",
  marginTop: "20px",
}));

export const Pagination = styled(PaginationDS)(() => ({
  marginTop: "32px",
  padding: "32px",
}));

export const SuccessIcon = styled(Success)({
  height: "16px",
});

export const CompartilhamentoTypeContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flex: 1,
  width: "100%",
  borderBottom: `1px solid ${theme.color.line.medium}`,
  [theme.breakpoint.lg()]: {
    alignItems: "flex-start",
  },
}));

export const CompartilhamentoType = styled.div(({ theme, selected }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  flex: ".5",
  padding: "8px",
  color: selected ? theme.color.brandPrimary.dark : "inherit",
  borderBottom: `2px solid ${
    selected ? theme.color.brandPrimary.dark : "transparent"
  }`,
  [theme.breakpoint.lg()]: {
    flex: ".2",
  },
}));

export const FiltersSection = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  flex: 1,
  width: "100%",
  gap: "12px",
}));

export const FilterContainer = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  flex: 1,
  gap: "16px",
  flexWrap: "wrap",
}));

export const FilterButton = styled.div(({ theme, selected }) => ({
  display: "inline-flex",
  cursor: "pointer",
  flexDirection: "row",
  alignItems: "center",
  width: "auto",
  gap: "8px",
  padding: "8px 16px",
  heigth: "22px",
  backgroundColor: selected ? theme.color.brandPrimary.dark : "transperent",
  borderRadius: "500px",
  border: `1px solid ${theme.color.brandPrimary.dark}`,
  color: selected ? theme.color.neutral["08"] : theme.color.brandPrimary.dark,
  svg: {
    width: "16px",
    display: selected ? "block" : "none",
  },
}));

export const PaginationContainer = styled.div(({ theme }) => ({
  backgroundColor: theme.color.neutral["08"],
  boxShadow: theme.shadow.base,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "24px 32px",
  gap: "18px",
}));
