import React from "react";
import { Text } from "@ds/react";
import { CONSENTIMENTO_MODALS } from "context/modalsContext";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import * as S from "./SearchInstituicaoButton.style";
import { useModalsContext } from "hooks/useModalsContext";

const SearchInstituicaoButton = ({
  instituicao,
  description,
  disabled = false,
}) => {
  const { openModal } = useModalsContext();

  const openInstituicaoModal = () =>
    !disabled && openModal(CONSENTIMENTO_MODALS.instituicoes);

  if (!instituicao)
    return (
      <S.SearchInstituicaoButton
        variant="secondary-md"
        disabled={disabled}
        onClick={openInstituicaoModal}
      >
        <SearchIcon />
        <Text variant="button-02-md">Buscar instituição</Text>
      </S.SearchInstituicaoButton>
    );

  return (
    <div>
      {description && (
        <Text variant="caption-02" color="text-light">
          {description}
        </Text>
      )}

      <S.SearchInstituicaoComponentContainer
        disabled={disabled}
        onClick={openInstituicaoModal}
      >
        <Text variant="body-02-md">{instituicao?.CustomerFriendlyName}</Text>
        {!disabled && <EditIcon />}
      </S.SearchInstituicaoComponentContainer>
    </div>
  );
};

export default SearchInstituicaoButton;
