import useApi from "./useApi";

const useDadosEnviados = () => {
  const { data, error, loading, fetchData } = useApi({
    method: "get",
    url: `/open-insurance-previsul/v1/operacoes-consentimento/transmissora/consentimentos-transmitidos`,
  });

  return {
    loading,
    data,
    error,
    fetchData,
  };
};

export default useDadosEnviados;
