import { Button } from "@ds/react";
import styled from "styled-components";

export const Container = styled.div(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  svg: {
    cursor: "pointer",
    marginRight: "10px",
  },
}));

export const Instituicao = styled.div(() => ({
  borderRadius: "12px",
  padding: "20px 0 0",
  border: "1px solid #D1DBE3",
  boxShadow: "0px 4px 5px rgba(45, 41, 38, 0.08)",
  gap: "16px",
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const InstituicaoContent = styled.div(() => ({
  padding: "0 20px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  textAlign: "left",
}));

export const ButtonContent = styled.div(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 0 16px",
}));

export const SearchInstituicaoButton = styled(Button)(({ fullWidth }) => ({
  cursor: "pointer",
  width: fullWidth ? "100%" : "fit-content",

  svg: {
    marginRight: "10px",
  },
}));
