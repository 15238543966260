import NavigationHeader from "components/NavigationHeader/NavigationHeader";
import { useGestaoContext } from "hooks/useGestaoContext";
import * as S from "./MaisDetalhes.style";
import ActionSection from "./components/ActionSection";
import DadosCompartilhamentoSection from "./components/DadosCompartilhamentoSection";
import MainInformationSection from "./components/MainInformationSection";
import ResumoSolicitacaoSection from "./components/ResumoSolicitacaoSection";
import useScrollTop from "hooks/useScrollTop";

const MaisDetalhes = () => {
  useScrollTop({ behavior: "smooth" });
  const { setSelectedCompartilhamento } = useGestaoContext();

  return (
    <S.Container>
      <NavigationHeader onBack={() => setSelectedCompartilhamento(null)} />

      <MainInformationSection />

      <ResumoSolicitacaoSection />

      <DadosCompartilhamentoSection />

      <ActionSection />
    </S.Container>
  );
};

export default MaisDetalhes;
