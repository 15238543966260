import { Tag } from "@ds/react";
import styled from "styled-components";

export const CompartilhamentosContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  gap: "32px",
});

export const CompartilhamentoCardContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "22px 0 0",
  gap: "14px",
  backgroundColor: theme.color.white,
  boxShadow: theme.shadow.raised,
  position: "relative",
}));
export const CompartilhamentoCardContent = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "0px 16px",
  gap: "14px",
  position: "relative",
}));

export const ButtonsContainer = styled.div({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  gap: "1px",

  button: {
    borderRadius: "0",
    width: "100%",

    span: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
});

export const StatusTag = styled(Tag)({
  position: "absolute",
  top: "16px",
  right: "16px",
});

export const Icon = styled.img({
  margin: "0 6px 0 0",
  height: "60%",
});
