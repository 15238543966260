import styled from "styled-components/macro";

export const Container = styled.div(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
}));

export const Label = styled.label(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "bold",
  marginBottom: "8px",
}));

export const CustomSelect = styled.select(({ theme }) => ({
  minHeight: "40px",
  borderColor: theme.color.neutral["04"],
  borderRadius: "4px",
  padding: "8px 12px",
  fontSize: "16px",
}));
