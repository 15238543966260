import useEtapas from "hooks/useEtapas";
import React, { useCallback, useMemo } from "react";
import { ETAPAS } from "./constants";
import * as S from "./Autorizacao.styles";
import { useModalsContext } from "hooks/useModalsContext";
import { useAutorizacaoContext } from "hooks/useAutorizacaoContext";
import AutorizacaoPendente from "./etapas/AutorizacaoPendente/AutorizacaoPendente";
import Redirecionamento from "./etapas/Redirecionamento/Redirecionamento";
import Confirmacao from "./etapas/Confirmacao/Confirmacao";
import Compartilhamento from "./etapas/Compartilhamento/Compartilhamento";
import NavigationHeader from "components/NavigationHeader/NavigationHeader";
import { useNavigate } from "react-router-dom";
import withTemplate from "hoc/withTemplate";
import Steps from "components/Steps";

const Autorizacao = () => {
  const navigate = useNavigate();
  const { renderModal } = useModalsContext();
  const { status } = useAutorizacaoContext();
  const { isFirstStep, currentStep, stepForward, stepBack } = useEtapas(ETAPAS);

  const handleGoBack = isFirstStep ? () => navigate("/opin") : stepBack;

  const ETAPA_COMPONENT_MAPPER = useMemo(
    () => ({
      Confirmação: <Confirmacao />,
      Compartilhamento: <Compartilhamento onSubmit={stepForward} />,
    }),
    [stepForward]
  );

  const renderEtapa = useCallback(
    () => ETAPA_COMPONENT_MAPPER[currentStep],
    [ETAPA_COMPONENT_MAPPER, currentStep]
  );

  if (status === "pending") return <AutorizacaoPendente />;
  if (status === "redirect") return <Redirecionamento />;

  return (
    <S.AutorizacaoContainer>
      <NavigationHeader onBack={handleGoBack} />
      <Steps steps={ETAPAS} currentStep={currentStep} />

      {renderEtapa()}
      {renderModal()}
    </S.AutorizacaoContainer>
  );
};

export default withTemplate(Autorizacao);
