import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
}));

export const EnterprisesInfo = styled.div(({ theme }) => ({
  border: "1px solid #D1DBE3",
  borderRadius: "12px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "40px",

  [theme.breakpoint.lg()]: {
    maxWidth: "70%",
  },
}));

export const Info = styled.div(({ center }) => ({
  display: "flex",
  alignItems: "center",
  gap: center ? "34px" : "16px",
  img: {
    maxWidth: "60px",
  },
}));
