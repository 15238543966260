import { Skeleton } from "@ds/react";
import Link from "components/Link/Link";
import { GESTAO_MODALS } from "context/modalsContext";
import { useGestaoContext } from "hooks/useGestaoContext";
import { useModalsContext } from "hooks/useModalsContext";
import { useCallback } from "react";

const useDadosCompartilhados = () => {
  const { openModal } = useModalsContext();
  const {
    isPermissionsLoading,
    selectedCompartilhamento,
    selectedCompartilhamentoPermissions,
  } = useGestaoContext();

  const { apolices, permissions } = selectedCompartilhamento || {};

  const handleOpenDataModal = useCallback(
    ({ categoria, dados }) =>
      openModal(GESTAO_MODALS.dados, { title: categoria, dados }),
    [openModal]
  );

  const renderPermissions = useCallback(() => {
    let result = null;

    if (!permissions?.length) return null;
    if (isPermissionsLoading) result = <Skeleton lines={3} />;
    else
      result = selectedCompartilhamentoPermissions.map((permission) => (
        <Link
          key={permission.id}
          onClick={() => handleOpenDataModal(permission)}
          style={{ flexBasis: `calc(45%)` }}
        >
          {permission.categoria}
        </Link>
      ));

    return result;
  }, [
    permissions?.length,
    isPermissionsLoading,
    selectedCompartilhamentoPermissions,
    handleOpenDataModal,
  ]);

  const renderSelectedApolicesInfo = useCallback(() => {
    if (!apolices?.length) return null;

    const modalType = GESTAO_MODALS.selectedApolices;
    const modalProps = { apolices: selectedCompartilhamento?.apolices };

    return (
      <Link onClick={() => openModal(modalType, modalProps)}>
        {apolices?.length} apólices selecionadas
      </Link>
    );
  }, [apolices?.length, selectedCompartilhamento?.apolices, openModal]);

  return {
    renderPermissions,
    renderSelectedApolicesInfo,
  };
};

export default useDadosCompartilhados;
