import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "16px",

  [theme.breakpoint.lg()]: {
    gap: "24px",
  },

  "& *": {
    color: theme.color.neutral["02"],
  },

  strong: {
    display: "contents",
  },
}));

export const Link = styled.button(({ theme }) => ({
  textDecorationLine: "underline",
  background: "transparent",
  border: "none",
  cursor: "pointer",
  color: theme.color.brandPrimary.light,
  padding: 0,
  fontSize: "16px",
  marginTop: "8px",
  display: "flex",
  alignItems: "flex-end",
  alignSelf: "center",

  svg: {
    marginLeft: "5px",
  },
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  alignItems: "stretch",

  [theme.breakpoint.lg()]: {
    flexDirection: "row",
    gap: "24px",
    alignItems: "center",
  },
}));

export const ItemContainer = styled.div(() => ({
  "&.bottom-space": { marginBottom: 40 },
}));

export const CheckboxContainer = styled.div(() => ({
  alignItems: "center",
  width: "fit-content",
  display: "flex",
  marginBottom: 8,

  "> span": {
    minWidth: "max-content",
  },

  "> .checkbox": {
    border: "none",
    padding: "0 0",
    margin: 0,
  },
}));
