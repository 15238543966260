import { useAuth } from "oidc-react";
import api from "services/api";

export const useOpinAuth = () => {
  const auth = useAuth();

  api.interceptors.request.use(
    (config) => {
      config.headers["x-ibm-client-id"] = process.env.REACT_APP_CLIENT_ID;
      config.headers[
        "Authorization"
      ] = `Bearer ${auth?.userData?.access_token}`;

      return config;
    },
    (error) => Promise.reject(error)
  );

  return api;
};
