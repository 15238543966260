import styled from "styled-components";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",

  [theme.breakpoint.lg()]: {
    gap: "24px",
  },
}));

export const Link = styled.button(({ theme }) => ({
  textDecorationLine: "underline",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  textAlign: "left",
  color: theme.color.brandPrimary.light,
  padding: 0,
  fontSize: "16px",
  marginTop: "16px",
  display: "flex",
  alignItems: "flex-end",

  svg: {
    marginLeft: "5px",
  },
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "32px",

  [theme.breakpoint.lg()]: {
    flexDirection: "row",
    gap: "24px",
  },
}));

export const SpecialSection = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  borderLeftColor: theme.color.brandSecondary.light,
  borderLeftWidth: "2px",
  borderLeftStyle: "solid",
  paddingLeft: "16px",
  gap: "12px",

  [theme.breakpoint.lg()]: {
    gap: "24px",
  },
}));
