import { Text } from "@ds/react";
import styled from "styled-components/macro";

export const TextLight = styled(Text)({
  fontWeight: 400,

  "& strong": {
    display: "contents",
  },
});
