import { Text } from "@ds/react";
import styled from "styled-components/macro";

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  backgroundColor: theme.color.neutral["08"],
  gap: theme.spacing.inset["xs"],
  padding: `${theme.spacing.inset["sm"]} 0`,
  "div[class^='ProgressBarstyle__ProgressAnimated-sc-8w5s6g-1']": {
    backgroundColor: theme.color.brandSecondary.light,
  },
  [theme.breakpoint.lg()]: {
    padding: `20px 32px 0`,
  },
}));

export const Steps = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: `0 16px`,
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing.inline["md"],
  [theme.breakpoint.lg()]: {
    gap: theme.spacing.inline["xl"],
    padding: `24px 14px`,
  },
}));

export const Step = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing.inset["3xs"],
  textTransform: "capitalize",
}));

export const StepName = styled(Text)(() => ({
  textTransform: "capitalize",
  fontWeight: 400,
}));

export const StepNumber = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "20px",
  height: "20px",
  borderRadius: "4px",
  padding: "12px",
  backgroundColor: theme.color.neutral["07"],
  color: theme.color.brandPrimary.light,
}));
export const ActiveStepNumber = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: theme.color.neutral["08"],
  width: "20px",
  height: "20px",
  borderRadius: "4px",
  padding: "12px",
  backgroundColor: theme.color.brandSecondary.light,
}));
