import { Button, Divider, Text } from "@ds/react";
import Card from "components/Card";
import CustomSelect from "components/CustomSelect/CustomSelect";
import { useConsentimentoContext } from "hooks/useConsentimentoContext";
import { useModalsContext } from "hooks/useModalsContext";
import { useAuth } from "oidc-react";
import { SearchInstituicaoButton } from "pages/Consentimento/components/SearchInstituicaoButton";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCPF, truncateStr } from "utils/helpers";
import * as S from "./Solicitacao.style";
import CardLoading from "components/CardLoading/CardLoading";
import { GESTAO_ACTION } from "utils/constants";

const MAX_SEGMENTO_INCLUDES_LENGTH = 65;

const Solicitacao = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { userData } = useAuth();
  const { renderModal } = useModalsContext();
  const { stepForward, selectedInstituicao, segmentosHandler } =
    useConsentimentoContext();

  const { loading, segmentos, setSelectedSegmento, selectedSegmento } =
    segmentosHandler;

  const { nome, cpf } = userData.profile;
  const isDisabled = !selectedSegmento || !selectedInstituicao;
  const isInstitutionEditable = state?.action !== GESTAO_ACTION.CHANGE;
  const segmentoIncludes = truncateStr(
    segmentosHandler.filledSegmento?.grupos
      ?.map(({ grupo, subgrupo }) => `${grupo} ${subgrupo}`)
      .join(", "),
    MAX_SEGMENTO_INCLUDES_LENGTH
  );

  if (loading) return <CardLoading />;

  return (
    <>
      <Card lead="Open InSurance" title="Novo compartilhamento">
        <S.IdentificationInfoContainer>
          <Text variant="caption-02">Identificação do cliente</Text>
          <Text variant="body-02-md">Nome: {nome}</Text>
          <Text variant="body-02-md">CPF: {formatCPF(cpf)}</Text>
        </S.IdentificationInfoContainer>

        <Divider />

        <S.SectionContainer>
          <S.SectionTitle>Sobre o uso dos dados</S.SectionTitle>
          <Text variant="body-02-md">
            Ao compartilhar seus dados, conseguimos ofertar produtos e serviços
            personalizados de acordo com a sua necessidade.
          </Text>

          <CustomSelect
            label="Qual o segmento que deseja compartilhar?"
            placeholder="Selecione o segmento"
            options={segmentos}
            value={selectedSegmento}
            onChange={({ target }) => setSelectedSegmento(target.value)}
          />

          {!!selectedSegmento && (
            <S.Caption>Esse segmento inclui: {segmentoIncludes}</S.Caption>
          )}
        </S.SectionContainer>

        <Divider />

        <S.SectionContainer>
          <S.SectionTitle>
            Instituição que vai compartilhar os dados
          </S.SectionTitle>

          {isInstitutionEditable && (
            <S.SectionSubtitle>
              E para qual instituição solicitaremos o compartilhamento dos seus
              dados?
            </S.SectionSubtitle>
          )}

          <SearchInstituicaoButton
            disabled={!isInstitutionEditable}
            instituicao={selectedInstituicao}
          />

          <S.ButtonContainer>
            <Button
              variant="primary-01-md"
              onClick={stepForward}
              disabled={isDisabled}
            >
              Continuar
            </Button>
            <S.Link onClick={() => navigate("/")}>Cancelar solicitação</S.Link>
          </S.ButtonContainer>
        </S.SectionContainer>
      </Card>

      {renderModal()}
    </>
  );
};

export default Solicitacao;
