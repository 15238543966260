import { Text } from "@ds/react";
import CompartilhamentoBase from "pages/Consentimento/components/CompartilhamentoBase/CompartilhamentoBase";
import { useNavigate } from "react-router-dom";

const Renovacao = () => {
  const navigate = useNavigate();

  return (
    <CompartilhamentoBase
      disableEditInstitution
      disableEditPermissions
      shareDataCardTitle="Renovação do compartilhamento de dados"
      shareDataCardContent={
        <Text variant="body-02-md">
          Quer renovar o compartilhamento? É prático e rápido. Seu
          compartilhamento atual será finalizado, mas você aproveita o mesmo
          cadastro para solicitar um novo.
        </Text>
      }
      cancelActionText="Meus compartilhamentos"
      onCancelAction={() => navigate("/opin/gestao")}
    />
  );
};

export default Renovacao;
