import { COMMON_MODALS, CONSENTIMENTO_MODALS } from "context/modalsContext";
import { useModalsContext } from "./useModalsContext";
import { useEffect, useMemo, useState } from "react";
import { getSegmentosUrl } from "services/endpoints";
import useApi from "./useApi";
import { REQUEST_METHODS } from "utils/constants";

const useSegmentos = () => {
  const { openModal } = useModalsContext();
  const { loading, error, data } = useApi({
    method: REQUEST_METHODS.GET,
    url: getSegmentosUrl(),
  });

  const [selectedSegmento, setSelectedSegmento] = useState("");

  const segmentos = useMemo(
    () =>
      data?.map?.(({ id, nomeClassificacao }) => ({
        id,
        value: nomeClassificacao,
      })),
    [data]
  );

  const filledSegmento = useMemo(
    () =>
      data?.find?.(({ nomeClassificacao: name }) => name === selectedSegmento),
    [data, selectedSegmento]
  );

  useEffect(() => {
    if (!loading && data && !data?.length)
      openModal(CONSENTIMENTO_MODALS.blockConsentFlow);
    if (error) openModal(COMMON_MODALS.genericError);
  }, [data, error, loading, openModal]);

  return {
    loading,
    segmentos,
    selectedSegmento,
    setSelectedSegmento,
    filledSegmento,
    error,
  };
};

export default useSegmentos;
