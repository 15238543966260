const { useRef, useLayoutEffect } = require("react");

const useMutableRef = (value) => {
  const valueRef = useRef(value);

  useLayoutEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef;
};

export default useMutableRef;
