import { Tag } from "@ds/react";
import styled from "styled-components";

export const CompartilhamentoInfo = styled.div(({ theme }) => ({
  display: "flex",
  padding: "32px 16px ",
  flexDirection: "column",
  gap: "16px",
  background: theme.color.neutral["08"],
  boxShadow: theme.shadow.level["1"],
  color: theme.color.neutral["03"],
  position: "relative",
}));

export const SectionItem = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "4px",

  "& > :first-child": {
    color: theme.color.neutral["03"],
  },

  "& > :last-child": {
    color: theme.color.neutral["02"],
  },
}));

export const StatusTag = styled(Tag)({
  position: "absolute",
  top: "24px",
  right: "16px",
});

export const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
});

export const ActionContainer = styled.div({
  display: "flex",
  gap: "16px",
  alignItems: "center",
  flexDirection: "column",

  button: {
    display: "flex",
    justifyContent: "center",
    width: "100%",

    svg: {
      top: 0,
    },

    span: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
  },
});
