import { Accordion, Button } from "@ds/react";
import styled from "styled-components/macro";
import { ReactComponent as Opin } from "../../assets/icons/OpenInsuranceLogo.svg";

export const OpinLogo = styled(Opin)({
  width: "247px",
  height: "auto",
});
export const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  paddingBottom: "16px",
  width: "100%",

  [theme.breakpoint.lg()]: {
    gap: "24px",
    paddingBottom: "5px",
  },
}));

export const ButtonContainer = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "32px",

  [theme.breakpoint.lg()]: {
    maxWidth: "22rem",
    gap: "24px",
  },

  "> button": {
    display: "flex",
    alignItems: "flex-start",
    width: 360,
  },

  svg: {
    marginRight: 10,
    marginBottom: -2,
  },
}));

export const NewShareButton = styled(Button).attrs({
  variant: "secondary-md",
})(({ theme }) => ({
  background: theme.color.brandPrimary.light,
  color: theme.color.neutral["08"],
}));

export const Link = styled.button(({ theme }) => ({
  textDecorationLine: "underline",
  background: "transparent",
  cursor: "pointer",
  border: "none",
  color: theme.color.brandPrimary.light,
  padding: 0,
  fontSize: "16px",
  textAlign: "left",
}));

export const BodyLink = styled.a(({ theme }) => ({
  color: theme.color.brandPrimary.light,
  display: "contents",
}));

export const CustomAccordion = styled(Accordion)(() => ({
  fontSize: 16,

  strong: {
    display: "contents",
  },
}));
