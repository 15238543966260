import { useModalsContext } from "hooks/useModalsContext";
import usePermissionsByNames from "hooks/usePermissionsByNames";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { SCOPE } from "utils/constants";
import { GESTAO_MODALS } from "./modalsContext";

const GestaoContext = createContext({});

export const GestaoProvider = ({ children }) => {
  const { openModal } = useModalsContext();

  const {
    data: apiPermissions,
    error,
    loading,
    getDetailedPermissions,
  } = usePermissionsByNames();

  const [
    selectedCompartilhamentoPermissions,
    setSelectedCompartilhamentoPermissions,
  ] = useState([]);
  const [tabType, setTabType] = useState(SCOPE.RECEPCAO);
  const [selectedCompartilhamento, setSelectedCompartilhamento] =
    useState(null);

  const value = useMemo(
    () => ({
      tabType,
      setTabType,
      isPermissionsLoading: loading,
      getDetailedPermissions,
      selectedCompartilhamento,
      setSelectedCompartilhamento,
      selectedCompartilhamentoPermissions,
      setSelectedCompartilhamentoPermissions,
    }),
    [
      tabType,
      loading,
      getDetailedPermissions,
      selectedCompartilhamento,
      selectedCompartilhamentoPermissions,
    ]
  );

  useEffect(() => {
    if (apiPermissions) setSelectedCompartilhamentoPermissions(apiPermissions);
    // TODO: create and use a specific error modal component below
    if (error) openModal(GESTAO_MODALS.genericError);
  }, [
    error,
    openModal,
    apiPermissions,
    setSelectedCompartilhamentoPermissions,
  ]);

  useEffect(() => {
    if (selectedCompartilhamento?.permissions?.length)
      getDetailedPermissions(selectedCompartilhamento?.permissions);
  }, [getDetailedPermissions, selectedCompartilhamento?.permissions]);

  return (
    <GestaoContext.Provider value={value}>{children}</GestaoContext.Provider>
  );
};

export default GestaoContext;
