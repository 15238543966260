import { useState, useEffect, useCallback } from "react";
import { useConsentimentoContext } from "./useConsentimentoContext";
import { COMMON_MODALS } from "context/modalsContext";
import { useModalsContext } from "./useModalsContext";
import { useOpinAuth } from "./useOpinAuth";
import { getPermissionsBySegmento } from "services/endpoints";
import { RESOURCES_READ } from "pages/Consentimento/constants";

const usePermissoesBySegmento = () => {
  const api = useOpinAuth();
  const { openModal } = useModalsContext();
  const { segmentosHandler } = useConsentimentoContext();

  const [loading, setLoading] = useState(true);
  const [requiredPermissions, setRequiredPermissions] = useState([]);
  const [optionalPermissions, setOptionalPermissions] = useState([]);

  const fetchPermissoesBySegmento = useCallback(async () => {
    try {
      const { data: permissions } = await api.get(
        getPermissionsBySegmento(segmentosHandler.filledSegmento?.sigla)
      );
      const filteredPermissions = permissions.filter(
        ({ chave }) => chave !== RESOURCES_READ[0]
      );

      setRequiredPermissions(filteredPermissions.filter((p) => p.obrigatorio));
      setOptionalPermissions(filteredPermissions.filter((p) => !p.obrigatorio));
    } catch (error) {
      openModal(COMMON_MODALS.genericError);
    } finally {
      setLoading(false);
    }
  }, [api, segmentosHandler.filledSegmento?.sigla, openModal]);

  useEffect(() => {
    fetchPermissoesBySegmento();
  }, [fetchPermissoesBySegmento]);

  return {
    loading,
    requiredPermissions,
    optionalPermissions,
  };
};

export default usePermissoesBySegmento;
