import React from "react";
import * as S from "../CompartilhamentoDesktop.style";
import { Text } from "@ds/react";
import AprovadoresList from "components/AprovadoresList/AprovadoresList";

const AprovadoresItemSection = ({ apolices = [] }) => {
  const aprovadores = Object.values(
    apolices.reduce((acc, apolice) => {
      // eslint-disable-next-line no-unused-expressions
      apolice?.aprovadores?.forEach((aprovador) => {
        if (!acc[aprovador?.identification])
          acc[aprovador?.identification] = {
            ...aprovador,
            apolices: [apolice],
          };
        else acc[aprovador.identification].apolices.push(apolice);
      });

      return acc;
    }, {})
  );

  if (!aprovadores?.length) return null;

  return (
    <S.Section>
      <Text variant="caption-01" color="text-light">
        Aprovadores
      </Text>

      <S.DataSection>
        <AprovadoresList aprovadores={aprovadores} />
      </S.DataSection>
    </S.Section>
  );
};
export default AprovadoresItemSection;
