import { Divider } from "@ds/react";
import { DESCRIPTION, FOOTER_LINKS } from "./constants";
import * as S from "./Footer.style";

const Footer = () => (
  <S.FooterMain>
    <S.LogoContainer>
      <S.LogoPrevisul />
      <S.Copyright variant="caption-02">© 2020 Previsul Seguradora</S.Copyright>
    </S.LogoContainer>

    <S.Content>
      <S.ContactsSection>
        <S.ContentTitle variant="body-01-sm">Fale com a gente</S.ContentTitle>
        <S.ContentDescription variant="body-01-sm">
          {DESCRIPTION}
        </S.ContentDescription>
        <S.ContactsBody>
          <S.ContactItem>
            <S.ItemTitle variant="body-01-sm">
              De capitais e regiões metropolitanas
            </S.ItemTitle>
            <S.ItemText href="tel:30036773" variant="body-01-sm">
              3003 6773
            </S.ItemText>
          </S.ContactItem>
          <S.ContactItem>
            <S.ItemTitle variant="body-01-sm">Demais localidades</S.ItemTitle>
            <S.ItemText href="tel:08007098059" variant="body-01-sm">
              0800 709 8059
            </S.ItemText>
          </S.ContactItem>
        </S.ContactsBody>
      </S.ContactsSection>

      <Divider />

      <S.LinksSection>
        {FOOTER_LINKS.map((links) => (
          <S.LinksSectionItem key={links.title}>
            <S.ContentTitle variant="body-01-sm">{links.title}</S.ContentTitle>
            <S.LinksBody>
              {links.items.map((link) => (
                <S.FooterLink key={link.name} href={link.href}>
                  {link.name}
                </S.FooterLink>
              ))}
            </S.LinksBody>
          </S.LinksSectionItem>
        ))}
      </S.LinksSection>
    </S.Content>
  </S.FooterMain>
);

export default Footer;
