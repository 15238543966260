import useApi from "./useApi";
import { useEffect } from "react";
import { getApolicesUrl } from "services/endpoints";
import { useAutorizacaoContext } from "./useAutorizacaoContext";

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_APOLICES_PAGE_SIZE = 10;

const useApolices = (page = DEFAULT_PAGE_NUMBER) => {
  const { setApolices, interaction } = useAutorizacaoContext();

  const { loading, error, data, fetchData } = useApi({
    method: "GET",
    url: getApolicesUrl(
      interaction?.data?.consent?.consentId,
      page - 1,
      interaction?.data?.segment,
      DEFAULT_APOLICES_PAGE_SIZE
    ),
    autoRun: false,
  });

  useEffect(() => {
    if (data) setApolices(data);
  }, [data, setApolices]);

  return { data, loading, error, getApolices: fetchData };
};

export default useApolices;
