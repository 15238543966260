import { ProgressBar, Text, useDeviceType } from "@ds/react";
import React from "react";
import * as S from "./StepsBar.style";

const StepsBar = ({ steps, currentStep, ...props }) => {
  const { lg } = useDeviceType();
  return (
    <S.Container>
      <S.Steps>
        {steps.map((step, i) =>
          step === currentStep ? (
            <S.Step key={step}>
              <S.ActiveStepNumber>
                <Text variant="button-01-md">{i + 1}</Text>
              </S.ActiveStepNumber>
              <S.StepName variant="body-02-md">{step}</S.StepName>
            </S.Step>
          ) : (
            <S.Step key={step}>
              <S.StepNumber>
                <Text variant="button-01-md">{i + 1}</Text>
              </S.StepNumber>
              {lg && <S.StepName variant="body-02-md">{step}</S.StepName>}
            </S.Step>
          )
        )}
      </S.Steps>
      <ProgressBar
        value={((steps.findIndex((step) => step === currentStep) + 1) * 100) / steps.length}
      />
    </S.Container>
  );
};

export default StepsBar;
